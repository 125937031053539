import { observer } from 'mobx-react';
import { FeedbackForm, Instructions, Questions } from './subcomponents';

import './Help.scss';

const Help = observer(() => (
  <div className='help'>
    <div className='help-container'>
      <Questions/>
      <FeedbackForm/>
      <Instructions/>
    </div>
  </div>
));

export default Help;
