export const ROTATION_STEP = 15;
export const POSITION_STEP = 0.1;
export const MIN_SCALE = 0.5;
export const MAX_SCALE = 4;
export const SCALE_STEP = 0.1;
export const OUTLINE_THICKNESS = 3;
export const INT_STEP = 1;
export const LOCKER_MAX = 6;
export const LOCKER_MIN = 0;
export const WIDTH_STEP = 1;
export const MIN_WIDTH = 1;
export const MAX_WIDTH = 4;

export const rotationMarks = [
  {
    value: 0,
    label: '0°',
  },
  {
    value: 90,
    label: '90°',
  },
  {
    value: 180,
    label: '180°',
  },
  {
    value: 270,
    label: '270°',
  },
  {
    value: 360,
    label: '360°',
  },
];

export const scaleMarks = [
  {
    value: 0.5,
    label: '0.5',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

export const widthMarks = [
  {
    value: 1,
    label: 'S',
  },
  {
    value: 2,
    label: 'M',
  },
  {
    value: 3,
    label: 'L',
  },
  {
    value: 4,
    label: 'XL',
  },
];

export const closestZero = (nums: number[]) => {
  return nums.reduce((acc, num) => {
    if (Math.abs(num) < Math.abs(acc)) {
      acc = num;
    }
    return acc;
  }, Number.MAX_SAFE_INTEGER);
};

export const getLayout = (
  x: number,
  y: number, 
  scale: number,
  rotation: number,
  width: number, 
  height: number,
) => {
  if ([0, 180, 360].includes(rotation)) {
    return {
      top: y - height / 2  * scale,
      bottom: y + height / 2  * scale,
      left: x - width / 2  * scale,
      right: x + width / 2  * scale,
    };
  } else if ([90, 270].includes(rotation)) {
    return {
      top: y - width / 2  * scale,
      bottom: y + width / 2  * scale,
      left: x - height / 2  * scale,
      right: x + height / 2  * scale,
    };
  }
};
