import {
  AdminStore,
  DepartmentsStore,
  FavoritesStore,
  LoginStore,
  OfficesStore,
  RegionsStore,
  ReservationsStore,
  RoomsStore,
  SnackbarStore,
  SchedulerStore,
  UsersStore,
  WorkspacesStore,
  EditStore,
  ConfirmationDialogStore,
  DivisionsStore,
  DomainsStore,
  PremisesStore,
  RoomStore,
  DistributionStore,
} from 'stores';

import {
  AdminService,
  ApiService,
  LoginService,
  FavoritesService,
  OfficesService,
  RegionsService,
  ReservationsService,
  RoomsService,
  UsersService,
  SchedulerService,
  WorkspacesService,
  KeycloakService,
  PremisesService,
  DivisionsService,
  DepartmentsService,
  DomainsService,
  RoomService,
  DistributionService,
} from './index';

export class ServiceContainer {
  private _adminStore?: AdminStore;
  private _adminService?: AdminService;
  private _apiService?: ApiService;
  private _favoritesStore?: FavoritesStore;
  private _favoritesService?: FavoritesService;
  private _loginStore?: LoginStore;
  private _loginService?: LoginService;
  private _officesStore?: OfficesStore;
  private _officesService?: OfficesService;
  private _regionsStore?: RegionsStore;
  private _regionsService?: RegionsService;
  private _reservationsStore?: ReservationsStore;
  private _reservationsService?: ReservationsService;
  private _roomsStore?: RoomsStore;
  private _roomsService?: RoomsService;
  private _snackbarStore?: SnackbarStore;
  private _usersStore?: UsersStore;
  private _usersService?: UsersService;
  private _schedulerStore?: SchedulerStore; 
  private _schedulerService?: SchedulerService;
  private _workspacesStore?: WorkspacesStore;
  private _workspacesService?: WorkspacesService;
  private _keycloakService?: KeycloakService;
  private _editStore?: EditStore;
  private _confirmationDialogStore?: ConfirmationDialogStore;
  private _departmentsStore?: DepartmentsStore;
  private _departmentsService?: DepartmentsService;
  private _divisionsStore?: DivisionsStore;
  private _divisionsService?: DivisionsService;
  private _domainsStore?: DomainsStore;
  private _domainsService?: DomainsService;
  private _premisesStore?: PremisesStore;
  private _premisesService?: PremisesService;
  private _roomStore?:RoomStore;
  private _roomService?:RoomService;
  private _distributionStore?: DistributionStore;
  private _distributionService?: DistributionService;

  get adminStore() {
    if (!this._adminStore) {
      this._adminStore = new AdminStore();
    }
    return this._adminStore;
  }

  get adminService() {
    if (!this._adminService) {
      this._adminService = new AdminService();
    }
    return this._adminService;
  }

  get apiService() {
    if (!this._apiService) {
      this._apiService = new ApiService(this.keycloakService);
    }
    return this._apiService;
  }

  get favoritesStore() {
    if (!this._favoritesStore) {
      this._favoritesStore = new FavoritesStore();
    }
    return this._favoritesStore;
  }

  get favoritesService() {
    if (!this._favoritesService) {
      this._favoritesService = new FavoritesService();
    }
    return this._favoritesService;
  }

  get loginStore() {
    if (!this._loginStore) {
      this._loginStore = new LoginStore();
    }
    return this._loginStore;
  }

  get loginService() {
    if (!this._loginService) {
      this._loginService = new LoginService();
    }
    return this._loginService;
  }

  get officesStore() {
    if (!this._officesStore) {
      this._officesStore = new OfficesStore();
    }
    return this._officesStore;
  }

  get officesService() {
    if (!this._officesService) {
      this._officesService = new OfficesService();
    }
    return this._officesService;
  }

  get premisesStore() {
    if (!this._premisesStore) {
      this._premisesStore = new PremisesStore();
    }
    return this._premisesStore;
  }

  get premisesService() {
    if (!this._premisesService) {
      this._premisesService = new PremisesService();
    }
    return this._premisesService;
  }

  get regionsStore() {
    if (!this._regionsStore) {
      this._regionsStore = new RegionsStore();
    }
    return this._regionsStore;
  }

  get regionsService() {
    if (!this._regionsService) {
      this._regionsService = new RegionsService();
    }
    return this._regionsService;
  }

  get reservationsStore() {
    if (!this._reservationsStore) {
      this._reservationsStore = new ReservationsStore();
    }
    return this._reservationsStore;
  }

  get reservationsService() {
    if (!this._reservationsService) {
      this._reservationsService = new ReservationsService();
    }
    return this._reservationsService;
  }

  get roomsStore() {
    if (!this._roomsStore) {
      this._roomsStore = new RoomsStore();
    }
    return this._roomsStore;
  }

  get roomsService() {
    if (!this._roomsService) {
      this._roomsService = new RoomsService();
    }
    return this._roomsService;
  }

  get snackbarStore() {
    if (!this._snackbarStore) {
      this._snackbarStore = new SnackbarStore();
    }
    return this._snackbarStore;
  }

  get usersStore() {
    if (!this._usersStore) {
      this._usersStore = new UsersStore();
    }
    return this._usersStore;
  }

  get usersService() {
    if (!this._usersService) {
      this._usersService = new UsersService();
    }
    return this._usersService;
  }

  get schedulerStore() {
    if (!this._schedulerStore) {
      this._schedulerStore = new SchedulerStore();
    }
    return this._schedulerStore;
  }

  get schedulerService() {
    if (!this._schedulerService) {
      this._schedulerService = new SchedulerService();
    }
    return this._schedulerService;
  }

  get workspacesStore() {
    if (!this._workspacesStore) {
      this._workspacesStore = new WorkspacesStore();
    }
    return this._workspacesStore;
  }

  get workspacesService() {
    if (!this._workspacesService) {
      this._workspacesService = new WorkspacesService();
    }
    return this._workspacesService;
  }

  get keycloakService() {
    if (!this._keycloakService) {
      this._keycloakService = new KeycloakService();
    }
    return this._keycloakService;
  }

  get editStore() {
    if (!this._editStore) {
      this._editStore = new EditStore();
    }
    return this._editStore;
  }

  get confirmationDialogStore() {
    if (!this._confirmationDialogStore) {
      this._confirmationDialogStore = new ConfirmationDialogStore();
    }
    return this._confirmationDialogStore;
  }

  get departmentsStore() {
    if (!this._departmentsStore) {
      this._departmentsStore = new DepartmentsStore();
    }
    return this._departmentsStore;
  }

  get departmentsService() {
    if (!this._departmentsService) {
      this._departmentsService = new DepartmentsService();
    }
    return this._departmentsService;
  }

  get divisionsService() {
    if (!this._divisionsService) {
      this._divisionsService = new DivisionsService();
    }
    return this._divisionsService;
  }

  get divisionsStore() {
    if (!this._divisionsStore) {
      this._divisionsStore = new DivisionsStore();
    }
    return this._divisionsStore;
  }

  get domainsService() {
    if (!this._domainsService) {
      this._domainsService = new DomainsService();
    }
    return this._domainsService;
  }

  get domainsStore() {
    if (!this._domainsStore) {
      this._domainsStore = new DomainsStore();
    }
    return this._domainsStore;
  }

  get roomStore() {
    if (!this._roomStore) {
      this._roomStore = new RoomStore();
    }
    return this._roomStore;
  }

  get roomService() {
    if (!this._roomService) {
      this._roomService = new RoomService();
    }
    return this._roomService;
  }

  get distributionStore() {
    if (!this._distributionStore) {
      this._distributionStore = new DistributionStore();
    }
    return this._distributionStore;
  }

  get distributionService() {
    if (!this._distributionService) {
      this._distributionService = new DistributionService();
    }
    return this._distributionService;
  }
}

export const serviceContainer = new ServiceContainer();
