import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Autocomplete,
  TextField,
  FormControl, 
  Pagination,
  Button,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import { DeleteOutline, Close } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { add } from 'date-fns';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

import { paginator } from '../DistributionTable/DistributionTable.helpers';
import { OPTIONS_STATUS_MOCK, StatusOptionType, MenuProps, timeAllHours } from './DistributionLetterHistory.constants';
import SearchForm from '../SearchForm/SearchForm';
import { DistributionTable } from '../DistributionTable/DistributionTable';
import { DistributionLetterForm } from 'UI/components';

import { INewsletterDto } from 'types';
import { serviceContainer } from 'services';

import './DistributionLetterHistory.scss';

const MIN_DATE = new Date(new Date().setMonth(new Date().getMonth() - 6));
const MAX_DATE = add(new Date(), { months: 24 });

export const DistributionLetterHistory = observer(() => {
  const [page, setPage] = useState<number>(1);
  const [row, setRow] = useState<INewsletterDto | null>(null);
  const [totalCounts, setTotalCounts] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [status, setStatus] = useState<StatusOptionType | null>(null);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [valueStart, setValueStart] = useState<string>('');
  const [valueEnd, setValueEnd] = useState<string>('');
  const [fullDateStart, setFullDateStart] = useState<Date | null>(null);
  const [fullDateEnd, setFullDateEnd] = useState<Date | null>(null);
  const [openDatePickerFrom, setOpenDatePickerFrom] = useState(false);
  const [openDatePickerTo, setOpenDatePickerTo] = useState(false);

  const { allNewsletters } = serviceContainer.distributionStore;

  const handleChange = (_: unknown, value: number) => {
    setPage(paginator(allNewsletters, value, 7).page);
  };

  useEffect(() => {
    if (serviceContainer.adminStore.allRoles && !serviceContainer.adminStore.allRoles.length) {
      serviceContainer.adminService.getAllRoles();
    }
  }, []);

  useEffect(() => {
    if (dateFrom || valueStart) {
      const date = dateFrom?.toDateString();
      setFullDateStart(new Date(`${date} ${valueStart}`));
    }
  }, [dateFrom]);

  useEffect(() => {
    if (dateTo || valueEnd) {
      const date = dateTo?.toDateString();
      setFullDateEnd(new Date(`${date} ${valueEnd}`));
    }
  }, [dateTo]);

  const submitSearchHandler = (value: string) => {
    serviceContainer.distributionStore.setFilter(value);
  };

  const handleCancel = () => {
    setStatus(null);
    setDateFrom(null);
    setDateTo(null);
    setValueEnd('');
    setFullDateEnd(null);
    setValueStart('');
    setFullDateStart(null);
  };

  const ButtonFromActionToday = ({ onSetToday }: PickersActionBarProps) => (
    <Button
      id='picker-actions-today-from'
      variant="text"
      onClick={() => {
        onSetToday();
        setOpenDatePickerFrom(true);
      }}
    >
      Сегодня
    </Button>
  );

  const ButtonToActionToday = ({ onSetToday }: PickersActionBarProps) => (
    <Button
      id='picker-actions-today-from'
      variant="text"
      onClick={() => {
        onSetToday();
        setOpenDatePickerTo(true);
      }}
    >
      Сегодня
    </Button>
  );

  return (
    <div className='distribution'>
      <div className="distribution-history">
        <h2 className="title distribution-history-title">
          История писем
        </h2>
        <div className="distribution-history-fields">
          <div className='distribution-history-search'>
            <SearchForm
              submitHandler={submitSearchHandler}
              clearHandler={() => serviceContainer.distributionStore.setFilter('')}
              placeholder="Поиск по записям"
            />
            <Button
              className='distribution-history-button'
              variant="contained"
              disabled={isNew}
              onClick={() => {
                setIsNew(true);
                setRow(null);
              }}
            >
              Создать
            </Button>
          </div>
          <h6 className='distribution-filters-title'>Фильтры</h6> 
          <FormControl className='distribution-filters-form'>
            <Autocomplete
              className='distribution-filters-form-select'
              id="status-select"
              options={OPTIONS_STATUS_MOCK}
              getOptionLabel={(option: StatusOptionType) => option.label}
              value={status}
              onChange={(e, newValue: StatusOptionType | null) => {
                setStatus(newValue);
              }}
              renderInput={(params) => (
                <TextField 
                  placeholder="Статус"
                  {...params} 
                />
              )}
            />
            <DatePicker
              label="От"
              open={openDatePickerFrom}
              value={dateFrom}
              mask="__.__.____"
              onChange={(value) => setDateFrom(value)}
              className='distribution-filters-form-input'
              onOpen={() => setOpenDatePickerFrom(true)}
              onClose={() => setOpenDatePickerFrom(false)}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              components={{
                ActionBar: ButtonFromActionToday,
              }}
              componentsProps={{
                actionBar: {
                  actions: ['today'],
                },
              }}
              renderInput={(params) => (
                <TextField
                  className='distribution-filters-input'
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'ДД.ММ.ГГГГ',
                    readOnly: true,
                    onClick:() => setOpenDatePickerFrom(true),
                  }}
                />
              )}
              InputProps={{  
                startAdornment: (
                  <>
                    {dateFrom && (
                      <IconButton
                        className='distribution-filters-input-icon'
                        onClick={() => {
                          setDateFrom(null);
                          setValueStart('');
                          setFullDateStart(null);
                        }}
                      >
                        <Close />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
            <FormControl
              className='distribution-filters-select-form'
              size="small"
              variant="standard"
            >
              <Select
                id='time-start'
                className='distribution-filters-select'
                value={valueStart}
                MenuProps={MenuProps}
                onChange={(e) => setValueStart(e.target.value)}
                displayEmpty={true}
                renderValue={value => value?.length ? value : (
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>чч:мм</span>
                )}
              >
                {timeAllHours.map((item) => {
                  return (
                    <MenuItem
                      key={item.slot}
                      value={item.slot}
                    >
                      {item.slot}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <DatePicker
              label="До"
              open={openDatePickerTo}
              value={dateTo}
              mask="__.__.____"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              components={{
                ActionBar: ButtonToActionToday,
              }}
              componentsProps={{
                actionBar: {
                  actions: ['today'],
                },
              }}
              onChange={(value) => setDateTo(value)}
              className='distribution-filters-form-input'
              onOpen={() => setOpenDatePickerTo(true)}
              onClose={() => setOpenDatePickerTo(false)}
              renderInput={(params) => (
                <TextField
                  className='distribution-filters-input'
                  variant='standard'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'ДД.ММ.ГГГГ',
                    readOnly: true,
                    onClick:()=> setOpenDatePickerTo(true),
                  }}
                />
              )}
              InputProps={{                    
                startAdornment: (
                  <>
                    {dateTo ? (
                      <IconButton
                        className='distribution-filters-input-icon'
                        onClick={() => {
                          setDateTo(null);
                          setValueEnd('');
                          setFullDateEnd(null);
                        }}
                      >
                        <Close />
                      </IconButton>
                    ) : null}
                  </>
                ),
              }}
            />
            <FormControl
              className='distribution-filters-select-form'
              size="small"
              variant="standard"
            >
              <Select
                id='time-end'
                className='distribution-filters-select'
                value={valueEnd}
                MenuProps={MenuProps}
                onChange={(e) => setValueEnd(e.target.value)}
                displayEmpty={true}
                renderValue={value => value?.length ? value : (
                  <span style={{ color: 'rgba(0, 0, 0, 0.42)' }}>чч:мм</span>
                )}
              >
                {timeAllHours.map((item) => {
                  return (
                    <MenuItem
                      key={item.slot}
                      value={item.slot}
                    >
                      {item.slot}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormControl>
          <div style={{ display: 'inline' }}>
            <Button
              variant="text"
              className='delete-icon'
              onClick={handleCancel}
              startIcon={<DeleteOutline />}
            >
              Очистить параметры
            </Button>
          </div>
        </div>
        <div className='distribution-block'>
          <DistributionTable
            setRow={setRow}
            page={page}
            totalCounts={totalCounts}
            setTotalCounts={setTotalCounts}
            setTotalPages={setTotalPages}
            status={status?.value}
            row={row}
            dateFrom={fullDateStart}
            dateTo={fullDateEnd}
          />
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          className='distribution-table-pagination'
        />
      </div>
      <DistributionLetterForm
        row={row}
        setRow={setRow}
        setIsNew={setIsNew}
        page={page}
        isNew={isNew}
      />
    </div>
  );
});
