import { serviceContainer } from '../ServiceContainer';
import { toInternalData, toInternalRegion } from './helpers';
import { TRegionTableInternal } from '../../pages/AdministrationPage/RegionsTable/RegionsTable.type';

export class RegionsService {
  
  async loadRegions() {
    serviceContainer.regionsStore.loadingRegions = false;
    await serviceContainer.apiService.getRegions().then((res) => {
      if (res) {
        serviceContainer.regionsStore.regions = res.data.data;
        serviceContainer.regionsStore.setRegionsTable(toInternalData(res.data.data));
      }
      serviceContainer.regionsStore.regionsLoaded = true;
      serviceContainer.regionsStore.loadingRegions = false;
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
  }

  async addRegion(params: TRegionTableInternal) {
    const region = toInternalRegion(params);
    await serviceContainer.apiService.createRegion(region).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлен ${params.regionName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.regionsService.loadRegions();
  }

  async deleteRegion(regionId: string) {
    await serviceContainer.apiService.deleteRegion(regionId).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно удален ${regionId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await this.loadRegions();
  }

  async updateRegion(params: TRegionTableInternal) {
    const region = toInternalRegion(params);
    await serviceContainer.apiService.updateRegion(region).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно изменен ${params.regionName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await this.loadRegions();
  }

  async toggleAccess(params: TRegionTableInternal) {
    const region = toInternalRegion(params);
    region.active = !region.active;
    await serviceContainer.apiService.updateRegion(region).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно ${region.active ? 'разблокирован' : 'заблокирован'} ${params.regionName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await this.loadRegions();
  }
}
