import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ReactComponent as DoorSvg } from 'assets/svg/decor/door.svg';
import { serviceContainer } from 'services';
import { RoomTooltip } from '../../RoomTooltip/RoomTooltip';
import { Status } from 'types/commonTypes';

import './Door.scss';

interface DoorProps {
  number:number;
}

const isMobile = window.innerWidth < 600;

export const Door = observer(({ number }: DoorProps) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const history = useHistory();

  const { roomId } = useParams<{ roomId: string, officeId: string }>();

  const onClick = () => {
    if (serviceContainer.roomStore.isEdit) {
      if (serviceContainer.roomStore.selected === number) {
        serviceContainer.roomStore.setSelected(null);
      } else {
        serviceContainer.roomStore.setSelected(number);
      }
    } else {
      history.push(`${history.location.pathname}/${roomId}.${number}`);
    }
  };

  const biggerSide = serviceContainer.roomStore.columns > serviceContainer.roomStore.rows ?
    serviceContainer.roomStore.columns :
    serviceContainer.roomStore.rows;
  const scale = 65 / biggerSide;
  const currentDoor = serviceContainer.roomStore.draggables[number - 1];

  const reservatorIcon = currentDoor?.reservatorIcon || '';
  const hasUserIcon = Object.keys(serviceContainer.usersStore.userIcon).includes(reservatorIcon);

  const { status, reservator } = currentDoor ? currentDoor : { status: 'free' as Status, reservator: undefined };

  useEffect(() => {
    if (reservatorIcon && !hasUserIcon && isTooltipOpen) {
      serviceContainer.usersService.getBlobImageByUrl(reservatorIcon).then((data) => {
        serviceContainer.usersStore.setUserIcon(data, reservatorIcon);
      });
    }
  }, [reservatorIcon, isTooltipOpen, hasUserIcon]);

  return (
    <RoomTooltip
      label='Локер'
      id={number}
      status={status}
      reservator={reservator}
      reservatorIcon={reservatorIcon}
      disabled={serviceContainer.roomStore.isEdit}
      onOpen={()=>setTooltipOpen(true)}
      onClose={()=>setTooltipOpen(false)}
      divisionAcl={currentDoor?.division_acl}
    >
      <div
        className={`door ${serviceContainer.roomStore.selected === number ? 'selected' : ''}`}
        onClick={onClick}
        style={isMobile ? {
          height: `calc(calc(100vh - ${scale})/${serviceContainer.roomStore.rows})vh`,          
          width: `${(100 - scale) / serviceContainer.roomStore.columns}vw`,
        } : {
          height:`${scale}vh`,
          width:`${scale}vh`,
        }}
      >
        <DoorSvg/>
        <div className={`circle ${status}`}>
          {number}
        </div>
      </div>
    </RoomTooltip>);
});
