import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { headCells, STATUS } from './DistributionTable.constants';
import { paginator } from './DistributionTable.helpers';
import { serviceContainer } from 'services';
import { INewsletterDto } from 'types';
import { getDateAndTimeSlot } from 'UI/components/PickerPlace/Pickers/helpers';
import { PageLoader } from 'UI/components';

import './DistributionTable.scss';

type DistributionTableProps = {
  page: number;
  setRow: Dispatch<SetStateAction<INewsletterDto | null>>;
  setTotalCounts: Dispatch<SetStateAction<number>>;
  totalCounts: number;
  setTotalPages: Dispatch<SetStateAction<number>>;
  status: string | undefined;
  row: INewsletterDto | null;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export const DistributionTable = observer(({ 
  page, 
  setRow, 
  setTotalCounts,
  totalCounts,
  setTotalPages,
  status,
  row,
  dateFrom,
  dateTo,
}: DistributionTableProps) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('newsletter_id');

  const search = serviceContainer.distributionStore.filter;

  const isSelected = (id: number | undefined) => row?.id === id;

  const { allNewsletters, isLoadingNewsletter } = serviceContainer.distributionStore;

  useEffect(() => {
    let dateFromField;
    let dateToField;
    if (dateFrom) {
      dateFromField = new Date(dateFrom).toISOString();
    }
    if (dateTo) {
      dateToField = new Date(dateTo).toISOString();
    }
    const paginationParams = {
      page,
      pageSize: 7,
      search,
      status,
      dateFrom: dateFromField,
      dateTo: dateToField,
    };
    const sortingMethod = order.toUpperCase();
    serviceContainer.distributionService.loadAllNewsletters(orderBy, sortingMethod, paginationParams)
      .then((res) => {
        if (res) {
          setTotalCounts(res.meta.totalCount);
          setTotalPages(res.meta.totalPages);
        }
      });
  }, [page, totalCounts, orderBy, order, search, status, dateFrom, dateTo]);

  const sortHandler = (prop: any) => {
    const isAsc = orderBy === prop && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(prop);
  };

  return (
    <div className='distribution-table'>
      <div className='table-wrapper'>
        {allNewsletters?.length > 0 ? (
          <TableContainer
            className='distribution-table-container'
            style={{ overflowX: 'hidden' }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className='table'
            >
              <TableHead className="distribution_header">
                <TableRow className='distribution_row'>
                  {headCells.map((headCell) => (
                    <TableCell
                      className='distribution_cell'
                      key={headCell.id}
                      align='left'
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => sortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className='distribution_body'>
                {paginator(allNewsletters, page, 7).data.map((item) => {
                  const isItemSelected = isSelected(item.id);
                  return (
                    <TableRow
                      hover
                      className='distribution_row'
                      role="checkbox"
                      onClick={() => setRow(item)}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                      tabIndex={-1}
                      key={item.id}
                    >
                      <TableCell
                        sx={{ width: '10%' }}
                        className='distribution_cell'
                        align="left"
                      >{item.id}</TableCell>
                      <TableCell
                        className='distribution_cell'
                        sx={{ width: '20%' }}
                        align="left"
                      >
                        <span style={{ color: STATUS[item.status].color }}>{STATUS[item.status].label}</span>
                      </TableCell>
                      <TableCell
                        className='distribution_cell subject'
                        sx={{ width: '40%' }}
                        align="left"
                      >{item.subject}</TableCell>
                      <TableCell
                        className='distribution_cell data'
                        sx={{ width: '30%' }}
                        align="left"
                      >{item.schedule ? getDateAndTimeSlot(new Date(item.schedule)) : null}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            {isLoadingNewsletter ? <PageLoader /> : (
              <Typography className='not-found'>
                В этом разделе пока нет писем 
              </Typography>
            ) }
          </>
        )}
      </div>
    </div>
  );
});