import { serviceContainer } from '../index';
import { Scheduler } from 'types/otherTypes';

export class SchedulerService {

  async loadAllSchedulers() {
    const schedulers = (await serviceContainer.apiService.getAllSchedulers());
    serviceContainer.schedulerStore.schedulers = schedulers.data;
    serviceContainer.schedulerStore.filteredSchedulers = schedulers.data;
  }

  async loadScheduler() {
    const scheduler = (await serviceContainer.apiService.getScheduler());
    serviceContainer.schedulerStore.scheduler = scheduler.data.data;
  }

  async switchScheduler(value: boolean, scheduler_id: string) {
    try {
      if (value) {
        await serviceContainer.apiService.enableScheduler(scheduler_id);
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: 'Запуск запланирован',
          severity: 'success',
        });
      } else {
        await serviceContainer.apiService.disableScheduler(scheduler_id);
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: 'Запуск отключён',
          severity: 'success',
        });
      }
      
      this.loadAllSchedulers();
    } catch (error: any) {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`Возникла ошибка ${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    }
  }
  
}
