import { action, makeAutoObservable, toJS } from 'mobx';
import { RegionDto } from '../types/commonTypes';
import { TRegionTableInternal, TSnackbarProps } from '../pages/AdministrationPage/RegionsTable/RegionsTable.type';


export class RegionsStore {
  private _regionsTable: TRegionTableInternal[] = [];
  private _regions: RegionDto[] = [];
  private _regionsLoaded = false;
  private _loadingRegions = false;
  private _isRequestError = false;
  private _filter: string = '';
  private _isAddingRegion = false;
  private _dropdownRegion: TRegionTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _regionInfoForEdit: TRegionTableInternal | null = null;
  private _snackbar: TSnackbarProps = {
    open: false,
    message: '',
    severity: 'error',
  };

  constructor() {
    makeAutoObservable(this);
  }

  get regions(): RegionDto[] {
    return toJS(this._regions);
  }

  set regions(regions: RegionDto[]) {
    this._regions = regions;
  }

  get regionsTable(): TRegionTableInternal[] {
    return toJS(this._regionsTable);
  }

  setRegionsTable(regionsTable: TRegionTableInternal[]) {
    this._regionsTable = regionsTable;
  }

  get isRequestError(): boolean {
    return toJS(this._isRequestError);
  }

  get loadingRegions(): boolean {
    return toJS(this._loadingRegions);
  }

  @action
  set loadingRegions(value: boolean) {
    this._loadingRegions = value;
  }

  get regionsLoaded(): boolean {
    return toJS(this._regionsLoaded);
  }

  @action
  set regionsLoaded(value: boolean) {
    this._regionsLoaded = value;
  }

  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingRegion() {
    return this._isAddingRegion;
  }

  setIsAddingRegion(value: boolean) {
    this._isAddingRegion = value;
  }

  get dropdownRegion() {
    return this._dropdownRegion;
  }

  setDropdownRegion(value: TRegionTableInternal) {
    this._dropdownRegion = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get snackbar() {
    return this._snackbar;
  }

  setSnackbar(value: TSnackbarProps) {
    this._snackbar = value;
  }

  get regionInfoForEdit() {
    return this._regionInfoForEdit;
  }

  setRegionInfoForEdit(value: TRegionTableInternal | null) {
    this._regionInfoForEdit = value;
  }

  clearRegions() {
    this._regions = [];
  }
}
