import moment from 'moment';

export const isBookingActual = (endDate: string | null, isPermanent: boolean) => {
  if (endDate === null || isPermanent) return true;
  const now = +moment(new Date()).format('x');
  const end = +moment(endDate).format('x');
  return end - now > 0;
};

export const tabStyles = {
  textTransform: 'capitalize',
  fontSize: '16px',
  padding: 0,
  minWidth: 'auto',
  justifyContent: 'flex-start',
  minHeight: '30px',
};

export const sortOptions = {
  userName:'По имени',
  dateStart:'С начала',
  dateEnd:'С конца',
  regionName:'По городу',
  officeName:'По офису',
  roomName:'По кабинету',
  desktop:'По столу',
};