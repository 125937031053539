import { serviceContainer } from 'services';

export const loadRegionsAndOffices = async () => {
  if (!serviceContainer.regionsStore.regionsLoaded) {
    await serviceContainer.regionsService.loadRegions();
  }
};

export const loadOffice = async (regionId: string | undefined) => {
  if (regionId && !serviceContainer.officesStore.officesLoaded) {
    await serviceContainer.officesService.loadOfficeByRegionId(regionId);
  }
};

export const loadRooms = async (officeId: string | undefined) => {
  if (officeId) {
    await serviceContainer.roomsService.loadRooms(officeId);
  }
};

export const loadWorkspaces = async (roomId: string | undefined) => {
  if (roomId && !serviceContainer.workspacesStore.hasWorkspaces(roomId)) {
    await serviceContainer.workspacesService.loadWorkspaces(roomId);
  }
};