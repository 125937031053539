import './PagePlaceholder.scss';

interface PagePlaceholderProps {
  icon: JSX.Element;
  text: string;
}

export const PagePlaceholder = ({ icon, text }:PagePlaceholderProps) =>
  <div className='page-placeholder'>
    {icon}
    <div>{text}</div>
  </div>;