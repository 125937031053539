import { IDivision } from '../../types/otherTypes';
import { TDivisionTableInternal } from '../../pages/AdministrationPage/DivisionsTable/DivisionsTable.type';

export const toInternalData = (data: IDivision[]): TDivisionTableInternal[] => {
  return data.map(item => {
    return {
      divisionId: item.divisionId,
      divisionName: item.divisionName,
      departmentId: item.departmentId,
    };
  });
};

export const toInternalOption = (data: IDivision[]): string[] => {
  function unique(arr: string[]) {
    return Array.from(new Set(arr));
  }

  return unique(data.map(item => {
    return item.departmentId;
  }));
};
