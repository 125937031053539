import { makeAutoObservable, toJS, action } from 'mobx';
import { ReactNode, JSXElementConstructor, ReactElement } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';
import { TransitionProps } from '@mui/material/transitions';
import { Fade } from '@mui/material';

type TransitionComponent = JSXElementConstructor<TransitionProps & { children: ReactElement<any, any> }>;

type Snackbar = {
  open?: boolean;
  message?: string | ReactNode;
  severity?: AlertColor;
};

export class SnackbarStore {
  private _snackbar: Snackbar = {};
  private _transitionComponent: TransitionComponent | undefined = Fade;

  constructor() {
    makeAutoObservable(this);
  }

  get snackbar() {
    return toJS(this._snackbar);
  }

  get open() {
    return toJS(this._snackbar.open);
  }

  get message() {
    return toJS(this._snackbar.message);
  }

  get severity() {
    return toJS(this._snackbar.severity);
  }

  get transitionComponent() {
    return toJS(this._transitionComponent);
  }

  @action
  setSnackbar(value: Snackbar) {
    this._snackbar = value;
  }

  @action
  setOpen(value: boolean) {
    this._snackbar.open = value;
  }

  @action
  setMessage(value: string | ReactNode) {
    this._snackbar.message = value;
  }

  @action
  setSeverity(value: AlertColor) {
    this._snackbar.severity = value;
  }

  @action
  setTransitionComponent(value: TransitionComponent) {
    this._transitionComponent = value;
  }

  showHttpErrorSnackbar(error: any) {
    this.setSnackbar({
      open: true,
      message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
      severity: 'error',
    });
  }
}
