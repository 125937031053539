import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LightTooltip = styled(({ className, ...props } :TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f2f2f2',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 12px 28px rgba(78, 81, 85, 0.2)',
    fontSize: 11,
  },
  ['& div > span']: {
    color: '#f2f2f2',
    boxShadow: '0px 12px 28px rgba(78, 81, 85, 0.2)',
  },
}));