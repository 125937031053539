import { ThemeProvider, createTheme } from '@mui/material';
import { ReactNode } from 'react';

/**
 * Для того чтобы обертка работала, обарачивоемому компоненту нужно добавить пропс color="primary"
 * @example 
 * <ColorProvider color="red">
 *   <IconButton color="primary"> ... </IconButton>
 * </ColorProvider>
 */
export const ColorProvider = ({
  color,
  children,
}: {
  color: string,
  children: ReactNode,
}) => {
  return (
    // @ts-ignore
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: color,
          },
        },
      })}
    >
      {children}
    </ThemeProvider>
  );
};