import React from 'react';
import { CircularProgress } from '@mui/material';
import './PageLoader.scss';

const PageLoader = () => {
  return (
    <div className="page_loader">
      <CircularProgress />
    </div>
  );
};

export default PageLoader;
