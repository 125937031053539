import { differenceInCalendarDays, add } from 'date-fns';
import { Event } from 'react-big-calendar';
import { Reservation, DatesString } from '../types/commonTypes';

export const formatReservations = (reservations: Reservation<DatesString>[] | undefined): Event[] =>
  reservations?.map(({ id, attributes: r }) => {
    const end = r.dateEnd === null ? add(new Date(r.dateStart.split('.')[0]), { years: 5 }) : new Date(r.dateEnd.split('.')[0]);
    const endRounded = add(end, { minutes: 1 });
    const longerThanDay = differenceInCalendarDays(end, new Date(r.dateStart.split('.')[0])) > 0;

    return {
      id,
      start: new Date(r.dateStart.split('.')[0]),
      end: endRounded,
      allDay: r.isPlaceReservedForever || longerThanDay,
      title: r.userName,
    };
  }) || [];
