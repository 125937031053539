import { action, makeAutoObservable, toJS } from 'mobx';
import { IFavorites } from '../types/commonTypes';

export class FavoritesStore {
  private _favorites: { [key: string]: IFavorites[] } = {};
  private _favoritesLoaded = false;
  private _isRequestError = false;
  private _loadingFavorites = false;
  
  constructor() {
    makeAutoObservable(this);
  }
 
  get loadingFavorites(): boolean {
    return toJS(this._loadingFavorites);
  }
  
  @action
  set loadingFavorites(value: boolean) {
    this._loadingFavorites = value;
  }
	
  get favoritesLoaded(): boolean {
    return toJS(this._favoritesLoaded);
  }
  
  getFavoritesByKey(key: string): IFavorites[] {
    return toJS(this._favorites[key] ?? []);
  }
  
  @action
  setFavoritesByKey(key: string, value: IFavorites[]) {
    return toJS(this._favorites[key] = value);
  }
  
  get favorites() {
    return toJS(this._favorites);
  }
  
  @action
  set isRequestError(value: boolean) {
    this._isRequestError = value;
  }
}
