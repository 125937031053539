import { Button } from '@mui/material';
import { AttachFile, ErrorOutline } from '@mui/icons-material';
import { TooltipLight } from '../../../Tooltip/Tooltip';
import TooltipText from './TooltipText';
import { getExtension } from './helpers';
import { allowedFileExtensions } from '../../mocks';
import { serviceContainer } from 'services';

type OwnProps = {
  setAttachedFile: (file: File[]) => void,
};

const AttachButton = ({ setAttachedFile }: OwnProps) => {
  const loadFile = (e: any) => {
    let array: File[] = [];
    e.preventDefault();
    let attachedFileSize = 0;
    Object.values(e.currentTarget.files).forEach((item: any) => {
      attachedFileSize += item.size;
    });
    
    for (const file of e.currentTarget.files) {
      const extension = getExtension(file.name);
      if (attachedFileSize > 1e+7) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: 'Суммарный размер файлов не должен превышать 10 MB',
          severity: 'error',
        });
        return;
      }

      if (!allowedFileExtensions.includes(extension as string)) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: 'Поддерживаемые форматы: jpeg, jpg, png, gif, bmp',
          severity: 'error',
        });
        return;
      }
      array = [...array, file];
    }

    setAttachedFile(array);
  };
  
  return (
    <>
      <Button
        className='button'
        variant='outlined'
        startIcon={<AttachFile/>}
        component="label"
      >
        Прикрепить файлы
        <input
          hidden
          accept={'.' + allowedFileExtensions.join(',.')}
          type="file"
          onChange={(e) => loadFile(e)}
          value=""
          multiple
        />
      </Button>
      <TooltipLight
        type='iconHelp'
        title={<TooltipText/>}
      >
        <ErrorOutline className='tooltip-icon' />
      </TooltipLight>
    </>
  );
};

export default AttachButton;