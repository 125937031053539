import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
} from '@mui/material';
import { routes } from '../../../../routes';

import './FAQicon.scss';

const buttonData = [
  {
    ...routes.HELP_PAGE,
    icon: <div className='help-icon' />,
  },
];

const FAQicon = observer(() => {
  const history = useHistory();

  const handleClickMenu = (path: string): void => {
    history.push(path);
  };

  const faqIcon = buttonData.map((btn) => (
    <Button
      className='button faq-button'
      color='inherit'
      key={btn.pageName}
      onClick={() => {
        handleClickMenu(btn.path);
      }}
    >
      {btn.icon}
    </Button>
  ));

  return (
    <Box className='faq-icon'>
      {faqIcon}
    </Box>
  );
});

export default FAQicon;
