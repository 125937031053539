import { observer } from 'mobx-react';
import { Dispatch, SetStateAction } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { serviceContainer } from 'services';
import { User, UserField } from 'types/otherTypes';
import { AdminTableRow } from '../AdminTableRow/AdminTableRow';

import './AdminTable.scss';

const userFields:{ prop: UserField, label: string }[] = [
  {
    prop: 'userName',
    label: 'ФИО',
  },
  {
    prop: 'userRole',
    label: 'Роль',
  },
  {
    prop: 'userId',
    label: 'Логин',
  },
  {
    prop: 'division',
    label: 'Подразделение',
  },
  {
    prop: 'email',
    label: 'email',
  },
  {
    prop: 'lastUpdate',
    label: 'Дата обновления',
  },
];

export const AdminTable = observer(({
  filteredUserData,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}:{
  filteredUserData: User[],
  order: 'asc' | 'desc',
  setOrder: (v: 'asc' | 'desc') => void,
  orderBy: UserField,
  setOrderBy: Dispatch<SetStateAction<UserField>>,
})=>{
  const sortHandler = (prop: UserField) => {
    const isAsc = orderBy === prop && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(prop);
  };

  return <TableContainer
    className='data'
    component={Paper}
         >
    <Table
      stickyHeader
      className='table'
    >
      <TableHead>
        <TableRow className='admin-table-row header'>
          {userFields.map(({ prop, label })=>
            <TableCell key={prop}>
              <TableSortLabel
                active={orderBy === prop}
                direction={orderBy === prop ? order : 'asc'}
                onClick={() => sortHandler(prop)}
              >
                {label}
              </TableSortLabel>
            </TableCell>,
          )}
          <TableCell
            className='control'
            align='right'
            key='control'
          />
        </TableRow>
        {serviceContainer.adminStore.isAddingUser ?
          <AdminTableRow
            key='new-user'
            user={{
              userName:'',
              userRole:'user',
              userId:'',
              division:'',
              email:'',
              disabled:false,
              lastUpdate:'',
            }}
            isAdding
          /> :
          null}
      </TableHead>
      <TableBody>
        {filteredUserData.map((user) =>
          <AdminTableRow
            key={user.userId}
            user={user}
          />,
        )}
      </TableBody>
    </Table>
  </TableContainer>;
});
