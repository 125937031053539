import { RoomsTableInternal } from '../../stores';
import { IRoom } from '../../types/commonTypes';

const INIT_CELL = {
  text: 'text',
  input: 'input',
  textIconCell: 'textIconCell',
  textIconCellEdit: 'textIconCellEdit',
  checkbox: 'checkbox',
};

export const toInternalData = (data: IRoom[]): RoomsTableInternal[] => {
  return data.map(item => {
    return {
      workspaceId: {
        value: item.attributes.id,
        cell: INIT_CELL.text,
        cellEdit: INIT_CELL.input,
      },
      roomId: {
        value: item.attributes.office.name,
        cell: INIT_CELL.text,
        cellEdit: INIT_CELL.input,
      },
      workspaceNumber: {
        value: item.attributes.name,
        cell: INIT_CELL.text,
        cellEdit: INIT_CELL.input,
      },
      wtypeId: {
        value: item.attributes.title,
        cell: INIT_CELL.text,
        cellEdit: INIT_CELL.input,
      },
      roomType: {
        value: item.attributes.roomType,
        cell: INIT_CELL.text,
        cellEdit: INIT_CELL.input,
      },
      action: {
        value: '',
        cell: 'button',
        cellEdit: 'button',
      },
      id: item.attributes.id,
      disabled: false,
      edit: false,
      isNew: false,
    };
  });
};
