import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { Reservation, DatesString } from '../types/commonTypes';

export class ReservationsStore {
  private _reservations: Reservation<DatesString>[] | undefined = undefined;
  private _reservationsByWtype: { [key: string]:  Reservation<DatesString>[] } = {};
  private _loading: boolean = false;
  private _permission: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get loading() {
    return toJS(this._loading);
  }
  @action
  set loading(value) {
    this._loading = value;
  }

  get reservations() {
    return toJS(this._reservations);
  }
  
  @action
  set reservations(data) {
    this._reservations = data;
  }

  get permission() {
    return toJS(this._permission);
  }

  set permission(data) {
    this._permission = data;
  }

  getReservationsByWtype(wtype: string) {
    return toJS(this._reservationsByWtype[wtype]);
  }
  
  @action
  setReservationsByWtype(wtype: string, data: Reservation<DatesString>[]) {
    this._reservationsByWtype[wtype] = data;
  }

  @action
  clear() {
    runInAction(() => (this._reservations = []));
  }
  
  @action
  clearWtype() {
    runInAction(() => (this._reservationsByWtype = {}));
  }
}
