import { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import {
  Autocomplete,
  TextField,
  FormControl,
  Button,
  IconButton,
  Tooltip,
  InputLabel,
  Select, 
  MenuItem,
} from '@mui/material';
import { serviceContainer } from 'services';
import { INewsletterDto } from 'types';
import { TextEditor } from 'pages/DistributionPage/subcomponents/TextEditor/TextEditor';
import AttachButtonDistribution from './subcomponents/AttachButtonDistribution';
import FilePreviewDistribution from './subcomponents/FilePreviewDistribution';
import { DatePicker } from '@mui/x-date-pickers';
import type { NewsletterType } from './DistributionLetterForm.types';
import SliderCaptcha from '@slider-captcha/react';
import { Buffer } from 'buffer';
import { Delete, Close, HelpOutline } from '@mui/icons-material';
import { MenuProps, timeAllHours } from '../DistributionLetterHistory/DistributionLetterHistory.constants';
import { getTimeSlot } from 'UI/components/PickerPlace/Pickers/helpers';
import { 
  textForHelp,
  sortingMethod,
  sortingColumn,
  MIN_DATE,
  MAX_DATE,
} from './DistributionLetterForm.helpers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

import './DistributionLetterForm.scss';

type DistributionLetterFormProps = {
  row: INewsletterDto | null,
  setIsNew: Dispatch<SetStateAction<boolean>>,
  isNew: boolean,
  setRow: Dispatch<SetStateAction<INewsletterDto | null>>;
  page: number;
};

export const DistributionLetterForm = ({ row, isNew, setIsNew, setRow, page }: DistributionLetterFormProps) => {
  const [editRow, setEditRow] = useState<NewsletterType | null>(null);
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const [richText, setRichText] = useState('');
  const [tagBtnClicked, setTagBtnClicked] = useState<boolean>(false);
  const [attachedFile, setAttachedFile] = useState<File[] | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [clickSendMe, setClickSendMe] = useState<boolean>(false);
  const [timeValue, setTimeValue] = useState<string>('');
  const [dateValue, setDateValue] = useState<Date | null>(null);

  // Buffer нужен для slider captcha
  window.Buffer = window.Buffer || Buffer;

  const dateSend = dateValue && timeValue ? new Date(`${dateValue?.toDateString()} ${timeValue}`) : null;
  const hasAttachmentFiles = attachedFile && attachedFile.length > 0;

  const saveDisabledButton = !richText || richText === '<p><br></p>' || !editRow?.subject;
  const deleteDisabledButton = !row?.id || !isEdit;

  const paginationParams = {
    page,
    pageSize: 7,
  };

  const handleChangeText = (value: string) => {
    setRichText(value);
    setTagBtnClicked(false);
  };

  const handleCancel = () => {
    setEditRow(null);
    setRichText('');
    setIsEdit(false);
    setClickSendMe(false);
    setAttachedFile(undefined);
    setTimeValue('');
    setDateValue(null);
    setRow(null);
  };

  const clearEditRow = () => {
    setEditRow(null);
    setRichText('');
    setAttachedFile(undefined);
    setTimeValue('');
    setDateValue(null);
    setRow(null);
    setIsNew(false);
  };

  useEffect(() => {
    if (isNew) {
      handleCancel();
    }
  }, [isNew]);

  useEffect(() => {
    if (row?.attachment && row.attachment.length > 0) {
      setAttachedFile(row.attachment.map((item) => ({
        name: item.path, 
        size: item.size,
      })) as any);
    }
  }, [row]);

  useEffect(() => {
    if (row) {
      setEditRow(row);
      setRichText(row.body || '');
      setIsNew(false);
      setIsEdit(false);
      setTimeValue(row.schedule ? getTimeSlot(new Date(row.schedule)) : '');
      setDateValue(row.schedule ? new Date(row.schedule) : null);
    }
  }, [row]);

  const allRolesArray = useMemo(() => {
    return serviceContainer.adminStore.allRoles.map(({ id }) => id);
  }, [serviceContainer.adminStore.allRoles]);

  const sendFileNewsletterHandler = (id: number) => {
    const formData = new FormData();
    let filesName = '';

    if (attachedFile) {
      for (const file of attachedFile) {
        formData.append('files', file);
        filesName = `${filesName}${filesName ? ',' : ''}${file.name}`;
      }
      formData.append('filenames', filesName);
    }

    serviceContainer.apiService.sendFileNewsletter(id, formData);
  };

  const saveNewsletterHandler = async (statusNewsletter: string) => {
    const newNewsletter = {
      subject: editRow?.subject || '',
      body: richText,
      status: statusNewsletter,
      roles: editRow?.recipient_roles || [],
      schedule: dateSend && new Date(dateSend).toISOString(),
    };

    await serviceContainer.distributionService.createNewsletter(newNewsletter, sortingColumn, sortingMethod, paginationParams);
    if (serviceContainer.distributionStore.newNewsletter && attachedFile) {
      sendFileNewsletterHandler(serviceContainer.distributionStore.newNewsletter.id);
      setTimeout(() => {
        serviceContainer.distributionService.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);  
      }, 1000);
    }
    if (serviceContainer.distributionStore.newNewsletter) {
      handleCancel();
      setIsNew(false);
    }
  };

  const duplicateNewsletterHandler = async () => {
    const newNewsletter = {
      subject: editRow?.subject || '',
      body: richText,
      status: 'DRAFT',
      roles: editRow?.recipient_roles || [],
      schedule: null,
    };

    await serviceContainer.distributionService.duplicateNewsletter(newNewsletter, sortingColumn, sortingMethod, paginationParams);
    if (serviceContainer.distributionStore.newNewsletter && attachedFile) {
      sendFileNewsletterHandler(serviceContainer.distributionStore.newNewsletter.id);
      setTimeout(() => {
        serviceContainer.distributionService.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);  
      }, 1000);
    }
    if (serviceContainer.distributionStore.newNewsletter) {
      handleCancel();
      setIsNew(false);
    }
  };

  const sendMeHandler = async () => {
    if (row) {
      await serviceContainer.distributionService.sendMeNewsletter(row, sortingColumn, sortingMethod, paginationParams);
    }
    handleCancel();
  };

  const handleDeleteNewsletterFile =  () => {
    if (row && row.attachment) {
      row?.attachment.map(async (item) => {
        const { data } = await serviceContainer.apiService.deleteFileNewsletter(row.id, item.path);
        if (data) {
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: 'Файлы успешно удалены',
            severity: 'success',
          });
          setEditRow(data);
        } else {
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: 'Ошибка в удалении файлов',
            severity: 'error',
          });
        }
      });
    }
    setAttachedFile(undefined);
  };

  const updateNewsletterHandler = async (isDraft?: boolean) => {
    const updateNewsletter = {
      subject: editRow?.subject || '',
      status: isDraft ? 'DRAFT' : 'SEND_PENDING',
      body: richText,
      roles: editRow?.recipient_roles || [],
      schedule: dateSend && new Date(dateSend).toISOString(),
    };

    if (row) {
      await serviceContainer.distributionService.updateNewsletter(row?.id, updateNewsletter, sortingColumn, sortingMethod, paginationParams);
    }

    if (row && attachedFile) {
      sendFileNewsletterHandler(row.id);
      setTimeout(() => {
        serviceContainer.distributionService.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);  
      }, 1000);
    }
    handleCancel();
  };

  const planNewsletterHandler = async () => {
    const updateNewsletter = {
      subject: editRow?.subject || '',
      status: 'SEND_PENDING',
      body: richText,
      roles: editRow?.recipient_roles || [],
      schedule: dateSend && new Date(dateSend).toISOString(),
    };

    if (row) {
      await serviceContainer.distributionService.planNewsletter(row?.id, updateNewsletter, sortingColumn, sortingMethod, paginationParams);
    }

    if (row && attachedFile) {
      sendFileNewsletterHandler(row.id);
      setTimeout(() => {
        serviceContainer.distributionService.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);  
      }, 1000);
    }
    handleCancel();
  };

  const canceledNewsletterHandler = async () => {
    const updateNewsletter = {
      subject: editRow?.subject || '',
      status: 'CANCELED',
      body: richText,
      roles: editRow?.recipient_roles || [],
      schedule: dateSend && new Date(dateSend).toISOString(),
    };

    if (row) {
      await serviceContainer.distributionService.canceledNewsletter(row?.id, updateNewsletter, sortingColumn, sortingMethod, paginationParams);
    }

    handleCancel();
  };
  
  const handleDeleteNewsletter = async () => {
    if (row) {
      await serviceContainer.distributionService.deleteNewsletter(row as INewsletterDto, sortingColumn, sortingMethod, paginationParams);
    }
    handleCancel();
    setIsNew(true);
  };

  const verifiedCallback = (token: string) => {
    if (token) {
      sendMeHandler();
    }
  };

  const ButtonActionToday = ({ onSetToday }: PickersActionBarProps) => (
    <Button
      id='picker-actions-today'
      variant="text"
      onClick={() => {
        onSetToday();
        setOpenDatepicker(true);
      }}
    >
      Сегодня
    </Button>
  );
  
  const formControl = (
    <FormControl className='distribution-letter-form'>
      <TextField
        placeholder="Тема"
        variant="outlined"
        onChange={(e) => {
          const value = e.target.value;
          setEditRow({
            ...editRow,
            subject: value ? value : '',
          });
        }}
        className='distribution-letter-field' 
        value={editRow?.subject ? editRow.subject : ''}
        disabled={!(isNew || isEdit)}
        inputRef={input => input && isNew && input.focus()}
      />
      <Autocomplete
        multiple
        id="user-roles"
        className='distribution-letter-select'
        options={allRolesArray || row?.recipient_roles}
        getOptionLabel={(option) => option}
        value={editRow?.recipient_roles ? editRow?.recipient_roles : []}
        disabled={!(isNew || isEdit)}
        limitTags={2}
        onChange={(_, value) => {
          setEditRow({
            ...editRow,
            recipient_roles: typeof value === 'string' ? [value] : value,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className='distribution-letter-field-scroll'
            placeholder="Выберите группу пользователей" 
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <Tooltip
                    arrow
                    title={textForHelp}
                  >
                    <HelpOutline className='select-icon' />
                  </Tooltip>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <TextEditor
        tagBtnClicked={tagBtnClicked}
        setTagBtnClicked={setTagBtnClicked}
        richText={richText}
        handleChangeText={handleChangeText}
        isDistribution={!(isNew || isEdit)}
      />
      <div className={`distribution-letter-field-send-group ${hasAttachmentFiles ? 'reverse' : ''}`}>
        <AttachButtonDistribution
          setAttachedFile={setAttachedFile}
          isDisabled={!(isNew || isEdit)}
          attachedFile={attachedFile}
        />
        {attachedFile ? (
          <FilePreviewDistribution
            attachedFile={attachedFile}
            isDisabled={!(isNew || isEdit)}
            handleDeleteNewsletterFile={handleDeleteNewsletterFile}
          />
        ) : null}
      </div>
      <div className='distribution-letter-data-group'>
        <DatePicker
          label="Дата"
          open={openDatepicker}
          value={dateValue}
          mask="__.__.____"
          onChange={(value) => setDateValue(value)}
          disabled={!(isNew || isEdit)}
          views={['year', 'month', 'day']}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          onOpen={() => setOpenDatepicker(true)}
          onClose={() => setOpenDatepicker(false)}
          className='distribution-letter-input'
          components={{
            ActionBar: ButtonActionToday,
          }}
          componentsProps={{
            actionBar: {
              actions: ['today'],
            },
          }}
          renderInput={(params) => (
            <TextField
              className='distribution-letter-input'
              variant='outlined'
              InputLabelProps={{
                shrink: true,
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: 'ДД.ММ.ГГГГ',
                readOnly: true,
                onClick:()=>{},
              }}
            />
          )}
          InputProps={{       
            startAdornment: (
              <>
                {dateValue ? (
                  <IconButton
                    className='distribution-letter-input-icon'
                    onClick={() => {
                      setTimeValue('');
                      setDateValue(null);
                    }}
                  >
                    <Close />
                  </IconButton>
                ) : null}
              </>
            ),
          }}
        />
        <FormControl
          className='distribution-data-select-form'
          size='small'
        >
          <InputLabel
            className='distribution-data-select-label'
            id='time-label'
          >Время</InputLabel>
          <Select
            labelId='time-label'
            id='time'
            label="Время"
            className='distribution-data-select'
            value={timeValue}
            disabled={!(isNew || isEdit)}
            MenuProps={MenuProps}
            onChange={(e) => setTimeValue(e.target.value)}
          >
            {timeAllHours.map((item) => {
              return (
                <MenuItem
                  key={item.slot}
                  value={item.slot}
                >
                  {item.slot}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div> 
    </FormControl>
  );

  return (
    <div className='distribution-letter'>
      <h2 className="title distribution-letter-title">
        Письмо
        <Tooltip
          arrow
          title="Очистить"
        >
          <IconButton
            className='distribution-icon-close'
            onClick={clearEditRow}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </h2>
      {formControl}
      {clickSendMe && (
        <SliderCaptcha
          create="https://dev.joobpay.com/api/v1/auth/captcha/create"
          verify="https://dev.joobpay.com/api/v1/auth/captcha/verify"
          callback={verifiedCallback}
          text={{
            anchor: 'Потвердите отправку рассылки',
          }}       
        />
      )}
      <div className='distribution-letter-group'>
        <Tooltip
          arrow
          title="Удалить"
        >
          <span>
            <IconButton
              className='distribution-icon-delete'
              disabled={deleteDisabledButton}
              onClick={handleDeleteNewsletter}
            >
              <Delete className='icon-delete' />
            </IconButton>
          </span>
        </Tooltip>
        <Button
          className='distribution-letter-button'
          variant="contained"
          disabled={!row?.id || isEdit}
          onClick={() => setClickSendMe(true)}
        >
          Отправить себе
        </Button>
        {!isEdit && (
          <>
            {row ? (
              <Button
                className='distribution-letter-button'
                variant="outlined"
                disabled={row?.status === 'SENT'}
                onClick={() => setIsEdit(true)}
              >
                Редактировать
              </Button>
            ) : (
              <Button
                className='distribution-letter-button'
                variant="outlined"
                disabled={saveDisabledButton}
                onClick={() => saveNewsletterHandler('DRAFT')}
              >
                Сохранить черновик
              </Button>
            )}
          </>
        )}
        {isEdit && (
          <Button
            className='distribution-letter-button'
            variant="outlined"
            disabled={saveDisabledButton}
            onClick={() => updateNewsletterHandler(true)}
          >
            Сохранить черновик
          </Button>
        )}
        {isEdit ? (
          <Button
            className='distribution-letter-button'
            variant="contained"
            disabled={saveDisabledButton || !timeValue || !dateValue}
            onClick={() => updateNewsletterHandler()}
          >
            Запланировать
          </Button>
        ) : row?.id && row?.status === 'SEND_PENDING' ? (
          <Button
            className='distribution-letter-button'
            variant="contained"
            onClick={canceledNewsletterHandler}
          >
            Отменить отправку
          </Button>
        ) : (
          <Button
            className='distribution-letter-button'
            variant="contained"
            disabled={saveDisabledButton || !timeValue || !dateValue}
            onClick={() => {
              if (isNew) {
                saveNewsletterHandler('SEND_PENDING');
              } else if (row?.status === 'SENT') {
                duplicateNewsletterHandler();
              } else {
                planNewsletterHandler();
              }
            }}
          >
            {row?.status === 'SENT' ? 'Дублировать' : 'Запланировать'}
          </Button>
        )}
      </div>
    </div>
  );
};
