import { INewsletterDto } from 'types';

export function paginator(items: INewsletterDto[], current_page: number, per_page_items: number) {
  const page = current_page || 1,
    perPage = per_page_items || 1,
    totalPages = Math.ceil(items.length / perPage);

  return {
    page: page,
    per_page: perPage,
    pre_page: page - 1 ? page - 1 : null,
    next_page: totalPages > page ? page + 1 : null,
    total: items.length,
    total_pages: totalPages,
    data: items,
  };
}