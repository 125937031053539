import { serviceContainer } from './';
import { CreateFavorites } from '../types/commonTypes';

export class FavoritesService {
  
  async loadFavorites(userId: string) {
    if (Object.keys(serviceContainer.favoritesStore.favorites).includes(userId)) return;
    const result = await serviceContainer.apiService.getFavoritesByUserId(userId);
    serviceContainer.favoritesStore.setFavoritesByKey(userId, result.data.data);
    serviceContainer.favoritesStore.isRequestError = false;
    serviceContainer.favoritesStore.loadingFavorites = true;
  }
  
  async createFavorites(body: CreateFavorites) {
    let newFavorite;
    try {
      newFavorite = (await serviceContainer.apiService.createFavorites(body)).data.data;
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message: `Добавлено в избранное «${body.placeType} № ${body.roomNumber}»`,
        severity: 'success',
      });
    } catch (error: any) {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    }
    return newFavorite;
  }
  
  async deleteFavorites(userId: string, favoriteId: string) {
    let delFavorites;
    try {
      delFavorites = (await serviceContainer.apiService.deleteFavorites(favoriteId)).data.data;
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message: 'Успешно удалено',
        severity: 'success',
      });
    } catch (error: any) {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    }
    await this.loadFavorites(userId);
    return delFavorites;
  }
}
