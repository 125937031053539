export interface StatusOptionType {
  label: string;
  value: string;
}

export const OPTIONS_STATUS_MOCK: StatusOptionType[] = [
  { 
    label: 'Ожидает отправки', 
    value: 'SEND_PENDING',
  },
  { 
    label: 'Отменено', 
    value: 'CANCELED',
  },
  { 
    label: 'Отправлено', 
    value: 'SENT',
  },
  { 
    label: 'Ошибка', 
    value: 'SEND_FAILED',
  },
  { 
    label: 'Черновик', 
    value: 'DRAFT',
  },
];

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 100,
    },
  },
};

export const timeAllHours = Array(24).fill(1).map((_, index) => {
  let res = Math.floor(index).toString();
  if (index < 10) {
    res = '0' + res;
  }
  return { slot: `${res}:00` };
});
