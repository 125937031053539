import { action, makeAutoObservable, toJS } from 'mobx';
import { TDepartmentTableInternal } from '../pages/AdministrationPage/DepartmentsTable/DepartmentsTable.type';

export class DepartmentsStore {
  private _departments: TDepartmentTableInternal[] = [];
  private _isLoaded: boolean = true;
  private _isRequestError = false;
  private _filter: string = '';
  private _isAddingDepartment = false;
  private _dropdownDepartment: TDepartmentTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _departmentInfoForEdit: TDepartmentTableInternal | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get departments() {
    return toJS(this._departments);
  }

  setDepartments(departments: TDepartmentTableInternal[]) {
    this._departments = departments;
  }

  get isLoaded() {
    return this._isLoaded;
  }

  set isLoaded(isLoaded) {
    this._isLoaded = isLoaded;
  }

  @action
  set isRequestError(value: boolean) {
    this._isRequestError = value;
  }

  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingDepartment() {
    return this._isAddingDepartment;
  }

  setIsAddingDepartment(value: boolean) {
    this._isAddingDepartment = value;
  }

  get dropdownDepartment() {
    return this._dropdownDepartment;
  }

  setDropdownDepartment(value: TDepartmentTableInternal) {
    this._dropdownDepartment = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get departmentInfoForEdit() {
    return this._departmentInfoForEdit;
  }

  setDepartmentInfoForEdit(value: TDepartmentTableInternal | null) {
    this._departmentInfoForEdit = value;
  }
}
