import { useState } from 'react';
import { Collapse, IconButton, Typography } from '@mui/material';
import { ErrorOutline, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { helpText } from '../mocks';

export const Questions = () => {
  const [open, setOpen] = useState(-1);
  
  return (
    <div className='help-container-questions'>
      <Typography className='title help-title'>
        Частые вопросы
      </Typography>
      <div className='help-questions-box'>
        {helpText.map((item, index) => {
          return (
            <div
              className='help-box'
              key={index}
            >
              <div
                className={`help-box-question-collapse ${open === index ? 'open' : ''}`}
              >
                <Typography
                  className={`help-box-question-text ${open === index ? 'blue' : ''}`}
                >
                  {item.question}
                </Typography>
                <IconButton
                  aria-label='expand help'
                  size='small'
                  className='help-box-arrow'
                  onClick={() => setOpen(open === index ? -1 : index)}
                >
                  {open === index ? (
                    <KeyboardArrowUp/>
                  ) : (
                    <KeyboardArrowDown/>
                  )}
                </IconButton>
              </div>
              <Collapse
                in={open === index}
                timeout='auto'
                unmountOnExit
              >
                <div
                  className='help-box-answer'
                >
                  {item.answer}
                </div>
              </Collapse>
            </div>
          );
        })}
      </div>
      <div className='help-descr-box'>
        <ErrorOutline/>
        <Typography className='help-descr-text'>Не нашли ответ на свой вопрос — напишите нам</Typography>
      </div>
    </div>
  );
};