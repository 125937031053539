export const generateColor = (key?: string): string => {
  const standard = '#808080';
  const colors: { [key: string]: string } = {
    MOW: '#f83021',
    VOR: '#0aaf52',
    LEN: '#f83021',
    KDA: '#be3455',
    YAR: '#168ff7',
    VGG: '#0aaf52',
    NVS: '#ee7802',
    AST: '#9319c4',
    NIZ: '#ee7802',
    BEL: '#ffbc15',
    KRS: '#168ff7',
    TGR: '#0aaf52',
    SVE: '#9319c4',
    MSQ: '#ffbc15',
    ALA: '#9319c4',
  };
  return key ? colors[key] : standard;
};
