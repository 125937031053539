import { serviceContainer } from '../ServiceContainer';
import { toInternalData } from './helpers';
import { IDepartment } from '../../types/otherTypes';

export class DepartmentsService {
  async loadDepartments() {
    await serviceContainer.apiService.getDepartments().then((res) => {
      if (res) {
        serviceContainer.departmentsStore.setDepartments(toInternalData(res.data.data));
        serviceContainer.departmentsStore.isLoaded = false;
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
  }

  async addDepartment(body: IDepartment) {
    await serviceContainer.apiService.createDepartment(body).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно создан блок ${body.departmentId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.departmentsService.loadDepartments();
  }

  async deleteDepartment(departmentId: string) {
    await serviceContainer.apiService.deleteDepartment(departmentId).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно удален ${departmentId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.departmentsService.loadDepartments();
  }

  async updateDepartment(body: IDepartment) {
    await serviceContainer.apiService.updateDepartment(body.departmentId, body).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно обновлен блок ${body.departmentId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.departmentsService.loadDepartments();
  }
}
