import { Suspense } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { observer } from 'mobx-react';
import { Redirect, Switch } from 'react-router-dom';
import ruLocale from 'date-fns/locale/ru';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { appRoutes, routes } from 'routes';
import { serviceContainer } from 'services';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AppBar, ConfirmationDialog, PageLoader, TransitionsSnackbar } from 'UI/components';
import Image from 'assets/svg/bgr3.0.svg';

import './App.scss';

const App = observer(() => {
  const token = serviceContainer.loginStore.token;
  const initConfig = { onLoad: 'login-required' };

  return (
    <ReactKeycloakProvider
      authClient={serviceContainer.keycloakService.keycloak}
      initOptions={initConfig}
      onTokens={(keycloakToken) => {
        if (keycloakToken.token) {
          serviceContainer.apiService.updateInstance(keycloakToken.token);
        }
      }}
    >{token &&
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ruLocale}
      >
        <CssBaseline />
        <Box
          sx={{ background: `url(${Image}) center no-repeat #d1d2d3` }}
          className="app_background"
        />
        <AppBar />
        <Suspense fallback={<PageLoader/>}>
          <Switch>
            {appRoutes}
            <Redirect to={routes.ERROR_404.path} />
          </Switch>
        </Suspense>
        <ConfirmationDialog />
        <TransitionsSnackbar />
      </LocalizationProvider>}
    </ReactKeycloakProvider>
  );
});

export default App;
