import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  IconButton,
  Input,
} from '@mui/material';
import {
  DoneRounded,
  BlockRounded,
  LockRounded,
} from '@mui/icons-material';
import { serviceContainer } from 'services';
import {
  INT_STEP,
  LOCKER_MIN,
  LOCKER_MAX,
} from 'utils/editorHelpers';
import { ColorProvider } from 'UI/components';

import s from './LockerControlPanel.module.scss';

export const LockerControlPanel = observer(({
  setAccessModalOpen,
}: {
  setAccessModalOpen: (value: boolean) => void,
}) => {
  
  const { roomId } = useParams<{ roomId: string, officeId: string }>();

  return (
    <div className={s.locker_control_panel}>
      <div className={s.buttons}>
        <ColorProvider color='#0aaf52'>
          <IconButton
            color='primary'
            onClick={()=>serviceContainer.roomStore.saveLockerChanges(roomId)}
            title='Сохранить изменения'
          >
            <DoneRounded width={12} />
          </IconButton>
        </ColorProvider>
        <ColorProvider color='#f83021'>
          <IconButton
            color='primary'
            onClick={() => serviceContainer.roomStore.exitEditMode(() => { }, true)}
            title='Отменить изменения'
          >
            <BlockRounded width={12} />
          </IconButton>
        </ColorProvider>
      </div>
      <ColorProvider color='#168ff7'>
        <IconButton
          color='primary'
          onClick={() => setAccessModalOpen(true)}
          disabled={!serviceContainer.roomStore.selected}
          title='Редактировать доступ на бронирование места'
        >
          <LockRounded />
        </IconButton>
      </ColorProvider>
      <div className={s.inputs}>
        <Input
          value={serviceContainer.roomStore.columns}
          size="small"
          onChange={(e)=>serviceContainer.roomStore.setColumns(+e.target.value)}
          inputProps={{
            step: INT_STEP,
            min: LOCKER_MIN,
            max: LOCKER_MAX,
            type: 'number',
          }}
        />
        <Input
          value={serviceContainer.roomStore.rows}
          size="small"
          onChange={(e)=>serviceContainer.roomStore.setRows(+e.target.value)}
          inputProps={{
            step: INT_STEP,
            min: LOCKER_MIN,
            max: LOCKER_MAX,
            type: 'number',
          }}
        />
      </div>
    </div>
  );
});