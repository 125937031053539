import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import flexOfficeLogo from '../../../assets/img/logo/flex_office_logo_green.png';

import './Tooltip.scss';

type TooltipLightStyledProps = {
  type?: 'iconLogo' | 'favoritesIcon' | 'iconHelp' | 'informationIcon' | 'iconDistribution',
  logo?: string,
  title?: ReactElement | string,
  children?: ReactElement,
  tooltipBgColor?: 'grey',
};

const DEFAULT_TEXT = 'Доступно для бронирования не более чем на 3 дня';

const TooltipLightComponent = ({
  type,
  title,
  children,
  tooltipBgColor,
  ...props
}: TooltipLightStyledProps) => {
  // todo подумать как переделать
  const classes = useMemo(() => ({
    tooltip: `tooltip-${(type === 'iconLogo' || tooltipBgColor)
      ? 'grey'
      : (type === 'iconHelp' || type === 'favoritesIcon' ? 'dark-grey' : `white ${type === 'informationIcon' ? 'shadow' : ''}`)}`,
    arrow: `arrow-${(type === 'iconLogo' || tooltipBgColor)
      ? 'grey'
      : (['iconHelp', 'favoritesIcon'].includes(type || '') ? 'dark-grey' : 'white')}`,
  }), [type, tooltipBgColor]);
  
  return (
    <Tooltip
      arrow
      {...props}
      title={type === 'iconLogo' ? <>{DEFAULT_TEXT}</> : title as ReactElement}
      classes={classes}
    >
      {type === 'iconLogo' ? (
        <img
          src={flexOfficeLogo}
          alt='Logo'
          className='tooltip-logo'
        />
      ) : children as ReactElement}
    </Tooltip>
  );
};

export const TooltipLight = styled(TooltipLightComponent)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
