import { serviceContainer } from '../index';
import { isEmpty } from 'lodash';
import { User, IUsersPaginationParams } from 'types/otherTypes';

export class UsersService {

  async loadAllUsersForse() {
    const users = (await serviceContainer.apiService.getAllUsers()).data.data;
    serviceContainer.usersStore.users = users;
    serviceContainer.usersStore.isCurrentUserAdmin = users.length > 1;
  }

  async loadAllUsers() {
    if (serviceContainer.loginStore.token && isEmpty(serviceContainer.usersStore.users)) {
      const users = (await serviceContainer.apiService.getAllUsers()).data.data;
      serviceContainer.usersStore.users = users;
      serviceContainer.usersStore.isCurrentUserAdmin = users.length > 1;
    }
  }

  async loadUsersByPagination(paginationParams: IUsersPaginationParams) {
    return (await serviceContainer.apiService.getAllUsers(paginationParams)).data;
  }

  async loadCurrentUser() {
    serviceContainer.usersStore.currentUser =
      (await serviceContainer.apiService.getCurrentUser()).data.data;
  }

  async putPassword(body: { password: string }) {
    if (body) {
      const user = await serviceContainer.apiService.updatePassword(body).then(res => res);
      serviceContainer.usersStore.updateUser = user.data.data;
      return user;
    }
  }

  async updateUserDetails(user: User) {
    const data = await serviceContainer.apiService.updateUserEmail({ ...user }).then(res => res);
    return data;
  }

  async getBlobImageByUrl(filename: string) {
    const response = (await serviceContainer.apiService.getUserImageById(filename));
    return new Blob([response.data], { type: 'image/png' });
  }
}
