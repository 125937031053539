import { Marker as MarkerMap } from 'react-simple-maps';
import { generateColor } from '../../../utils/colorGenerator';

export const Marker = ({ iso, coords, title, color, isActive, clickHandler }: TMarkerProps) => {
  return (
    <MarkerMap
      className={`marker${isActive ? '' : ' disabled'}`}
      key={`${iso}_${title}`}
      coordinates={coords}
      onClick={isActive ? () => clickHandler(iso) : undefined}
    >
      <circle
        className="circle"
        fill={isActive ? color : generateColor()}
        r={5}
      />
      <text
        className="text"
        y={-10}
        x={(() => {
          switch (iso) {
            case 'BEL':
              return -50;
            case 'VOR':
              return -14;
            default:
              return -18;
          }
        })()}
        style={{
          opacity: isActive ? 1 : 0.7,
        }}
      >
        {title}
      </text>
    </MarkerMap>
  );
};

export type TMarkerProps = {
  iso: string;
  coords: [number, number];
  title: string;
  color: string;
  isActive: boolean;
  clickHandler: (iso: string) => void;
};
