import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { UploadFileRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Workspace, ControlPanel } from 'UI/components';
import { serviceContainer } from 'services';
import { Status } from 'types/commonTypes';

import './Office.scss';

export const Office = observer(() => {

  const { officeId, roomId } = useParams<{ roomId: string, officeId: string }>();
  const backgoundRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    serviceContainer.roomStore.reset();
    serviceContainer.roomService.loadBackgroundImage(officeId, roomId);
  }, [roomId]);

  useEffect(() => {
    const workspaces = serviceContainer.workspacesStore.getWorkspacesByKey(roomId);
    if (!workspaces) return;
    const draggables = workspaces
      .map(({
        attributes: {
          x,
          y,
          rotate,
          wsize,
          activeReservation,
        },
        workspace_number,
        type,
        status,
        division_acl,
        user_acl,
        note,
      }) => ({
        number: workspace_number,
        isFlexible: type === 'desktop_fo',
        isDesktopNa: type === 'desktop_na',
        isDesktop: type === 'desktop',
        position: { x:+x, y:+y },
        rotation: +rotate,
        width: +wsize,
        isChanged: false,
        isNew: false,
        status: status as Status,
        reservator: activeReservation?.data?.attributes?.fname?.userName,
        division_acl,
        user_acl,
        reservatorIcon: activeReservation?.data?.attributes?.fname?.img,
        note,
      }))
      .sort((a, b) => a.number - b.number);
    serviceContainer.roomStore.setDraggables(draggables);
    serviceContainer.roomStore.setInitDraggables(draggables);
    if (workspaces.length) {
      serviceContainer.roomStore.setScale(+workspaces[0].attributes?.scale || 1);
      serviceContainer.roomStore.setInitScale(+workspaces[0].attributes?.scale || 1);
    }
  }, [roomId, serviceContainer.workspacesStore.workspaces]);

  const updateSize = () => {
    if (!backgoundRef.current) return;
    const naturalWidth = serviceContainer.roomStore.initBackgroundSize.width;
    const naturalHeight = serviceContainer.roomStore.initBackgroundSize.height;

    const isWiderThanLonger = naturalWidth > naturalHeight;

    const ratio = isWiderThanLonger ?
      naturalWidth / naturalHeight :
      naturalHeight / naturalWidth;

    const side = {
      one: isWiderThanLonger ? backgoundRef.current.clientWidth : backgoundRef.current.clientHeight,
      two: isWiderThanLonger ? backgoundRef.current.clientHeight : backgoundRef.current.clientWidth,
    };

    const currentRatio = side.one / side.two;

    serviceContainer.roomStore.setScaleCoef((currentRatio > ratio ?
      side.two * ratio :
      side.one) / (isWiderThanLonger ? 1000 : 500));
    serviceContainer.roomStore.setBackgroundSize({
      width: side.one,
      height: side.two,
    });
  };

  useEffect(() => {
    updateSize();
  }, [serviceContainer.roomStore.initBackgroundSize]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className="office">
      {
        serviceContainer.roomStore.isBackgroundLoading ?
          <CircularProgress sx={{ margin: 'auto' }} /> :
          serviceContainer.roomStore.officeSvgUrl ?
            <>
              <div className="workspaces">
                <img
                  src={serviceContainer.roomStore.officeSvgUrl}
                  alt='room background'
                  draggable='false'
                  className='background'
                  ref={backgoundRef}
                  onLoad={updateSize}
                  onClick={() => {
                    serviceContainer.roomStore.updateDraggables();
                    serviceContainer.roomStore.setSelected(null);
                  }}
                />
                {serviceContainer.roomStore.draggables.map(({
                  number,
                  position,
                  rotation,
                  isFlexible,
                  isDesktopNa,
                  width,
                  status,
                  reservator,
                  reservatorIcon,
                  note,
                  division_acl,
                  user_acl,
                }) =>
                  <Workspace
                    key={number}
                    number={number}
                    position={position}
                    rotation={rotation}
                    isDesktopNa={!!isDesktopNa}
                    isFlexible={!!isFlexible}
                    isSelected={serviceContainer.roomStore.selected === number}
                    width={width || 1}
                    status={status}
                    reservator={reservator}
                    reservatorIcon={reservatorIcon}
                    note={note}
                    divisionAcl={division_acl}
                    userAcl={user_acl}
                  />,
                )}
              </div>
              <ControlPanel roomVariant='room' />
            </> :
            serviceContainer.editStore.isEdit ?
              <div className='upload-background'>
                <input
                  type='file'
                  onChange={(e)=>serviceContainer.roomStore.uploadBackground(e)}
                  accept="image/svg+xml"
                  value=""
                />
                <UploadFileRounded className='icon'/>
                <span>Перетащите или выберете <strong>svg</strong> офиса</span>
              </div> :
              <div className='no-data'>
                Нет данных
              </div>
      }
    </div>
  );
});
