import { action, makeAutoObservable, toJS } from 'mobx';

import { TSnackbarProps, TPremiseTableInternal } from '../pages/AdministrationPage/PremisesTable/PremisesTable.type';

export class PremisesStore {
  private _premisesTable: TPremiseTableInternal[] = [];
  private _premisesLoaded = false;
  private _isRequestError = false;
  private _filter: string = '';
  private _isAddingPremise = false;
  private _dropdownPremise: TPremiseTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _premiseInfoForEdit: TPremiseTableInternal | null = null;
  private _roomsTypeOption: string[] = [];
  private _snackbar: TSnackbarProps = {
    open: false,
    message: '',
    severity: 'error',
  };

  private _premisesLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get premisesLoaded(): boolean {
    return toJS(this._premisesLoaded);
  }

  setPremisesLoaded(value: boolean) {
    this._premisesLoaded = value;
  }

  get premisesTable() {
    return toJS(this._premisesTable);
  }

  setPremisesTable(data: TPremiseTableInternal[]) {
    this._premisesTable = data;
  }

  @action
  set isRequestError(value: boolean) {
    this._isRequestError = value;
  }


  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingPremise() {
    return this._isAddingPremise;
  }

  setIsAddingPremise(value: boolean) {
    this._isAddingPremise = value;
  }

  get dropdownPremise() {
    return this._dropdownPremise;
  }

  setDropdownPremise(value: TPremiseTableInternal) {
    this._dropdownPremise = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get snackbar() {
    return this._snackbar;
  }

  setSnackbar(value: TSnackbarProps) {
    this._snackbar = value;
  }

  get premiseInfoForEdit() {
    return this._premiseInfoForEdit;
  }

  setPremiseInfoForEdit(value: TPremiseTableInternal | null) {
    this._premiseInfoForEdit = value;
  }

  get roomsTypeOption() {
    return this._roomsTypeOption;
  }

  setRoomsTypeOption(value: string[]) {
    this._roomsTypeOption = value;
  }

  get premisesLoading() {
    return this._premisesLoading;
  }

  set premisesLoading(isLoading) {
    this._premisesLoading = isLoading;
  }
}
