import { serviceContainer } from './';
import { CreateReservation } from '../types/commonTypes';

export class ReservationsService {
  
  async loadReservations(workspaceId?: string, isCalendar?: boolean) {
    const data = (await serviceContainer.apiService.getReservations(workspaceId, isCalendar)).data;
    serviceContainer.reservationsStore.reservations = data.data;
    serviceContainer.reservationsStore.permission = data.permission;
  }
  
  async loadReservationsByWtype(wtype: string, search: string) {
    const type = wtype === 'desktop' ? `${wtype},desktop_fo` : wtype;
    const reservations = (await serviceContainer.apiService.getReservationsByWtype(type, search)).data.data;
    serviceContainer.reservationsStore.setReservationsByWtype(wtype, reservations);
  }
  
  async createReservation(isCalendar: boolean, body: CreateReservation) {    
    serviceContainer.reservationsStore.loading = true;
    await serviceContainer.apiService.createReservation(body).then(res => res).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await this.loadReservations(body.workspace_id, isCalendar);
    serviceContainer.reservationsStore.loading = false;

  }
  async updateReservation(isCalendar: boolean, body: CreateReservation, reservationId: string) {
    serviceContainer.reservationsStore.loading = true;
    await serviceContainer.apiService.updateReservation(body, reservationId).then(res => res).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await this.loadReservations(body.workspace_id, isCalendar);
    serviceContainer.reservationsStore.loading = false;
  }
  
  async deleteReservation(reservationId: string) {
    serviceContainer.reservationsStore.reservations =
      serviceContainer.reservationsStore.reservations?.filter(t => t.id !== reservationId);
    await serviceContainer.apiService.deleteReservation(reservationId).then(res => res).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
  }
}