import { serviceContainer } from '../ServiceContainer';
import { toInternalData } from './helpers';

export class RoomsService {

  async loadRooms(officeId: string, force?: boolean) {
    if (!force && Object.keys(serviceContainer.roomsStore.rooms).includes(officeId)) return;
    await serviceContainer.apiService.getRooms(officeId).then((r) => r?.data
      && serviceContainer.roomsStore.setRoomsById(officeId, r.data.data), ()=>{}).catch(() => {});
  }

  async loadAllRooms() {
    const r = await serviceContainer.apiService.getAllRooms();
    serviceContainer.roomsStore.roomsTable = toInternalData(r.data.data);
  }
}
