import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/styles';

import './BreadcrumbsSelect.scss';

const useStyles = makeStyles(() => ({
  selectStyles: {
    '& .MuiInput-root.Mui-focused': {
      '& .MuiSelect-select': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

interface IOption {
  name: string;
  path: string;
  type: string;
}

interface IBreadcrumbsSelectProps {
  initial: string;
  options: IOption[];
}

const BreadcrumbsSelect = (props: IBreadcrumbsSelectProps) => {
  const [open, setOpen] = useState(false);
  const { options, initial } = props;
  const location = useLocation();
  const classes = useStyles();
  const sortedOptions = Array.isArray(options) ?
    [...options].sort((a, b) => a.name.localeCompare(b.name)) :
    [];
  
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return options ? (
    <Box
      className={classes.selectStyles}
      component="form"
      noValidate
      autoComplete="off"
      onClick={() => setOpen(!open)}
    >
      <TextField
        id="select"
        select
        SelectProps={{
          open,
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: 12,
            fontWeight: 'bold',
            color: '#4e5155',
            paddingTop: 5,
          },
        }}
        value={initial}
        variant="standard"
      >
        {sortedOptions?.map((option: IOption) => (
          <MenuItem
            key={option.path}
            value={option.path}
            sx={{
              fontSize: 12,
              height: 30,
              padding: 0,
            }}
          >
            <Link
              to={option.path}
              className="breadcrumbs-select-link"
            >
              {option.name}
            </Link>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  ) : null;
};

export default BreadcrumbsSelect;
