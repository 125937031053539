import { Menu, MenuItem } from '@mui/material';

type OwnProps = {
  open: boolean,
  onBackdropClick: () => void,
  anchorEl: Element | null,
  handleAddTag: (v: string) => void,
};

const tagValues = [
  '#адрес ',
  '#кабинет ',
  '#место ',
  '#время ',
  '#интервал ',
];

export const TagMenu = ({ open, onBackdropClick, anchorEl, handleAddTag }: OwnProps) =>
  <Menu
    onBackdropClick={onBackdropClick}
    open={open}
    anchorEl={anchorEl}
  >
    {tagValues.map((tag) =>
      <MenuItem
        onClick={() => handleAddTag(tag)}
        key={tag}
      >
        {tag}
      </MenuItem>,
    )}
  </Menu>;
