import { serviceContainer } from 'services';
import { Reason } from 'types/otherTypes';

const fetchUsers = () =>
  serviceContainer.adminService.getUsersForse().then((res) =>
    serviceContainer.adminStore.setUsers(
      res.map((user) => ({
        userName: user.userName,
        userRole: user.userRole,
        userId: user.userId,
        division: user.division,
        email: user.email,
        disabled: user.disabled,
      })),
    ),
  );

export const showErrorSnackbar = (reason: Reason) => {
  serviceContainer.adminStore.setSnackbar({
    open: true,
    message: `${reason.response.data.statusCode}: ${reason.response.data.message}`,
    severity: 'error',
  });
};

export const addUser = async () => {
  if (serviceContainer.adminStore.userInfoForEdit)
    await serviceContainer.adminService
      .addUser(serviceContainer.adminStore.userInfoForEdit, serviceContainer.usersStore.paginationParams)
      .then(
        () => {
          fetchUsers();
          serviceContainer.adminStore.setSnackbar({
            open: true,
            message: `Пользователь ${serviceContainer.adminStore.userInfoForEdit?.userId} добавлен`,
            severity: 'success',
          });
          serviceContainer.adminStore.setIsAddingUser(false);
        },
        (reason) => showErrorSnackbar(reason),
      )
      .catch((reason) => showErrorSnackbar(reason));
};

export const editUser = async () => {
  if (serviceContainer.adminStore.userInfoForEdit)
    await serviceContainer.adminService
      .editUser(serviceContainer.adminStore.userInfoForEdit, serviceContainer.usersStore.paginationParams)
      .then(
        () => {
          fetchUsers();
          serviceContainer.adminStore.setSnackbar({
            open: true,
            message: `Данные пользователя ${serviceContainer.adminStore.userInfoForEdit?.userId} обновлены`,
            severity: 'success',
          });
        },
        (reason) => showErrorSnackbar(reason),
      )
      .catch((reason) => showErrorSnackbar(reason));
};

export const resetPassword = () => {
  if (serviceContainer.adminStore.userInfoForEdit?.email)
    serviceContainer.apiService
      .restorePassword(serviceContainer.adminStore.userInfoForEdit.email)
      .then(
        () => {
          serviceContainer.adminStore.setSnackbar({
            open: true,
            message: `Пароль у пользователя ${serviceContainer.adminStore.userInfoForEdit?.userId} сброшен`,
            severity: 'success',
          });
        },
        (reason) => showErrorSnackbar(reason),
      )
      .catch((reason) => showErrorSnackbar(reason));
};

export const updateUserData = async () => {
  if (serviceContainer.adminStore.userInfoForEdit?.userId) {
    try {
      const { data } = await serviceContainer.apiService
        .updateUserData(serviceContainer.adminStore.userInfoForEdit.userId);
      const { paginationParams } = serviceContainer.usersStore;
      if (paginationParams) {
        await serviceContainer.usersService.loadUsersByPagination(paginationParams)
          .then(res => serviceContainer.adminStore.setUsers(res.data));
      }
      serviceContainer.adminStore.setSnackbar({
        open: true,
        message: `${data}`,
        severity: 'success',
      });
    } catch (e) {
      showErrorSnackbar(e as Reason);
    }
  }
};

export const toggleAccess = () => {
  if (serviceContainer.adminStore.dropdownUser)
    serviceContainer.adminService
      .toggleAccess(serviceContainer.adminStore.dropdownUser)
      .then(
        () => {
          fetchUsers();
          serviceContainer.adminStore.setSnackbar({
            open: true,
            message: `Пользователь ${serviceContainer.adminStore.dropdownUser?.userId}
                  ${serviceContainer.adminStore.dropdownUser?.disabled ? 'разблокирован' : 'заблокирован'}`,
            severity: 'success',
          });
        },
        (reason) => showErrorSnackbar(reason),
      )
      .catch((reason) => showErrorSnackbar(reason));
};
