import Keycloak from 'keycloak-js';

export class KeycloakService {
  private _keycloak: Keycloak | null = null;

  get keycloak(): Keycloak {
    if (!this._keycloak) {
      this._keycloak = KeycloakService.createKeycloakInstance();
    }
    return this._keycloak;
  }

  private static createKeycloakInstance() {
    const initOptions = {
      url: 'https://sso.phoenixit.ru/auth/',
      realm: 'league-sso',
      clientId: 'flexoffice',
      onLoad: 'login-required',
    };
    return new Keycloak(initOptions);
  }
}
