import { FavoritesField } from '../../../types/otherTypes';
import { IFavorites } from '../../../types/commonTypes';

type Order = 'asc' | 'desc';

export const descendingComparator = (a: IFavorites, b: IFavorites, orderBy: keyof FavoritesField) => {
  if (orderBy !== 'action') {
    if (b.attributes[orderBy] < a.attributes[orderBy]) {
      return -1;
    }
    if (b.attributes[orderBy] > a.attributes[orderBy]) {
      return 1;
    }
  }
  return 0;
};

export const getComparator = (order: Order, orderBy: keyof FavoritesField) => {
  return order === 'desc'
    ? (a: IFavorites, b: IFavorites) => descendingComparator(a, b, orderBy)
    : (a: IFavorites, b: IFavorites) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array: readonly IFavorites[], comparator: (a: IFavorites, b: IFavorites) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [IFavorites, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const sortOptions: { placeType: string, regionName: string, officeName: string, roomNumber: string, workspaceNumber: string }  = {
  placeType:'По типу',
  regionName:'По городу',
  officeName:'По офису',
  roomNumber:'По комнате',
  workspaceNumber:'По месту',
};