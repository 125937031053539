import { IWorkspace } from '../../types/commonTypes';
import { TWorkspaceTableExternal, TWorkspaceTableInternal } from '../../pages/AdministrationPage/WorkspacesTable/WorkspacesTable.type';

export const toInternalData = (data: IWorkspace[]): TWorkspaceTableInternal[] => {
  return data.map(item => {
    return {
      workspaceId: item.id,
      roomName: item.room.name,
      workspaceNumber: (item.workspace_number).toString(),
      workspaceType:  item.type,
    };
  });
};

export const toExternalData = (data: TWorkspaceTableInternal): TWorkspaceTableExternal => {
  return {
    workspaceId: data.workspaceId,
    roomName: data.workspaceId.slice(0, data.workspaceId.lastIndexOf('.')),
    workspaceNumber: data.workspaceNumber,
    workspaceType:  data.workspaceType,
  };
};
