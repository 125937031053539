import { Link, Typography } from '@mui/material';
import { PictureAsPdfOutlined } from '@mui/icons-material';

export const Instructions = () => (
  <div className='help-wrapper-instructions box'>
    <Typography className='title'>
      Инструкции
    </Typography>
    <div className='help-box-link'>
      <PictureAsPdfOutlined/>
      <Link
        className='help-button-download'
        href={`${process.env.REACT_APP_API_HOST}/instruction/workplace.pdf`}
        underline='hover'
        target='_blank'
      >
        Инструкция по бронированию рабочих мест
      </Link>
    </div>
    <div className='help-box-link'>
      <PictureAsPdfOutlined/>
      <Link
        className='help-button-download'
        href={`${process.env.REACT_APP_API_HOST}/instruction/lockers.pdf`}
        underline='hover'
        target='_blank'
      >
        Инструкция по бронированию локеров
      </Link>
    </div>
  </div>
);