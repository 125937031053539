import { serviceContainer } from '../ServiceContainer';
import { toInternalData, toInternalOption } from './helpers';
import { IDivision } from '../../types/otherTypes';

export class DivisionsService {
  async loadDivisions() {
    await serviceContainer.apiService.getDivisions().then((res) => {
      if (res) {
        serviceContainer.divisionsStore.setDivisions(toInternalData(res.data.data));
        serviceContainer.divisionsStore.setOption(toInternalOption(res.data.data));
        serviceContainer.divisionsStore.isLoaded = false;
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
  }

  async addDivision(body: IDivision) {
    await serviceContainer.apiService.createDivision(body).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно создан блок ${body.departmentId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.divisionsService.loadDivisions();
  }

  async deleteDivision(divisionId: string) {
    await serviceContainer.apiService.deleteDivision(divisionId).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно удален ${divisionId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.divisionsService.loadDivisions();
  }

  async updateDivision(body: IDivision) {
    await serviceContainer.apiService.updateDivision(body.divisionId, body).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно обновлен блок ${body.departmentId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.divisionsService.loadDivisions();
  }
}
