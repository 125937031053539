import { makeAutoObservable } from 'mobx';

class ImageStore {
  images = new Map<string, string>();
  
  constructor() {
    makeAutoObservable(this);
  }
}

export const imageStore = new ImageStore();