import { TRegionTableInternal } from 'pages/AdministrationPage/RegionsTable/RegionsTable.type';
import { IRegion, RegionDto } from '../../types/commonTypes';

export const toInternalData = (data: RegionDto[]): TRegionTableInternal[] => {
  return data.map(item => {
    return {
      regionId: item.id,
      regionName: item.name,
      coordinates: item.coordinates,
      disabled: !item.active,
    };
  });
};

export const toInternalRegion = (data: TRegionTableInternal): IRegion => {
  return {
    regionId: data.regionId,
    regionName: data.regionName,
    active: !data.disabled,
    coordinates: data.coordinates,
  };
};
