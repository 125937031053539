import { ReactNode } from 'react';

import s from './Kbd.module.scss';

export const Kbd = ({ children }:{ children:ReactNode }) =>
  <div className={s.kbd}>
    <div>
      {children}
    </div>
    <div>
      {children}
    </div>
  </div>;