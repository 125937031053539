import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';
import {
  ErrorOutline,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { serviceContainer } from 'services';
import { LightTooltip } from 'UI/components';

import './PasswordChanges.scss';

export type FormValues = {
  newPasswordValue: string;
  confirmPasswordValue: string;
};

type PasswordChangesProps = {
  setFormFields: (v: FormikProps<FormValues>) => void;
  setOpen: (v: boolean) => void;
  setLoadingSave: (v: boolean) => void;
  setAlertMessage: (v: string) => void;
  setIsSuccess: (v: boolean) => void;
  setOpenSnackbar: (v: boolean) => void;
};

type NewPassword = {
  showPassword: boolean;
  newPasswordValue?: string;
};
type ConfirmPassword = {
  showPassword: boolean;
  confirmPasswordValue?: string;
};

const validationSchema = yup.object({
  newPasswordValue: yup
    .string()
    .trim()
    .required('Это обязательное поле')
    .matches(/[a-zA-Z]/, 'Пароль должен содержать хотя бы одну латинскую букву')
    .min(6, 'Пароль должен содержать не менее 6 символов включая хотя бы одну латинскую букву'),
  confirmPasswordValue: yup.string().when('newPasswordValue', {
    is: (val: string) => (val && val.length >= 6),
    then: yup.string().trim().oneOf(
      [yup.ref('newPasswordValue')],
      'Пароли не совпадают. Повторите попытку',
    ),
  }),
});

const PasswordChanges = observer(({
  setOpen = () => {},
  setLoadingSave = () => {},
  setAlertMessage = () => {},
  setIsSuccess = () => {},
  setOpenSnackbar = () => {},
  setFormFields,
}: PasswordChangesProps) => {
  const [newPassword, setNewPassword] = useState<NewPassword>({
    showPassword: false,
    newPasswordValue: '',
  });
  const [confirmPassword, setConfirmPassword] = useState<ConfirmPassword>({
    showPassword: false,
    confirmPasswordValue: '',
  });

  const formik = useFormik({
    initialValues: {
      newPasswordValue: '',
      confirmPasswordValue: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const body = {
        password: values.newPasswordValue,
      };
      if (body.password) {
        setLoadingSave(true);
        await serviceContainer.usersService.putPassword(body).catch(e => {
          const status = e.response.status;
          const message = e.response.data.message;
          if (status) { setAlertMessage(`${status}: ${message}`); }
          setIsSuccess(false);
        }).then((r) => {
          if (r?.status === 200) {
            setOpenSnackbar(true);
            setAlertMessage('Пароль успешно изменен!');
            formik.values.newPasswordValue = '';
            formik.values.confirmPasswordValue = '';
          }
        });
      }
      setLoadingSave(false);
      setOpen(false);
    },
  });
  
  useEffect(() => {
    if (formik.values.newPasswordValue && formik.values.confirmPasswordValue) {
      setFormFields(formik);
    }
  }, [formik, setFormFields]);

  const handleChange = (event: SyntheticEvent) => formik.handleChange(event);

  const handleClickShowPassword = (setValue: (value: NewPassword | ConfirmPassword) => void, value: NewPassword | ConfirmPassword) => {
    setValue({
      ...value,
      showPassword: !value.showPassword,
    });
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => event.preventDefault();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className="password-changes">
        <TextField
          autoComplete="on"
          className="password-changes-input"
          fullWidth
          id="newPasswordValue"
          name="newPasswordValue"
          label="Новый пароль"
          variant="filled"
          type={newPassword.showPassword ? 'text' : 'password'}
          value={formik.values.newPasswordValue.trim()}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword(setNewPassword, newPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {newPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LightTooltip
          title={!formik.values.newPasswordValue ? 'Сначала заполните новый пароль' : ''}
          arrow
        >
          <TextField
            autoComplete="on"
            className="password-changes-input"
            fullWidth
            id="confirmPasswordValue"
            name="confirmPasswordValue"
            label="Подтвердите пароль"
            variant="filled"
            type={confirmPassword.showPassword ? 'text' : 'password'}
            value={formik.values.confirmPasswordValue.trim()}
            disabled={!formik.values.newPasswordValue}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword(setConfirmPassword, confirmPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disabled={!formik.values.newPasswordValue}
                  >
                    {confirmPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </LightTooltip>
        <Typography
          component="span"
          style={{
            opacity: formik.errors.confirmPasswordValue || formik.errors.newPasswordValue ? 1 : 0,
            display: 'flex',
            alignItems: 'center',
            color: '#F83021',
          }}
        >
          <ErrorOutline sx={{ marginRight: '14px' }}/>
          {formik.errors.confirmPasswordValue || formik.errors.newPasswordValue}
        </Typography>
      </Box>
    </form>
  );
});

export default PasswordChanges;
