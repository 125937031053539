import { Button } from '@mui/material';
import { AttachFile, ErrorOutline, Add } from '@mui/icons-material';
import { TooltipLight } from 'UI/components/Tooltip/Tooltip';
import { serviceContainer } from 'services';

type OwnProps = {
  setAttachedFile: (file: File[]) => void,
  isDisabled: boolean,
  attachedFile: File[] | undefined,
};

const TooltipText = () => (
  <div className="help-tooltip-text">
    Суммарный размер файлов 20 MB
  </div>
);

const AttachButtonDistribution = ({ setAttachedFile, isDisabled, attachedFile }: OwnProps) => {
  const hasAttachedFiles = attachedFile && attachedFile.length > 0;

  const loadFile = (e: any) => {
    let array: File[] = attachedFile ? [...attachedFile] : [];
    e.preventDefault();
    let attachedFileSize = 0;
    Object.values(e.currentTarget.files).forEach((item: any) => {
      attachedFileSize += item.size;
    });

    for (const file of e.currentTarget.files) {
      if (attachedFileSize > 2e+7) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: 'Суммарный размер файлов не должен превышать 20 MB',
          severity: 'error',
        });
        return;
      }

      array = [...array, file];
    }
    
    setAttachedFile(array);
  };
  
  return (
    <>
      {hasAttachedFiles ? (
        <TooltipLight
          type='iconHelp'
          title="Прикрепить файлы"
        >
          <Button
            className='button-icon'
            startIcon={<Add />}
            variant="contained" 
            component="label"
            disabled={isDisabled}
          >
            <input
              hidden
              type="file"
              onChange={(e) => loadFile(e)}
              value=""
              multiple
            />
          </Button>
        </TooltipLight>
      ) : (
        <> 
          <Button
            className='button'
            variant='outlined'
            startIcon={<AttachFile />}
            component="label"
            disabled={isDisabled}
          >
            Прикрепить файлы
            <input
              hidden
              type="file"
              onChange={(e) => loadFile(e)}
              value=""
              multiple
            />
          </Button>
          <TooltipLight
            type='iconHelp'
            title={<TooltipText/>}
          >
            <ErrorOutline className='tooltip-icon' />
          </TooltipLight>
        </>
      )}
    </>
  );
};

export default AttachButtonDistribution;