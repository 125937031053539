import {
  add,
  differenceInMinutes,
  eachDayOfInterval,
  endOfDay,
  format,
  getHours,
  getMinutes,
  getTime,
  isAfter,
  isToday,
  isWithinInterval,
  max,
  startOfDay, startOfMonth,
  sub,
} from 'date-fns';
import {
  DatesString,
  IntervalType,
  Reservation,
  ReservationAttributes,
  ReservationCreate,
} from '../../../../types/commonTypes';
import { compact, difference, differenceBy, findIndex, isEqual, sortBy, uniq, uniqBy } from 'lodash';

export type ItemReserv = {
  attributes: {
    dateEnd: string;
    dateStart: string;
    isFullDay: boolean;
    isPartTime: boolean;
    isPermanent: boolean;
  }
  start?: number;
};
export type ItemReservFull = { start?: number } & Reservation<DatesString>;

export type DisableDate = {
  dateEnd: string;
  dateStart: string;
  isPartTime?: boolean;
  isPermanent?: boolean;
  isFullDay?: boolean;
  start: number;
  isDirtyDay?: boolean;
};

export const timeSlots = Array(48).fill(1).map((_, index) => {
  let res = Math.floor(index / 2).toString();
  const isEven = index % 2 === 0;
  if (index < 20) {
    res = '0' + res;
  }
  return { slot: isEven ? `${res}:00` : `${res}:30` };
});

// 07:00-22:00 - период
export const rangeSlots = timeSlots.slice(14, timeSlots.length - 3);
export const rangeSlotsStart = timeSlots.slice(14, timeSlots.length - 4);
export const rangeSlotsEnd = timeSlots.slice(15, timeSlots.length - 3);

export const getOnlyDate = (date: Date) => format(date, 'yyyy-MM-dd');

export const getTimeSlot = (date: Date) => format(date, 'HH:mm');

export const getDateAndTimeSlot = (date: Date) => format(date, 'yyyy.MM.dd HH:mm');

export const getSlotByDateString = (dateString: string, type?: 'add') => type
  ? format(add(new Date(dateString.split('.')[0]), { minutes: 1 }), 'HH:mm')
  : format(new Date(dateString.split('.')[0]), 'HH:mm');


// для формата, пример, 2022-04-13T16:59:00.000Z
export const transformDateString = (dateString: string, type?: 'sub' | 'add') => type
  ? sub(new Date(dateString.split('.')[0]), { minutes: 1 })
  : new Date(dateString.split('.')[0]);

export const getDateWithoutDote = (dateString: string)  => {
  return dateString.split('.')[0];
};

export const getDateWithoutT = (dateString: string)  => {
  return dateString.split('T')[0];
};

export const getNumber = (slot: string) => Number(slot.replace(':', '.'));

export const getDateByStringDateAndSlot = (date: string, slot: string, type?: 'end') => type
  ? sub(new Date(`${date}T${slot}:00Z`), { minutes: 1 })
  : new Date(`${date}T${slot}:00Z`);

export const getStartIntervalByToday = () => {
  // поиск свободного интервала с учетом бронирования в прошлое на 30 минут и зависимостью от текущего часа
  const hoursString = getHours(new Date()).toString().length > 1
    ? getHours(new Date()).toString()
    : `0${getHours(new Date()).toString()}`;
  const minutesString = getMinutes(new Date()) > 30 ? '30' : '00';
  // нашли доступный интервал зависимый от сегодня
  return `${hoursString}:${minutesString}`;
};

// получаем брони на сегодня по типу бронирования
const getTodayReservationsByTypePartTime = (reservations: Reservation<DatesString>[]) => {
  const isPartTime = reservations.find(it => it.attributes.isPartTime && isToday(new Date(it.attributes.dateStart.split('.')[0])));
  const result = reservations.filter(it => it.attributes.isPartTime && isToday(new Date(it.attributes.dateStart.split('.')[0])));
  return isPartTime ? result : undefined;
};

// получаем актуальные брони на сегодня по бронированию по часам
const getCurrentBusyDate = (isPartTimeTodayData: Reservation<DatesString>[]) => {
  const startFreeSlot = getStartIntervalByToday();
  const indexIntervalEnd = timeSlots.findIndex(ts => ts.slot === startFreeSlot);
  const timeEnd = startFreeSlot === '00:00'
    ? format(sub(new Date(`${getOnlyDate(new Date())}T${timeSlots[1].slot}`), {  minutes: 1 }), 'HH:mm')
    : format(sub(new Date(`${getOnlyDate(new Date())}T${timeSlots[indexIntervalEnd].slot}`), { minutes: 1 }), 'HH:mm');

  // преобразовываем дату к дате с бэка
  const formattedDateStart = `${getOnlyDate(new Date())}T00:00:00.000Z`;
  const formattedDateEnd = `${getOnlyDate(new Date())}T${timeEnd}:00.000Z`;
  const today: ReservationCreate<DatesString> = {
    attributes: {
      dateStart: formattedDateStart,
      dateEnd: formattedDateEnd,
      isFullDay: false,
      isPartTime: true,
      isPermanent: false,
    },
  };

  if (!isPartTimeTodayData) { return undefined; }
  if (isPartTimeTodayData.length >= 0) {
    const maxDates: Date[] = [];
    isPartTimeTodayData.forEach(it => {
      if (today.attributes.dateEnd && it.attributes.dateEnd) {
        const dates = [transformDateString(today.attributes.dateEnd, 'sub'), transformDateString(it.attributes.dateEnd)];
        const maxDate = max(dates);
        maxDates.push(maxDate);
      }
    });
    // получаем даты окончания с временем, берем первую - получаем дату окончания первого актуального интервала
    const dis = uniq(maxDates.map(it => format(it, 'HH:mm')));
    const checkDate = dis.includes(timeEnd) ? dis : [timeEnd];
    const formattedStart = `${getOnlyDate(new Date())}T00:00:00.000Z`;
    const formattedEnd = `${getOnlyDate(new Date())}T${checkDate[0]}:00.000Z`;
    const firstInterval = {
      attributes: {
        dateStart: formattedStart,
        dateEnd: formattedEnd,
        isFullDay: false,
        isPartTime: true,
        isPermanent: false,
      },
    };
    const getNextInterval = isPartTimeTodayData.filter(it => {
      const itDateEnd = format(transformDateString(it.attributes.dateEnd as string), 'HH.mm');
      const firstIntervalDateEnd = format(transformDateString(firstInterval.attributes.dateEnd), 'HH.mm');
      return itDateEnd > firstIntervalDateEnd;
    });
    return [firstInterval, ...getNextInterval];
  }
};

export const createReservsByWorkspaceId = (reservations: Reservation<DatesString> [] | []) => {
  if (!reservations.length) return [];
  const isDirtyDay = reservations?.filter((reserv) => reserv.attributes.dateStart.split('T')[0] === getOnlyDate(new Date()) && !!reserv.id);

  const todayReservations = reservations?.find((reserv) => reserv.attributes.dateStart.split('T')[0] === getOnlyDate(new Date()) && !!reserv.id);
  const isPartTimeTodayData = sortBy(getTodayReservationsByTypePartTime(reservations), 'attributes.dateStart');

  const updateTodayBusyDate = getCurrentBusyDate(isPartTimeTodayData as Reservation<DatesString>[])?.map(it => ({
    ...it,
    start: getTime(new Date(it.attributes.dateStart.split('.')[0])),
    isDirtyDay: isDirtyDay.length > 0,
  })) as (Reservation<DatesString> & { start: number, isDirtyDay?: boolean })[];
  if (todayReservations?.attributes.isFullDay || todayReservations?.attributes.isPermanent) {
    const datesWithoutTodayByPartTime = reservations.map(it => ({
      ...it,
      start: getTime(new Date(it.attributes.dateStart.split('.')[0])),
      isDirtyDay: true,
    }));
    return datesWithoutTodayByPartTime;
  } else {
    const datesWithoutTodayByPartTime = reservations
      .filter(it => !isToday(new Date(it.attributes.dateStart.split('.')[0])))
      .map(it => ({
        ...it,
        start: getTime(new Date(it.attributes.dateStart.split('.')[0])),
        isDirtyDay: true,
      }));
    return sortBy([...updateTodayBusyDate, ...datesWithoutTodayByPartTime], 'start');
  }
};

export const getAllBusyDates = (reservationsByWorkspaceId?: (
  Reservation<DatesString> & { start: number, isDirtyDay?: boolean })[],
) => {
  const result: DisableDate[] = [];
  const resultFullDaysByPartTime: DisableDate[] = [];
  // todo подумать сколько храним брони
  // const filterReservations = reservationsByWorkspaceId?.filter((item) => item.start >= sub(startOfMonth(new Date()), { months: 1 }).getTime());
  const filterReservations = reservationsByWorkspaceId;
  if (filterReservations && filterReservations.length > 0) {
    const partTimeOnlyDatesString = uniq(filterReservations
      .filter((it) => it.attributes.isPartTime)
      .map((it) =>  it?.attributes?.dateStart?.split('T')[0]));

    const otherDates = filterReservations.filter((it) => !it.attributes.isPartTime);

    otherDates.forEach((od) => {
      const start = od.attributes.dateStart;
      const end = od.attributes.dateEnd ? od.attributes.dateEnd : start;
      result.push({
        dateStart: start,
        dateEnd: end,
        isPermanent: od.attributes.isPermanent,
        isFullDay: od.attributes.isFullDay,
        isPartTime: od.attributes.isPartTime,
        start: od.start,
        isDirtyDay: od.isDirtyDay,
      });
    });

    //Из броней по часам проверяем есть ли дни забронированные полностью
    partTimeOnlyDatesString.forEach((ds) => {
      const filteredDates = filterReservations?.filter((reserv) =>
        reserv.attributes.isPartTime && ds === reserv.attributes.dateStart.split('T')[0]);
      const firstResInDay = filteredDates
        .filter((item) => item.id)
        .sort((a, b) => a.start - b.start)[0];
      // день может быть занят на 15 часов (15 * 60 - min), если в дне занято 15 часов - отключаем день
      if (filteredDates.length > 0) {
        const res = filteredDates.map((fd) => {
          const isStartOfDay = format(new Date(fd.attributes.dateStart.split('.')[0]), 'HH:mm') === '00:00';
          const start = isStartOfDay
          // так добавлено был в reservationsByWorkspaceId текущий день,
            // то проверяем на совпадение с 00:00 и подставляем актуальный интервал - 07:00:00
            ? new Date(`${getOnlyDate(new Date(fd.attributes.dateStart.split('.')[0]))}:07:00:00`)
            : new Date(fd.attributes.dateStart.split('.')[0]);
          const end = isStartOfDay && firstResInDay
            ? new Date((firstResInDay.attributes.dateStart as string)?.split('.')[0])
            : add(new Date((fd.attributes.dateEnd as string)?.split('.')[0]), { minutes: 1 });

          const busyHours = differenceInMinutes(end, start);
          return {
            date: fd,
            busyHours,
          };
        });
        const initValue = 0;
        const sumBusyHours = res.reduce((prevValue: number, currentValue: { busyHours: number; }) => prevValue + currentValue.busyHours, initValue);
        const value = 15 * 60; // количество всего минут доступных на день
        if (sumBusyHours >= value) {
          resultFullDaysByPartTime.push({
            dateStart: `${ds}T00:00:00.000Z`,
            dateEnd: `${ds}T23:59:00.000Z`,
            isPermanent: false,
            isFullDay: true,
            isPartTime: false,
            isDirtyDay: true,
            start: new Date(`${ds}T00:00:00`).getTime(),
          });
        }
      }
    });

    const resultFullDaysByPartTimeDateString = resultFullDaysByPartTime.map(it => getDateWithoutT(it.dateStart));
    const partTimeOnlyDatesStringWithoutFullDay = difference(partTimeOnlyDatesString, resultFullDaysByPartTimeDateString);

    partTimeOnlyDatesStringWithoutFullDay.forEach((it) => {
      const filteredDates = filterReservations?.find((reserv) =>
        reserv.attributes.isPartTime && it === reserv.attributes.dateStart.split('T')[0]);
      if (filteredDates) {
        const start = filteredDates.attributes.dateStart;
        const end = filteredDates.attributes.dateEnd ? filteredDates.attributes.dateEnd : start;
        result.push({
          dateStart: start,
          dateEnd: end,
          isPermanent: filteredDates.attributes.isPermanent,
          isFullDay: filteredDates.attributes.isFullDay,
          isPartTime: filteredDates.attributes.isPartTime,
          start: filteredDates.start,
          isDirtyDay: filteredDates.isDirtyDay,
        });
      }
    });
    // для isPartTime достаточно первой брони
    return sortBy([...result, ...resultFullDaysByPartTime], 'start');
  }
  return [];
};

export const isDisabledDate = (disabledDates: DisableDate[] | undefined, date: Date | null, type?: 'withPartTime') => {
  const disDate = disabledDates?.find(fdd => {
    if (fdd.isPartTime && type && fdd.isDirtyDay && date) {
      return date.getTime() ===  startOfDay(new Date(getDateWithoutDote(fdd.dateStart))).getTime();
    }
    if (fdd.isFullDay && fdd.dateEnd && date) {
      return isWithinInterval(date, {
        start: startOfDay(new Date(getDateWithoutDote(fdd.dateStart))),
        end: endOfDay(new Date(getDateWithoutDote(fdd.dateEnd))),
      });
    }
    if (fdd.isPermanent && date) {
      return date.getTime() >= startOfDay(new Date(getDateWithoutDote(fdd.dateStart))).getTime();
    }
  });
  return !disDate;
};

export const isEqualDates = (startDate: Date, endDate: Date, selectedStart: string, selectedEnd: string) => {
// todo переделать startDate endDate форматы
  return isEqual(sub(endDate, { minutes: 1 }), new Date(getDateWithoutDote(selectedEnd))) &&
    isEqual(startDate, new Date(getDateWithoutDote(selectedStart)));
};

export const getInformationByDate = (
  startDate: Date,
  reservationsByStartDate: (Reservation<DatesString> & IntervalType)[],
  dateTypeToday?: 'dateTypeToday',
) => {
  // поиск свободного интервала с учетом бронирования в прошлое на 30 минут и зависимостью от текущего часа
  const startFreeSlot = getStartIntervalByToday();
  // ищем интервалы занятые
  const busyTimesStart: { slot: string; }[] = [];
  const busyTimesEnd: { slot: string; }[] = [];

  reservationsByStartDate.forEach(item => {
    const indexStart = timeSlots.findIndex(ts => ts.slot === item?.interval.start);
    const indexEnd = timeSlots.findIndex(ts => ts.slot === item?.interval.end);
    const getBusyTimesByReservationStart = timeSlots.slice(indexStart, indexEnd);

    getBusyTimesByReservationStart.forEach(busyTimeSlot => busyTimesStart.push(busyTimeSlot));
    const getBusyTimesByReservationEnd = timeSlots.slice(indexStart + 1, indexEnd + 1);
    getBusyTimesByReservationEnd.forEach(busyTimeSlot => busyTimesEnd.push(busyTimeSlot));
  });
  const sortBusyTimesStart = sortBy(busyTimesStart, 'slot');
  const sortBusyTimesEnd = sortBy(busyTimesEnd, 'slot');

  const sliceEarlyTimeStart = timeSlots.slice(0, 14); // 14 индекс 7 утра
  const sliceLateTimeStart = timeSlots.slice(44);  // 45 индекс 22:00

  const sliceEarlyTimeEnd = timeSlots.slice(0, 15); // 15 индекс 7:30
  const sliceLateTimeEnd = timeSlots.slice(45); // 45 индекс 22:30

  const getBusyTimesStartByToday = timeSlots.slice(0, findIndex(timeSlots, ['slot', startFreeSlot]));
  const getBusyTimesEndByToday = timeSlots.slice(0, findIndex(timeSlots, ['slot', startFreeSlot]) + 1);

  const resultBusyTimesStart = dateTypeToday
    ? [...getBusyTimesStartByToday, ...sliceEarlyTimeStart, ...sortBusyTimesStart, ...sliceLateTimeStart]
    : [...sliceEarlyTimeStart, ...sortBusyTimesStart, ...sliceLateTimeStart];
  const resultBusyTimesEnd = dateTypeToday
    ? [...getBusyTimesEndByToday, ...sliceEarlyTimeEnd, ...sortBusyTimesEnd, ...sliceLateTimeEnd]
    : [...sliceEarlyTimeEnd, ...sortBusyTimesEnd, ...sliceLateTimeEnd];

  // ищем интервалы занятые
  const sortResultBusyTimesStart = sortBy(uniqBy(resultBusyTimesStart, 'slot'), 'slot');
  const sortResultBusyTimesEnd = sortBy(uniqBy(resultBusyTimesEnd, 'slot'), 'slot');

  // ищем интервалы свободные
  const freeTimesStart = difference(timeSlots, sortResultBusyTimesStart);
  const freeTimesEnd = difference(timeSlots, sortResultBusyTimesEnd);

  return {
    startDate,
    busyStartSlots: sortResultBusyTimesStart,
    busyEndSlots: sortResultBusyTimesEnd,
    freeStartSlots: freeTimesStart,
    freeEndSlots: freeTimesEnd,
  };
};

export const getReservationsBySelectedDate = (reservationsByWorkspaceId: (ItemReserv | ItemReservFull)[], selectedDate: Date) => {
  return reservationsByWorkspaceId
    .filter((item) => {
      return item.attributes.isPartTime && item.attributes.dateStart.split('T')[0] === getOnlyDate(selectedDate);
    })
    .map(res => {
      if (res.attributes.dateEnd) {
        const start = getTimeSlot(new Date(res.attributes.dateStart.split('.')[0]));
        const end = getTimeSlot(add(new Date(res.attributes.dateEnd.split('.')[0]),  { minutes: 1 }));
        return ({ ...res, interval: { start, end } });
      }
    }) as (Reservation<DatesString> & IntervalType)[];
};

type PreparationItem = {
  dateStart: string;
  dateEnd: string;
  isPermanent: boolean;
  isFullDay: boolean;
  isPartTime: boolean;
  start: number;
};

export const preparationReservation = ( selectedReservation: {
  attributes: ReservationAttributes<DatesString>;
  id: string;
  type: 'reservation' | string;
}) => {
  const createObj: Partial<PreparationItem> = {};

  createObj.dateStart = selectedReservation.attributes.dateStart;
  createObj.dateEnd = selectedReservation.attributes.dateEnd ?? createObj.dateStart;
  createObj.isPermanent = selectedReservation.attributes.isPermanent;
  createObj.isFullDay = selectedReservation.attributes.isFullDay;
  createObj.isPartTime = selectedReservation.attributes.isPartTime;
  createObj.start = new Date(selectedReservation.attributes.dateStart.split('.')[0]).getTime();

  return createObj as PreparationItem;
};

export const getFreeIntervalsForEnd = (
  reservationsByWorkspaceId: (Reservation<DatesString> & { start: number })[],
  currentDate: Date,
  startIntervalIndex: number,
  type?: 'end',
  selectedReservation?: {
    attributes: ReservationAttributes<DatesString>;
    id: string;
    type: 'reservation' | string;
  },
) => {
  const reservationsByStartDate = reservationsByWorkspaceId
    ? compact(getReservationsBySelectedDate(reservationsByWorkspaceId, currentDate))
    : [];
  const dateTypeToday = getOnlyDate(new Date()) === getOnlyDate(currentDate) ? 'dateTypeToday' : undefined;
  const informationByDate = getInformationByDate(currentDate, reservationsByStartDate, dateTypeToday);

  let currentIntervals = informationByDate.busyStartSlots;

  if (selectedReservation) {
    const selectedStartTime = format(new Date(selectedReservation.attributes.dateStart.split('.')[0]), 'HH:mm');
    const selectedEndTime = format(add(new Date((selectedReservation?.attributes?.dateEnd as string).split('.')[0]), { minutes: 1 }), 'HH:mm');

    const selectedIndexStart = timeSlots.findIndex(it => it.slot === selectedStartTime);
    const selectedIndexEnd =  timeSlots.findIndex(it => it.slot === selectedEndTime);

    const selectedSlots = timeSlots.slice(selectedIndexStart, selectedIndexEnd);

    currentIntervals = differenceBy(informationByDate.busyStartSlots, selectedSlots, 'slot');
  }

  const getStartSlots = currentIntervals.filter((it: { slot: string }) =>
    getNumber(it.slot) < getNumber(timeSlots[startIntervalIndex].slot));
  const getEndSlots = currentIntervals.filter((it: { slot: string }) =>
    getNumber(it.slot) > getNumber(timeSlots[startIntervalIndex].slot));

  // поиск свободного интревала по индексам
  const indStartFree = type
    ? timeSlots.findIndex(it => it.slot === getStartSlots[getStartSlots.length - 1].slot)
    : timeSlots.findIndex(it => it.slot === getStartSlots[getStartSlots.length - 1].slot) + 1;
  const indEndFree = type
    ? timeSlots.findIndex(it => it.slot === getEndSlots[0].slot)
    : timeSlots.findIndex(it => it.slot === getEndSlots[0].slot) + 1;
  // const getFreeIntervalsForStart = timeSlots.slice(indStartFree, indEndFree);
  return timeSlots.slice(indStartFree, indEndFree);
};

export const getNextFreeDate = (disDates: DisableDate[]) => {
  // для по дням
  // есть выбранная дата/нет выбранной даты
  const allDisDate: string[] = [];

  disDates.forEach(it => {
    if (!it.isPartTime && !it.isPermanent) {
      const dates: Date[] = eachDayOfInterval({
        start: new Date(getDateWithoutDote(it.dateStart)),
        end: new Date(getDateWithoutDote(it.dateEnd)),
      });
      dates.forEach((item) => allDisDate.push((getOnlyDate(item))));
    }
    if (it.isPermanent) {
      const start = new Date(getDateWithoutDote(it.dateStart));
      allDisDate.push(getOnlyDate(start));
    }
  });

  const searchEnd = allDisDate.length > 0
    ? startOfDay(new Date(allDisDate[allDisDate.length - 1]))
    : add(startOfDay(new Date()), { days: 1 });

  // проверим есть ли бронь на постоянку
  let allDates;
  const isHaveDatePermanent = disDates.find(it => it.isPermanent);

  if (getOnlyDate(searchEnd) === getOnlyDate(new Date())) {
    allDates = eachDayOfInterval({
      start: new Date(),
      end: add(new Date(), { days: 1 }),
    }).map(it => getOnlyDate(it));
  } else {

    if (isAfter(searchEnd, new Date())) {
      allDates = eachDayOfInterval({
        start: new Date(),
        end: searchEnd,
      }).map(it => getOnlyDate(it));
    } else {
      allDates = [getOnlyDate(new Date())];
    }
  }

  const datesFree = difference(allDates, allDisDate);

  if (isHaveDatePermanent) {
    return datesFree;
  } else {
    return datesFree.length > 0
      ? datesFree
      : [getOnlyDate(add(new Date(allDates[allDates.length - 1]), { days: 1 }))];
  }
};

export const getNextFreeDateInModal = (disDates: DisableDate[], selectedDate?: Date | null) => {
  // для по дням
  // есть выбранная дата/нет выбранной даты
  const allDisDate: string[] = [];

  disDates?.forEach(it => {
    if (it.isPartTime && it.isDirtyDay) {
      const start = getDateWithoutT(it.dateStart);
      allDisDate.push(start);
    }
    if (it.isFullDay) {
      const dates: Date[] = eachDayOfInterval({
        start: new Date(getDateWithoutDote(it.dateStart)),
        end: new Date(getDateWithoutDote(it.dateEnd)),
      });
      dates.forEach((item) => allDisDate.push((getOnlyDate(item))));
    }
    if (it.isPermanent) {
      const start = new Date(getDateWithoutDote(it.dateStart));
      allDisDate.push(getOnlyDate(start));
    }
  });

  const searchEnd = allDisDate.length > 0
    ? new Date(allDisDate[allDisDate.length - 1])
    : add(startOfDay(new Date()), { days: 1 });

  let allDates;
  // проверим есть ли бронь на постоянку
  const isHaveDatePermanent = disDates.find(it => it.isPermanent);

  if (getOnlyDate(searchEnd) === getOnlyDate(new Date())) {
    allDates = eachDayOfInterval({
      start: new Date(),
      end: add(new Date(), { days: 1 }),
    }).map(it => getOnlyDate(it));
  } else {

    if (isAfter(searchEnd, new Date())) {
      allDates = eachDayOfInterval({
        start: new Date(),
        end: searchEnd,
      }).map(it => getOnlyDate(it));
    } else {
      allDates = [getOnlyDate(new Date())];
    }
  }

  const datesFree = difference(allDates, allDisDate);

  if (isHaveDatePermanent) {
    return selectedDate && datesFree.includes(getOnlyDate(selectedDate))
      ? [getOnlyDate(selectedDate)]
      : datesFree;
  } else {
    return selectedDate && datesFree?.includes(getOnlyDate(selectedDate))
      ? [getOnlyDate(selectedDate)]
      : datesFree.length > 0 ? datesFree : [getOnlyDate(add(new Date(allDates[allDates.length - 1]), { days: 1 }))];
  }
};
