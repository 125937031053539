import { add } from 'date-fns';

export const textForHelp = 'Выбор одной или нескольких групп пользователей для отправки рассылки. '
  + 'По умолчанию отправка всем пользователям системы, в этом случае поле заполнять не нужно.';

export const getExtension = (fileName: string) =>
  fileName.split('.').pop();

export const sortingMethod = 'DESC';
export const sortingColumn = 'newsletter_id';

export const MIN_DATE = new Date();
export const MAX_DATE = add(new Date(), { months: 24 });