import { serviceContainer } from './ServiceContainer';
import { IDivision, IRole, IUsersPaginationParams, User } from '../types/otherTypes';

export class AdminService {

  async getUsers(): Promise<User[]> {
    await this.checkUsers();
    if (!serviceContainer.usersStore.users) return [];
    else return serviceContainer.usersStore.users;
  }

  async getUsersForse(): Promise<User[]> {
    if (!serviceContainer.usersStore.users) return [];
    else return serviceContainer.usersStore.users;
  }

  async userIsAdmin(): Promise<boolean> {
    await this.checkUsers();
    return serviceContainer.usersStore.isCurrentUserAdmin;
  }

  async currentUser(): Promise<User | null> {
    await this.checkUsers();
    return serviceContainer.usersStore.currentUser;
  }

  async toggleAccess(user: User): Promise<void> {
    await serviceContainer.apiService.updateUser({
      ...user,
      disabled: !user.disabled,
    });
    await serviceContainer.usersService.loadAllUsersForse();
  }

  async editUser(data: User, paginationParams?: IUsersPaginationParams): Promise<void> {
    await serviceContainer.apiService.updateUser(data);
    if (paginationParams) {
      serviceContainer.usersService.loadUsersByPagination(paginationParams)
        .then(res => serviceContainer.adminStore.setUsers(res.data));
    }
  }

  async getAllDivisions(): Promise<IDivision[]> {
    const res = (await serviceContainer.apiService.getDivisions()).data.data;
    serviceContainer.adminStore.allDivisions = res;
    return res as IDivision[];
  }

  async getAllRoles(): Promise<IRole[]> {
    const res = (await serviceContainer.apiService.getRoles()).data;
    serviceContainer.adminStore.allRoles = res;
    return res as IRole[];
  }

  private async checkUsers(): Promise<void> {
    if (!serviceContainer.usersStore.users || !serviceContainer.usersStore.users.length) {
      await serviceContainer.usersService.loadAllUsers();
    }
  }

  async addUser(data: User, paginationParams?: IUsersPaginationParams): Promise<void> {
    await serviceContainer.apiService.addUser(data);
    if (paginationParams) {
      serviceContainer.usersService.loadUsersByPagination(paginationParams)
        .then(res => serviceContainer.adminStore.setUsers(res.data));
    }
  }
}

