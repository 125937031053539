import { IOffice, IRoom } from '../../types/commonTypes';
import { IPremise, IPremiseExternal, TPremiseTableInternal } from '../../pages/AdministrationPage/PremisesTable/PremisesTable.type';

export const toInternalData = (data: IRoom[]): TPremiseTableInternal[] => {
  return data.map(item => {
    return {
      roomId: item.attributes.id,
      officeName: item.attributes.office.id,
      officeNumber: item.attributes.name,
      description: item.attributes.title,
      typeRoom: item.attributes.roomType,
    };
  });
};

export const toInternalRegionsOption = (data: IOffice[]): { id: string; description: string; }[] => {
  function unique(arr: string[]) {
    return Array.from(new Set(arr));
  }

  const ids = unique(data.map(item => {
    return item.attributes.region.id;
  }));

  const newData = ids.map(id => {
    const description = data.find((it) => it.attributes.region.id === id)?.attributes.region.name ?? '';
    return {
      id,
      description: description,
    };
  });

  return newData;
};

export const toInternalPremise = (data: TPremiseTableInternal): IPremise => {
  return {
    roomId: data.roomId,
    officeName: data.officeName,
    officeNumber: data.officeNumber,
    description: data.description,
    typeRoom: data.typeRoom,
  };
};

export const toExternalPremise = (data: IPremise): IPremiseExternal => {
  return {
    roomId: data.roomId,
    officeId: data.officeName,
    roomNumber: data.officeNumber,
    title: data.description,
    roomType: data.typeRoom,
  };
};
