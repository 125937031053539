import { forwardRef } from 'react';
import './TagButton.scss';

type OwnProps = {
  onClick: () => void,
  name: string,
};

export const TagButton = forwardRef<HTMLSpanElement, OwnProps>((props, ref) => {
  const { onClick, name } = props;
  return (
    <span
      className='TagButton ql-formats'
      ref={ref}
    >
      <button
        className='btn'
        onClick={onClick}
      >
        {name}
      </button>
    </span>
  );
});
