import { serviceContainer } from '../ServiceContainer';
import { toInternalData } from './helpers';
import { IDomain } from '../../types/otherTypes';

export class DomainsService {
  async loadDomains() {
    await serviceContainer.apiService.getDomains().then((res) => {
      if (res) {
        serviceContainer.domainsStore.setDomains(toInternalData(res.data.data));
        serviceContainer.domainsStore.isLoaded = false;
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
  }

  async addDomain(body: IDomain) {
    await serviceContainer.apiService.createDomain(body).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно создан блок ${body.domainName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.domainsService.loadDomains();
  }

  async deleteDomain(domainName: string) {
    await serviceContainer.apiService.deleteDomain(domainName).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно удален ${domainName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });

    await serviceContainer.domainsService.loadDomains();
  }

  async updateDomain(oldDomainName: string, body: IDomain) {
    await serviceContainer.apiService.updateDomain(oldDomainName, body)
      .then((res) => {
        if (res) {
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Успешно обновлен блок ${body.domainName}`,
            severity: 'success',
          });
        }
      }).catch((error) => {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
          severity: 'error',
        });
      });

    await serviceContainer.domainsService.loadDomains();
  }
}
