import { makeAutoObservable } from 'mobx';
import { IDivision, IRole, User } from '../types/otherTypes';

export class AdminStore {
  private _users:User[] = [];
  private _filter = '';
  private _isAddingUser = false;
  private _tab = 0;
  private _dropdownUser:User | null = null;
  private _dropdownAnchor:HTMLElement | null = null;
  private _isEditHandler:(()=>void) | null = null;
  private _snackbar:{
    open:boolean,
    message:string,
    severity:'success' | 'info' | 'warning' | 'error',
  } = {
      open:false,
      message:'',
      severity:'error',
    };
  private _userInfoForEdit:User | null = null;
  private _allDivisions: IDivision[] = [];
  private _allRoles: IRole[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get allDivisions() {
    return this._allDivisions;
  }

  set allDivisions(divisions: IDivision[]) {
    this._allDivisions = divisions;
  }

  get allRoles() {
    return this._allRoles;
  }

  set allRoles(roles: IRole[]) {
    this._allRoles = roles;
  }

  get users() {
    return this._users;
  }

  get filter() {
    return this._filter;
  }

  get isAddingUser() {
    return this._isAddingUser;
  }

  get tab() {
    return this._tab;
  }

  get dropdownUser() {
    return this._dropdownUser;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  get snackbar() {
    return this._snackbar;
  }

  get userInfoForEdit() {
    return this._userInfoForEdit;
  }

  setUsers(value: User[]) {
    this._users = value;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  setIsAddingUser(value: boolean) {
    this._isAddingUser = value;
  }

  setTab(value: number) {
    this._tab = value;
  }

  setDropdownUser(value: User) {
    this._dropdownUser = value;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  setIsEditHandler(value: (()=>void) | null) {
    this._isEditHandler = value;
  }

  setSnackbar(value: {
    open: boolean,
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error',
  }) {
    this._snackbar = value;
  } 

  setUserInfoForEdit(value: User | null) {
    this._userInfoForEdit = value;
  }
}
