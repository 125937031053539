import { observer } from 'mobx-react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { FavoritesField } from '../../../types/otherTypes';
import { HeadCell } from '../../../types/otherTypes';
import { MouseEvent } from 'react';

import { visuallyHidden } from '@mui/utils';

type HeadTableCellProps = {
  order: 'asc' | 'desc';
  orderBy: keyof FavoritesField;
  createSortHandler: (property: keyof FavoritesField) => (event: MouseEvent<unknown>) => void;
  headColumns: HeadCell[];
  mediaMax850?: boolean;
};

export const SortTableHead = observer(({
  order,
  orderBy,
  createSortHandler,
  headColumns,
  mediaMax850,
}: HeadTableCellProps) => {
  return (
    <TableHead className='table-head'>
      <TableRow className='table-row'>
        {headColumns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            className='table-cell'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {mediaMax850 || headCell.id === 'action' ? headCell.label : (
              <TableSortLabel
                active={orderBy ? orderBy === headCell.id : false}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className='label-sort'
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component='span'
                    sx={visuallyHidden}
                  >
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {mediaMax850 ? null : (
          <TableCell
            className='control'
            align='right'
            key='control'
          />
        )}
      </TableRow>
    </TableHead>
  );
});