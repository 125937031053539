import { makeAutoObservable } from 'mobx';
import { Scheduler } from '../types/otherTypes';

export class SchedulerStore {
  private _schedulers: Scheduler[] = [];
  private _filteredSchedulers: Scheduler[] = [];
  private _scheduler?: Scheduler;

  constructor() {
    makeAutoObservable(this);
  }
  get schedulers() {
    return this._schedulers;
  }

  set schedulers(schedulers) {
    this._schedulers = schedulers;
  }

  get filteredSchedulers() {
    return this._filteredSchedulers;
  }

  set filteredSchedulers(schedulers) {
    this._filteredSchedulers = schedulers;
  }

  get scheduler() {
    return this._scheduler;
  }

  set scheduler(scheduler) {
    this._scheduler = scheduler;
  }

  resetSearch() {
    this._filteredSchedulers = this._schedulers;
  }
}
