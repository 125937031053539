import { BlockRounded, ChatRounded, CheckRounded } from '@mui/icons-material';
import { ClickAwayListener, Grow, IconButton, Popper, TextField, Tooltip } from '@mui/material';
import { ColorProvider } from 'UI/components';
import { serviceContainer } from 'services';
import { useEffect, useRef, useState } from 'react';

import s from './NotePopper.module.scss';

export const NotePopper = () => {
  const [noteTooltipOpen, setNoteTooltipOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState('');

  const noteRef = useRef(null);

  useEffect(() => {
    setCurrentNote(serviceContainer.roomStore.getNote() || '');
  }, [serviceContainer.roomStore.getNote()]);

  return (
    <>
      <ColorProvider color='#168ff7'>
        <Tooltip
          placement="top"
          title='Редактировать комментарий'
          arrow
        >
          <IconButton
            color='primary'
            ref={noteRef}
            onClick={() => setNoteTooltipOpen(true)}
          >
            <ChatRounded />
          </IconButton>
        </Tooltip>
      </ColorProvider>
      {/* @ts-ignore */}
      <Popper
        placement='right'
        open={noteTooltipOpen}
        anchorEl={noteRef.current}
        transition
        className={s.note_popper}
      >
        {({ TransitionProps }) => (
          <Grow
            style={{ transformOrigin: '0 50% 0' }}
            {...TransitionProps}
            timeout={100}
          >
            <div>
              <ClickAwayListener onClickAway={() => setNoteTooltipOpen(false)}>
                <div className={s.content}>
                  <TextField
                    label="Комментарий"
                    multiline
                    minRows={3}
                    maxRows={3}
                    value={currentNote}
                    onChange={(e) => setCurrentNote(e.target.value)}
                  />
                  <div className={s.buttons}>
                    <ColorProvider color='#0aaf52'>
                      <IconButton
                        color='primary'
                        onClick={() => {
                          serviceContainer.roomStore.setNote(currentNote);
                          setNoteTooltipOpen(false);
                        }}
                      >
                        <CheckRounded />
                      </IconButton>
                    </ColorProvider>
                    <ColorProvider color='#f83021'>
                      <IconButton
                        color='primary'
                        onClick={() => setCurrentNote(serviceContainer.roomStore.getNote() || '')}
                      >
                        <BlockRounded />
                      </IconButton>
                    </ColorProvider>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};