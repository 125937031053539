import { Tooltip } from '@mui/material';
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ArrowLeftRounded,
  ArrowRightRounded,
  AutorenewRounded,
  OpenInFullRounded,
  InfoOutlined,
} from '@mui/icons-material';
import FoLogoPng from 'assets/img/logo/FoLogo.png';
import { RoomVariant } from 'types/commonTypes';
import { Kbd } from 'UI/components';

import s from './ControlPanelTooltip.module.scss';

export const ControlPanelTooltip = ({ roomVariant }:{ roomVariant: RoomVariant }) => {
  
  return (
    <Tooltip
      PopperProps={{ className: s.office_control_panel_tooltip }}
      title={
        <div className={s.content}>
          {roomVariant === 'room' &&
            <div className={s.item}>
              <div className={s.keys}>
                <Kbd>
                  F
                </Kbd>
              </div>
              <div className={s.name}>
                Рабочее место FlexOffice
              </div>
              <img
                src={FoLogoPng}
                className={s.icon}
              />
            </div>}
          <div className={s.item}>
            <div className={`${s.keys} ${s.ctrl}`}>
              <Kbd>ctrl</Kbd>
              <span className={s.plus}>+</span>
              <div className={s.arrows}>
                <Kbd>
                  <ArrowLeftRounded />
                </Kbd>
                <Kbd>
                  <ArrowRightRounded />
                </Kbd>
              </div>
            </div>
            <div className={s.name}>
              Поворот
            </div>
            <div className={s.icon}>
              <AutorenewRounded />
            </div>
          </div>
          <div className={s.item}>
            <div className={`${s.keys} ${s.ctrl}`}>
              <Kbd>ctrl</Kbd>
              <span className={s.plus}>+</span>
              <div className={`${s.arrows} ${s.vertical}`}>
                <Kbd>
                  <ArrowDropUpRounded />
                </Kbd>
                <Kbd>
                  <ArrowDropDownRounded />
                </Kbd>
              </div>
            </div>
            <div className={s.name}>
              Размер
            </div>
            <div className={s.icon}>
              <OpenInFullRounded />
            </div>
          </div>
          {roomVariant === 'room' &&
            <div className={s.item}>
              <div className={`${s.keys} ${s.ctrl}`}>
                <Kbd>shift</Kbd>
                <span className={s.plus}>+</span>
                <div className={s.arrows}>
                  <Kbd>
                    <ArrowLeftRounded />
                  </Kbd>
                  <Kbd>
                    <ArrowRightRounded />
                  </Kbd>
                </div>
              </div>
              <div className={s.name}>
                Ширина
              </div>
              <div className={`${s.icon} ${s.horizontal_scale}`}>
                <OpenInFullRounded />
              </div>
            </div>}
          <div className={s.item}>
            <div className={`${s.keys} ${s.move_arrows}`}>
              <Kbd>
                <ArrowDropUpRounded />
              </Kbd>
              <div>
                <Kbd>
                  <ArrowLeftRounded />
                </Kbd>
                <Kbd>
                  <ArrowDropDownRounded />
                </Kbd>
                <Kbd>
                  <ArrowRightRounded />
                </Kbd>
              </div>
            </div>
            <div className={s.name}>
              Перемещение на 1px
            </div>
            <div className={s.icon}>
              <ArrowDropUpRounded />
            </div>
          </div>
        </div>
        }
      placement="top"
      arrow
    >
      <InfoOutlined className={`${s.icon} ${s.info}`} />
    </Tooltip>
  );
};