import { observer } from 'mobx-react';
import { IconButton, Checkbox } from '@mui/material';
import { Grade, GradeOutlined } from '@mui/icons-material';
import { serviceContainer } from 'services';
import { useLocation, useParams } from 'react-router-dom';

import './FavoritesIcon.scss';

type FavoritesIconProps = {
  url: string,
  regionId?: string,
  officeId?: string,
  workspaceId?: string | undefined,
  roomId?: string,
};

const nameTypeFavoriteRoom: { [key: string]: string } = {
  locker: 'Шкаф-локер',
  room: 'Комната',
  meeting: 'Переговорная',
};

const nameTypeFavoritePlace: { [key: string]: string } = {
  desktop_fo: 'РМ',
  desktop: 'РМ',
  locker: 'Локер',
};

const FavoritesIcon = observer(({ url }: FavoritesIconProps) => {
  const { regionId, roomId, officeId, workspaceId } = useParams<{ regionId: string, officeId: string, roomId: string, workspaceId: string }>();
  const { pathname } = useLocation();
  
  const currentUser = serviceContainer.usersStore.currentUser?.userId ?? '';

  const currentFavorite = serviceContainer.favoritesStore.getFavoritesByKey(currentUser)?.find((favorite) => {
    const prepareUrl = `/${favorite.attributes.url.split('/').slice(3).join('/')}`;
    return prepareUrl === pathname;
  });

  const addFavorite = () => {
    if (currentUser) {
      const workspacesByKey = serviceContainer.workspacesStore.getWorkspacesByKey(roomId);
      const room = serviceContainer.roomsStore.getRoomsByKey(officeId)?.find((it) => it.attributes.id === roomId);
      const workspace = workspacesByKey?.find((it) => it.id === workspaceId);

      const roomName = serviceContainer.roomsStore.getRoomsByKey(officeId)?.find((it) => it.attributes.id === roomId)?.attributes.name ?? '';
      const officeName = serviceContainer.officesStore.getOfficesNewByKey(regionId)?.find(it => it.attributes.id === officeId)?.attributes.name ?? '';
      const regionName = serviceContainer.regionsStore.regions?.find(it => it.id === regionId)?.name ?? '';
      const numberFavorites = workspace?.workspace_number ? parseInt(workspace?.workspace_number as any) : 0;
      const placeType = nameTypeFavoritePlace[workspace?.type ?? ''] ?? nameTypeFavoriteRoom[room?.attributes?.roomType ?? ''];

      serviceContainer.favoritesService.createFavorites({
        userId: currentUser,
        placeType: placeType,
        regionName: regionName,
        officeName: officeName,
        roomNumber: roomName,
        workspaceNumber: numberFavorites,
        url: `${serviceContainer.apiService.API_URL}/${url}`,
      }).then(res => {
        if (res) {
          serviceContainer.favoritesStore.setFavoritesByKey(currentUser,
            [res as any, ...serviceContainer.favoritesStore.getFavoritesByKey(currentUser) as any]);
        }
      });
    }
  };

  const handleOnClick = () => {
    if (Boolean(currentFavorite)) {
      if (currentFavorite?.id) {
        serviceContainer.favoritesService.deleteFavorites(currentUser, currentFavorite?.id);
        const newBody = serviceContainer.favoritesStore.getFavoritesByKey(currentUser)?.filter((item) => item.id !== currentFavorite?.id);
        serviceContainer.favoritesStore.setFavoritesByKey(currentUser, newBody);
      }
    } else {
      addFavorite();
    }
  };

  return (
    <IconButton
      className="favorite-icon"
      onClick={handleOnClick}
    >
      <Checkbox
        icon={<GradeOutlined />}
        checkedIcon={<Grade style={{ color: '#ffbc15' }}/>}
        checked={Boolean(currentFavorite)}
      />
    </IconButton>
  );
});

export default FavoritesIcon;
