import { Typography } from '@mui/material';
import { observer } from 'mobx-react';

import './Toolbar.scss';

interface ToolbarProps {
  title: string;
  isEdit: '' | undefined | boolean;
}

const isMobile = window.innerWidth < 600;

export const Toolbar = observer(({ title, isEdit }: ToolbarProps) => {
  
  return (
    <div className="toolbar">
      <Typography className={`title ${title.length > 7 ? 'small' : ''}`}>
        {title}
      </Typography>
      {isMobile ? null : (
        <ul className={`hint-panel ${isEdit ? 'edit' : ''}`}>
          <li className="element">
            <div className='circle green'/>
            <Typography className="hint">Свободно</Typography>
          </li>
          <li className="element">
            <div className='circle orange'/>
            <Typography className="hint">Забронировано</Typography>
          </li>
          <li className="element">
            <div className='circle red'/>
            <Typography className="hint">Занято</Typography>
          </li>
          <li className="element">
            <div className='circle grey'/>
            <Typography className="hint">Недоступно</Typography>
          </li>
        </ul>
      )}
    </div>
  );
});
