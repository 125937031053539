import { makeAutoObservable, toJS } from 'mobx';
import { TDomainTableInternal } from '../pages/AdministrationPage/DomainsTable/DomainsTable.type';

export class DomainsStore {
  private _domains: TDomainTableInternal[] = [];
  private _isLoaded: boolean = true;
  private _filter: string = '';
  private _isAddingDomain = false;
  private _dropdownDomain: TDomainTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _domainInfoForEdit: TDomainTableInternal | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get domains() {
    return toJS(this._domains);
  }

  setDomains(domains: TDomainTableInternal[]) {
    this._domains = domains;
  }

  get isLoaded() {
    return this._isLoaded;
  }

  set isLoaded(isLoaded) {
    this._isLoaded = isLoaded;
  }


  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingDomain() {
    return this._isAddingDomain;
  }

  setIsAddingDomain(value: boolean) {
    this._isAddingDomain = value;
  }

  get dropdownDomain() {
    return this._dropdownDomain;
  }

  setDropdownDomain(value: TDomainTableInternal) {
    this._dropdownDomain = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get domainInfoForEdit() {
    return this._domainInfoForEdit;
  }

  setDomainInfoForEdit(value: TDomainTableInternal | null) {
    this._domainInfoForEdit = value;
  }
}
