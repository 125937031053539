import { serviceContainer } from '../ServiceContainer';
import { toExternalPremise, toInternalData, toInternalPremise } from './helpers';
import { TPremiseTableInternal } from '../../pages/AdministrationPage/PremisesTable/PremisesTable.type';

export class PremisesService {
  
  async loadPremises() {
    try {
      serviceContainer.premisesStore.premisesLoading  = true;
      const r = await serviceContainer.apiService.getAllRooms();
      const result = toInternalData(r.data.data);
      serviceContainer.premisesStore.setPremisesTable(result);
      serviceContainer.premisesStore.setRoomsTypeOption(['locker', 'room', 'meetingroom']);
      serviceContainer.premisesStore.isRequestError = false;
    } finally {
      serviceContainer.premisesStore.premisesLoading = false;
    }
    
  }

  async addPremise(params: TPremiseTableInternal) {
    const item = toExternalPremise(params);
    await serviceContainer.apiService.createRoom(item).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлен ${params.officeName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.premisesService.loadPremises();
  }

  async updatePremise(params: TPremiseTableInternal) {
    const item = toExternalPremise(params);
    await serviceContainer.apiService.updateRoom(params.roomId, item).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлен ${params.officeName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.premisesService.loadPremises();
  }

  async deletePremise(roomId: string) {
    await serviceContainer.apiService.deleteRoom(roomId).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлен ${serviceContainer.premisesStore.premiseInfoForEdit?.officeName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.premisesService.loadPremises();
  }
}
