import { ReactElement } from 'react';
import { serviceContainer } from 'services';
import { Divider, Tooltip, Skeleton } from '@mui/material';
import { Status } from 'types/commonTypes';
import { statusMap } from 'utils';
import { PersonRounded } from '@mui/icons-material';

import './RoomTooltip.scss';

interface RoomTooltipProps {
  label: string;
  id: number | string;
  status: Status;
  children: ReactElement;
  isFlexible?: boolean;
  reservator?: string;
  reservatorIcon?: string | null;
  note?: string | null;
  disabled?: boolean;  
  onOpen?: () => void;
  onClose?: () => void;
  divisionAcl?: string[];
}

export const RoomTooltip = ({
  label,
  id,
  status,
  children,
  isFlexible,
  reservator,
  reservatorIcon,
  note,
  onOpen,
  onClose,
  disabled,
  divisionAcl,
}: RoomTooltipProps) => {
  return disabled ? children :
  <Tooltip
    classes={{ tooltip: '.Tooltip-tooltip room-tooltip' }}
    title={<div className='content'>
      {reservator && (
        <>
          <div className='reservator_info'>
            {status === 'free' || !serviceContainer.usersStore.userIcon[reservatorIcon ?? '']  ? <PersonRounded className='icon' /> : (
              <img
                className='reservator_info_icon'
                src={URL.createObjectURL(serviceContainer.usersStore.userIcon[reservatorIcon ?? ''] as Blob | MediaSource)}
                alt={`${reservatorIcon}`}
              />
            )}
            <div className="reservator">
              {reservator}
            </div>
          </div>
          <Divider className='divider'/>
        </>
      )}
      <div className='top'>
        <div className='title'>
          {label}
        </div>
        <div className='number'>
          №{id}
        </div>
      </div>
      <div className="note">
        {note}
      </div>
      {divisionAcl?.length
        ? (
          <div className='divisions'>
            {`Доступно для бронирования: ${divisionAcl.join(', ')}`}
          </div>
        ) : null}
      {isFlexible && <>
        <div className="hint">
          Рабочее место FlexOffice. Доступно для бронирования не более чем на 3 дня
        </div>
        <Divider className='divider'/>
      </>}
      <div className='status'>
        <div className={`circle ${status}`}/>
        <div>
          {statusMap[status]}
        </div>
      </div>
    </div>}
    arrow
    onOpen={onOpen}
    onClose={onClose}
  >
    {children}
  </Tooltip>;
};
