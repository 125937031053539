import { makeAutoObservable } from 'mobx';
import { VersionDto, NotificationVersionsDto, INewsletterDto } from 'types';

export class DistributionStore {
  private _isLoading: boolean = false;
  private _allNotifications: NotificationVersionsDto[] = [];
  private _currentNotification: NotificationVersionsDto | undefined;
  private _currentVersion: VersionDto | undefined;
  private _allNewsletters: INewsletterDto[] = [];
  private _filter = '';
  private _newNewsletter: INewsletterDto | undefined;
  private _isLoadingNewsletter: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get allNotifications() {
    return this._allNotifications;
  }

  set allNotifications(value) {
    this._allNotifications = value;
  }

  get currentNotification() {
    return this._currentNotification;
  }

  set currentNotification(value) {
    this._currentNotification = value;
  }

  get currentVersion() {
    return this._currentVersion;
  }

  set currentVersion(value) {
    this._currentVersion = value;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get allNewsletters() {
    return this._allNewsletters;
  }

  set allNewsletters(value) {
    this._allNewsletters = value;
  }

  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get newNewsletter() {
    return this._newNewsletter;
  }

  set newNewsletter(value) {
    this._newNewsletter = value;
  }

  get isLoadingNewsletter() {
    return this._isLoadingNewsletter;
  }

  set isLoadingNewsletter(value) {
    this._isLoadingNewsletter = value;
  }
}
