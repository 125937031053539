import {
  Snackbar,
  Alert,
} from '@mui/material';
import { observer } from 'mobx-react';
import { serviceContainer } from 'services';

export const TransitionsSnackbar = observer(() => {
  const handleClose = () => serviceContainer.snackbarStore.setOpen(false);
	
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={serviceContainer.snackbarStore.snackbar.open}
        onClose={handleClose}
        autoHideDuration={5000}
        TransitionComponent={serviceContainer.snackbarStore.transitionComponent}
      >
        <Alert
          onClose={handleClose}
          sx={{ width: '100%' }}
          severity={serviceContainer.snackbarStore.snackbar.severity}
          variant="filled"
        >
          {serviceContainer.snackbarStore.snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
});
