import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ArrowLeftRounded,
  ArrowRightRounded,
  AutorenewRounded,
  DeleteRounded,
  LockRounded,
  OpenInFullRounded,
  DesktopAccessDisabled,
  Computer,
} from '@mui/icons-material';
import {
  IconButton,
  Input,
  Slider,
  Typography,
  Tooltip,
  SliderValueLabelProps,
} from '@mui/material';
import { serviceContainer } from 'services';
import { RoomVariant } from 'types/commonTypes';
import {
  MAX_WIDTH,
  MIN_WIDTH,
  POSITION_STEP,
  ROTATION_STEP,
  WIDTH_STEP,
  rotationMarks,
  widthMarks,
} from 'utils';
import FoLogoPng from 'assets/img/logo/FoLogo.png';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { ColorProvider } from 'UI/components';
import { NotePopper } from '../NotePopper/NotePopper';

import s from './WorkspaceControlPanel.module.scss';

export const WorkspaceControlPanel = observer(({
  roomVariant,
  setAccessModalOpen,
}: {
  roomVariant: RoomVariant,
  setAccessModalOpen: (value: boolean) => void,
}) => {

  const [confirmDeleteTimeout, setConfirmDeleteTimeout] = useState<NodeJS.Timeout>();

  const onDelete = () => {
    if (serviceContainer.roomStore.confirmDelete) { 
      serviceContainer.roomStore.setConfirmDelete(false);
      clearTimeout(confirmDeleteTimeout);
      serviceContainer.roomStore.deleteDraggable();
    } else {
      serviceContainer.roomStore.setConfirmDelete(true);
      clearTimeout(confirmDeleteTimeout);
      setConfirmDeleteTimeout(setTimeout(() => {
        serviceContainer.roomStore.setConfirmDelete(false);
      }, 2000));
    }
  };

  const getWorkspacesType = () => {
    if (serviceContainer.roomStore.isDesktopNa) {
      return <DesktopAccessDisabled className={s.disabled} />;
    }
    if (serviceContainer.roomStore.isCurrentFlexible) {
      return <img
        src={FoLogoPng}
        style={{
          width: '24px',
        }}
             />;
    }
    return <Computer className={s.desktop} />;  
  };

  const getInfoWorkspacesType = () => {
    if (serviceContainer.roomStore.isDesktopNa) {
      return 'Рабочее место, недоступное для бронирования';
    }
    if (serviceContainer.roomStore.isCurrentFlexible) {
      return 'Рабочее место FlexOffice';
    }
    return 'Рабочее место, доступное для бронирования';
  };

  return (
    <div className={`${s.workspace_control_panel}  ${serviceContainer.roomStore.selected ? s.show : ''}`}>
      <div className={s.buttons}>
        <ColorProvider color='#f83021'>
          <Tooltip
            placement="top"
            title='Удалить'
            arrow
          >
            <IconButton
              color='primary'
              onClick={onDelete}
            >
              <DeleteRounded />
              <Typography className={s.question}>
                {serviceContainer.roomStore.confirmDelete && '?'}
              </Typography>
            </IconButton>
          </Tooltip>
        </ColorProvider>
        {roomVariant === 'room' &&
          <Tooltip
            placement="top"
            arrow
            title={getInfoWorkspacesType()}
          >
            <IconButton
              onClick={() => serviceContainer.roomStore.toogleCurrentType()}
            >
              {getWorkspacesType()}
            </IconButton>
          </Tooltip>}
        <ColorProvider color='#168ff7'>
          <Tooltip
            placement="top"
            arrow
            title='Редактировать доступ на бронирование места'
          >
            <IconButton
              color='primary'
              onClick={() => setAccessModalOpen(true)}
            >
              <LockRounded />
            </IconButton>
          </Tooltip>
        </ColorProvider>
        <NotePopper/>
      </div>
      <div className={s.sliders}>
        <div>
          <Slider
            value={serviceContainer.roomStore.currentRotation}
            onChange={(_, value) => {
              if (!Array.isArray(value)) {
                serviceContainer.roomStore.setCurrentRotation(value);
              }
            }}
            marks={rotationMarks}
            step={ROTATION_STEP}
            max={360}
            components={{
              ValueLabel: (props: SliderValueLabelProps) => {
                const { children, value } = props;
                return (
                  <Tooltip
                    arrow
                    enterTouchDelay={0}
                    placement="top"
                    title={value}
                  >
                    {children}
                  </Tooltip>
                );
              },
            }}
            valueLabelDisplay="auto"
          />
          <AutorenewRounded />
        </div>
        {roomVariant === 'room' &&
          <div>
            <Slider
              value={serviceContainer.roomStore.currentWidth}
              onChange={(_, value) => {
                if (!Array.isArray(value)) {
                  serviceContainer.roomStore.setCurrentWidth(value);
                }
              }}
              marks={widthMarks}
              step={WIDTH_STEP}
              min={MIN_WIDTH}
              max={MAX_WIDTH}
            />
            <OpenInFullRounded className={s.icon_arrow} />
          </div>}
      </div>
      <div className={s.position}>
        <div className={s.inputs}>
          <div>
            <Typography fontWeight={500}>x</Typography>
            <Input
              value={serviceContainer.roomStore.currentPosition.x}
              size="small"
              onChange={(e)=>serviceContainer.roomStore.setX(+e.target.value)}
              inputProps={{
                step: POSITION_STEP,
                type: 'number',
              }}
            />
          </div>
          <div>
            <Typography fontWeight={500}>y</Typography>
            <Input
              value={serviceContainer.roomStore.currentPosition.y}
              size="small"
              onChange={(e)=>serviceContainer.roomStore.setY(+e.target.value)}
              inputProps={{
                step: POSITION_STEP,
                type: 'number',
              }}
            />
          </div>
        </div>
        <div className={s.arrows}>
          <IconButton onClick={() => serviceContainer.roomStore.move(0, -1)}>
            <ArrowDropUpRounded />
          </IconButton>
          <IconButton onClick={() => serviceContainer.roomStore.move(-1, 0)}>
            <ArrowLeftRounded />
          </IconButton>
          <IconButton onClick={() => serviceContainer.roomStore.move(0, 1)}>
            <ArrowDropDownRounded />
          </IconButton>
          <IconButton onClick={() => serviceContainer.roomStore.move(1, 0)}>
            <ArrowRightRounded />
          </IconButton>
        </div>
      </div>
    </div>);
});