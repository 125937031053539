import { observer } from 'mobx-react';
import {
  IconButton,
  Input,
  Slider,
  Tooltip,
} from '@mui/material';
import {
  OpenInFullRounded,
  DeleteOutlineRounded,
  DoneRounded,
  BlockRounded,
} from '@mui/icons-material';
import { AddWorkspace, UploadSvg } from 'assets';
import { serviceContainer } from 'services';
import {
  MAX_SCALE,
  MIN_SCALE,
  SCALE_STEP,
  scaleMarks,
} from 'utils/editorHelpers';
import { RoomVariant } from 'types/commonTypes';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ControlPanelTooltip } from '../ControlPanelTooltip/ControlPanelTooltip';
import { ColorProvider } from 'UI/components';
import FoLogoPng from 'assets/img/magnet.png';

import s from './MainControlPanel.module.scss';

export const MainControlPanel = observer(({
  roomVariant,
}: {
  roomVariant: RoomVariant,
}) => {
  const bgFileInputRef = useRef<HTMLInputElement>(null);

  const { roomId } = useParams<{ roomId: string, officeId: string }>();

  return (
    <div className={s.main_control_panel}>
      <div>
        <ControlPanelTooltip roomVariant={roomVariant} />
      </div>
      <div>
        <ColorProvider color='#0aaf52'>
          <Tooltip
            placement="top"
            title='Сохранить изменения'
            arrow
          >
            <IconButton
              color='primary'
              onClick={()=>serviceContainer.roomStore.saveChanges(roomId, roomVariant)}
            >
              <DoneRounded/>
            </IconButton>
          </Tooltip>
        </ColorProvider>
        <ColorProvider color='#f83021'>
          <Tooltip
            placement="top"
            arrow
            title='Отменить изменения'
          >
            <IconButton
              color='primary'
              onClick={()=>serviceContainer.roomStore.exitEditMode()}
            >
              <BlockRounded/>
            </IconButton>
          </Tooltip>
        </ColorProvider>
        <ColorProvider color='#168ff7'>
          <Tooltip
            placement="top"
            title='Удалить все рабочие места'
            arrow
          >
            <IconButton
              color='primary'
              onClick={()=>serviceContainer.roomStore.deleteAllDraggables()}
            >
              <DeleteOutlineRounded/>
            </IconButton>
          </Tooltip>
        </ColorProvider>
      </div>
      <div className={s.buttons}>
        <Tooltip
          placement="top"
          title='Обновить svg офиса'
          arrow
        >
          <IconButton
            onClick={() => bgFileInputRef.current?.click()}
          >
            <input
              type='file'
              style={{ display: 'none' }}
              onChange={(e) => serviceContainer.roomStore.uploadBackground(e)}
              ref={bgFileInputRef}
              accept="image/svg+xml"
              value=""
            />
            <UploadSvg width={21} />
          </IconButton>
        </Tooltip>
        {roomVariant === 'room' && <Tooltip
          placement="top"
          title='Добавить'
          arrow
                                   >
          <IconButton
            onClick={()=>serviceContainer.roomStore.addDraggable()}
          >
            <AddWorkspace width={24} />
          </IconButton>
        </Tooltip>}
      </div>
      {roomVariant === 'room' &&
        <div>
          <Tooltip
            placement="top"
            title='Выравнивание относительно других столов'
            arrow
          >
            <IconButton
              onClick={() => serviceContainer.roomStore.toogleSnap()}
              className={`${s.magnet} ${serviceContainer.roomStore.isSnap ? '' : s.unchecked}`}
            >
              <img
                src={FoLogoPng}
                style={{ width: '48px' }}
              />
            </IconButton>
          </Tooltip>
        </div>}
      <div className={s.scale}>
        <Input
          className={s.input}
          value={serviceContainer.roomStore.scale}
          size="small"
          onChange={(e) => serviceContainer.roomStore.setScale(+e.target.value)}
          inputProps={{
            step: SCALE_STEP,
            min: MIN_SCALE,
            max: MAX_SCALE,
            type: 'number',
          }}
        />
        <Slider
          className={s.slider}
          value={serviceContainer.roomStore.scale}
          onChange={(_, value) => {
            if (!Array.isArray(value)) {
              serviceContainer.roomStore.setScale(value);
            }
          }}
          marks={scaleMarks}
          min={MIN_SCALE}
          max={MAX_SCALE}
          step={SCALE_STEP}
        />
        <OpenInFullRounded />
      </div>
    </div>
  );
});
