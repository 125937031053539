/* eslint-disable */
export const helpText = [
  {
    number: 1,
    question: 'Когда можно забронировать рабочее место и на какой срок?',
    answer: 'Рабочее место можно забронировать в любой удобный момент, даже начиная с текущего часа. ' +
      'Срок бронирования зависит от выбранного сценария. Бронирование по часам возможно только в течение одного дня. ' +
      'Бронирование по дням  доступно от одного дня на неограниченный срок. Бронирование на постоянной основе начинается с определённой даты и не имеет срока окончания. ' +
      'Места, помеченные значком «fo» являются частью концепции ' +
      'Flex Office и бронируются на срок не более 3 рабочих дней, такие места невозможно забронировать на постоянной основе.',
  },
  {
    number: 2,
    question: 'Какое рабочее место можно забронировать?',
    answer: 'Система бронирования рабочих мест позволяет забронировать любое рабочее место, без привязки пользователя к подразделениям.' +
      'В случае если Вы планируете забронировать рабочее место в комнате, привязанной к другому подразделению предварительно свяжитесь с ассистентом для согласования с руководителем подразделения. ' +
      'Когда будете располагаться на рабочем месте не забудьте представиться коллегам и сообщить на какой срок вы планируете работать с ними рядом.',
  },
  {
    number: 3,
    question: 'Не успел(а) забронировать рабочее место. Что делать?',
    answer: 'Проверьте наличие свободных рабочих мест в комнатах, где Вы планируете разместиться. Забронируйте на весь день или на период когда планируете работать из офиса. ' +
      'Если свободных рабочих мест нет, попробуйте уточнить какое рабочее место будет свободно сегодня, и обязательно свяжитесь с владельцем или его руководителем, получите согласование временно занять рабочее место. ' +
      'Также попробуйте найти свободные места в соседних комнатах или в местах коллаборации и неформального общения.',
  },
  {
    number: 4,
    question: 'Забронированное мною рабочее место занято другим сотрудником. Что делать?',
    answer: 'Каждый сотрудник, перед тем как занять рабочее место, должен забронировать его в системе.\n' +
      'Проверьте, что Вы не ошиблись при создании брони, и в системе бронирования рабочее место забронировано за Вами. Вежливо попросите сотрудника освободить забронированное вами место. ' +
      'В случае отказа настаивайте на своём праве занять забронированное место. Подключайте вашего руководителя, или ассистента.',
  },
  {
    number: 5,
    question: 'Если мои планы изменились?',
    answer: 'В случае если Вы не планируете использовать забронированное рабочее место необходимо удалить или изменить бронь.',
  },
  {
    number: 6,
    question: 'Можно ли забронировать рабочее место для  другого  сотрудника?',
    answer: 'Бронирование рабочих мест для других сотрудников доступно только ассистентам. Обратитесь к своему ассистенту или забронируйте на себя.',
  },
  {
    number: 7,
    question: 'Я нашёл ошибку или хочу предложить доработку.',
    answer: 'Для обратной связи воспользуйтесь формой справа. Команда Flex Office стремится улучшить систему и будет рада конструктивной обратной связи.',
  },
];

export const allowedFileExtensions = ['jpeg', 'JPEG', 'jpg', 'JPG', 'png', 'PNG', 'gif', 'bmp', 'heic', 'heif'];

