import { observer } from 'mobx-react';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  TableCell,
  FormControl,
  Autocomplete,
  TableRow,
  TextField,
  Box,
  FilterOptionsState,
} from '@mui/material';
import {
  ArrowDropUpRounded,
  DoneRounded,
  DoNotDisturbAltRounded,
  MoreVert,
  InfoOutlined,
} from '@mui/icons-material';
import { textInformation } from './constants';
import { string, object } from 'yup';
import { IDivision, IRole, User } from 'types/otherTypes';
import { serviceContainer } from 'services';
import { TooltipLight } from 'UI/components';
import { addUser, editUser } from '../helpers';

import './AdminTableRow.scss';

type Option = {
  divisionId: string,
  divisionName: string
};

export const AdminTableRow = observer(({
  user,
  isAdding,
}:{
  user: User,
  isAdding?: boolean,
})=>{
  const roles = serviceContainer.adminStore.allRoles || [];
  const divisions = serviceContainer.adminStore.allDivisions || [];
  const isSuperuser = serviceContainer.usersStore.currentUser?.userRole === 'superuser';
  const { disabled, ...userInfo } = user;
  const [edit, setEdit] = useState(userInfo);
  const [isEdit, setIsEdit] = useState(isAdding);
  const [validErr, setValidErr] = useState({
    userName:'',
    userRole:'',
    userId:'',
    division:'',
    email:'',
  });
  const [isExpanded, setIsExpanded] = useState(false);

  const userValidation = object({
    userName:string().required('Обязательное поле'),
    userRole:string().required('Обязательное поле'),
    userId:string().required('Обязательное поле'),
    division:string().nullable(),
    email:string()
      .email('Некорректный email-адрес')
      .required('Обязательное поле'),
  });
  const editButtonConfig = [
    {
      title:'Принять',
      clickHandler:()=>{
        const data = { ...edit, email: edit.email?.trim(), userId: edit.userId.trim(), userName: edit.userName.trim() };
        userValidation.validate(data, { abortEarly:false })
          .then((value)=>{
            serviceContainer.adminStore.setUserInfoForEdit({
              userId:value.userId,
              userName:value.userName,
              userRole:value.userRole,
              division:value.division ?? '',
              email:value.email,
              disabled:false,
            });
            if (isAdding) {
              serviceContainer.confirmationDialogStore.open({
                title:'Подтвердите добавление пользователя',
                description:`Добавить пользователя ${serviceContainer.adminStore.userInfoForEdit?.userId}?`,
                resolveTitle:'Добавить',
                rejectTitle:'Отменить',
                onFulfilled:addUser,
              });
            } else {
              serviceContainer.confirmationDialogStore.open({
                title:'Подтвердите изменения',
                description:`Сохранить изменения данных пользователя ${serviceContainer.adminStore.userInfoForEdit?.userId}?`,
                resolveTitle:'Сохранить',
                rejectTitle:'Отменить',
                onFulfilled:editUser,
              });
              setIsEdit(false);
            }
          })
          .catch(({ inner })=>{
            setValidErr(inner.reduce((errors:{
              userName:string,
              userRole:string,
              userId:string,
              division:string,
              email:string,
            }, { message, path }:{ message:string, path:string })=>
              ({ ...errors, [path]:message })
            , {
              userName:'',
              userRole:'',
              userId:'',
              division:'',
              email:'',
            }));
          });

        setValidErr({
          userName:'',
          userRole:'',
          userId:'',
          division:'',
          email:'',
        });
      },
      icon: <DoneRounded
        className='icon green'
        fontSize='small'
            />,
    },
    {
      title:'Отменить',
      clickHandler:()=>{
        if (isAdding) serviceContainer.adminStore.setIsAddingUser(false);
        else setIsEdit(false);
        setValidErr({
          userName:'',
          userRole:'',
          userId:'',
          division:'',
          email:'',
        });
      },
      icon:<DoNotDisturbAltRounded
        className='icon red'
        fontSize='small'
           />,
    },
  ];

  const controllButtons = <Stack
    direction='row'
    className={`control ${isEdit ? 'edit' : ''}`}
                          >
    {isAdding &&
      <TooltipLight
        type='informationIcon'
        title={textInformation}
      >
        <InfoOutlined className='information-icon'/>
      </TooltipLight>}
    {isEdit ?
      editButtonConfig.map(({ title, clickHandler, icon })=>
        <IconButton
          key={title}
          className='button'
          onClick={clickHandler}
          title={title}
        >
          {icon}
        </IconButton>) :
      <IconButton
        className='button'
        onClick={(e)=>{
          setEdit(userInfo);
          serviceContainer.adminStore.setDropdownUser(user);
          serviceContainer.adminStore.setDropdownAnchor(e.currentTarget);
          serviceContainer.adminStore.setIsEditHandler(()=>{
            setIsEdit(true);
            setIsExpanded(true);
          });
        }}
      >
        <MoreVert/>
      </IconButton>}
  </Stack>;

  const handleFilterOptions = (options: Option[], state: FilterOptionsState<Option>) =>
    options.filter(option =>
      (option.divisionId + option.divisionName).toLowerCase().includes(state.inputValue.toLowerCase()));

  const editField = (key:string, prop:string | null)=> {
    const divisionsValues = divisions.map(item => ({
      divisionId: item.divisionId,
      divisionName: item.divisionName,
    }));
    const rolesValues = isSuperuser
      ? roles?.filter((role) => role.id !== 'admin').map(item => item.id)
      : roles?.map(item => item.id);

    return (
      <FormControl className='admin-form'>
        <TextField
          className={`input-wrapper ${edit[key as keyof typeof edit] === prop ? '' : 'blue'}`}
          variant='standard'
          style={(isEdit && (key === 'userRole' || key === 'division')) ? { display: 'none' } : { display: 'block' }}
          inputProps={{ className: `input ${edit[key as keyof typeof edit] === prop ? '' : 'blue'}` }}
          value={edit[key as keyof typeof edit] ? edit[key as keyof typeof edit] : ''}
          onChange={(e) => {
            const value = e.target.value;
            setEdit({
              ...edit,
              [key]: value ? value : '',
            });
          }}
          error={!!validErr[key as keyof typeof validErr]}
          helperText={validErr[key as keyof typeof validErr]}
        />
        <Autocomplete
          classes={{ popper: 'divisions-select' }}
          className='divisions-select'
          options={divisionsValues}
          id='divisions-select'
          disableClearable
          getOptionLabel={(option) => option.divisionId ?? option}
          style={{ display: key === 'division' ? 'block' : 'none' }}
          // @ts-ignore
          value={edit[key as keyof typeof edit] ?? undefined}
          onChange={(e, newValue ) => {
            setEdit({
              ...edit,
              division: newValue ? newValue.divisionId : '',
            });
          }}
          filterOptions={handleFilterOptions}
          renderInput={(params) =>
            <TextField
              {...params}
              className='autocomplete-field'
              variant='standard'
              // error={!!validErr.division}
              // helperText={validErr.division}
            />}
          renderOption={(props, option) => {
            return (
              <Box
                component='li'
                {...props}
                key={option.divisionId}
              >
                <Box component='div'>
                  <Box component='p'>
                    {option.divisionId}
                  </Box>
                  <Box component='span'>
                    {option.divisionName}
                  </Box>
                </Box>
              </Box>
            );
          }}
        />
        <Autocomplete
          options={rolesValues}
          id='roles-select'
          style={{ display: key === 'userRole' ? 'block' : 'none' }}
          value={edit[key as keyof typeof edit] ? edit[key as keyof typeof edit] ?? undefined : ''}
          disableClearable
          onChange={(e, newValue ) => {
            setEdit({
              ...edit,
              userRole:newValue ? newValue : '',
            });
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              className='autocomplete-field'
              variant='standard'
            />}
          renderOption={(props, option) => {
            return (
              <Box
                component='li'
                {...props}
              >
                {option}
              </Box>
            );
          }}
        />
      </FormControl>
    );
  };

  const labels = {
    userId:'Логин',
    userRole:'Роль',
    division:'Подразделение',
    email:'email',
  };

  const isEditField = (key:string) =>
    isEdit && !(  key === 'userId' && !isAdding || key === 'userRole' && isAdding || key === 'lastUpdate');

  return <>
    <TableRow className='admin-table-row'>
      {Object.entries(userInfo).map(([key, prop])=>
        <TableCell
          className={disabled ? 'disabled' : 'table-cell'}
          key={key}
        >
          { isEditField(key) ?
            editField(key, prop) :
            <div className="value">
              {prop}
            </div>}
        </TableCell>,
      )}
      <TableCell align='right'>
        {controllButtons}
      </TableCell>
    </TableRow>
    <TableRow className='admin-table-row mobile'>
      <TableCell className='cell'>
        <Accordion
          disableGutters
          expanded={isExpanded}
          onChange={()=>{}}
          className='accordion'
        >
          <AccordionSummary className={`accordion-summary ${disabled ? 'disabled' : ''} ${isExpanded ? 'expanded' : ''}`}>
            <div
              className='accordion-button'
              onClick={()=>setIsExpanded(isEdit ? true : !isExpanded)}
            >
              <ArrowDropUpRounded className={`dropdown-arrow ${isExpanded ? 'up' : 'down'}`}/>
              {isEdit ?
                <TextField
                  className={`input-wrapper ${edit.userName === userInfo.userName ? '' : 'blue'}`}
                  variant='standard'
                  inputProps={{ className:`input ${edit.userName === userInfo.userName ? '' : 'blue'}` }}
                  value={edit.userName ? edit.userName : ''}
                  onChange={(e)=>{
                    const value = e.target.value;
                    setEdit({
                      ...edit,
                      userName:value ? value : '',
                    });
                  }}
                  error={!!validErr.userName}
                  helperText={validErr.userName}
                /> :
                <div className="value">
                  {userInfo.userName}
                </div>}
            </div>
            {controllButtons}
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            {Object.entries({
              userRole:userInfo.userRole,
              userId:userInfo.userId,
              division:userInfo.division,
              email:userInfo.email,
            }).map(([key, prop])=>
              <div
                className="field"
                key={key}
              >
                <div className='title'>{labels[key as keyof typeof labels]}</div>
                {isEditField(key) ?
                  editField(key, prop) :
                  <div className={`value ${disabled ? 'disabled' : ''}`}>
                    {prop}
                  </div>}
              </div>,
            )}
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  </>;
});
