import BasicBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { serviceContainer } from 'services';
import BreadcrumbsSelect from '../BreadcrumbsSelect/BreadcrumbsSelect';
import { GoBackIcon } from '../GoBackIcon/GoBackIcon';
import { getUrlKeys } from 'utils';
import { routes } from 'routes';
import {
  loadRegionsAndOffices,
  loadOffice,
  loadRooms,
  loadWorkspaces,
} from './helpers';

import './Breadcrumbs.scss';
import { EditOutlined } from '@mui/icons-material';

interface IBreadcrumbsData {
  path: string;
  name: string;
  type: string;
}

interface ISelectData {
  [key: string]: IBreadcrumbsData[]
}

const useStyles = makeStyles(() => ({
  selectStyles: {
    '& .MuiBreadcrumbs-ol': {
      '& li:nth-child(2n+4)': {
        marginLeft: 0,
      },
    },
  },
}));

const Breadcrumbs = observer(() => {
  const location = useLocation();
  const regionKey = serviceContainer.regionsStore.regions
    .some(region => region.id === getUrlKeys()[0]) ? getUrlKeys()[0] : '';
  const officeKey = getUrlKeys()[1];
  const workspacesKey = getUrlKeys()[2];
  const classes = useStyles();
  const currentUserId = serviceContainer.usersStore.currentUser?.userId;

  const ref = useRef(null);

  const breadcrumbsData: IBreadcrumbsData[] = [];

  const nonSelectTypes = [
    'desktop',
    'desktop_fo',
    'meetingroom',
    'locker',
    'desktop_na',
  ];

  const singlePageData = Object.values(routes).find((route) => {
    return route.path === location.pathname;
  });

  const selectData: ISelectData = {
    region: [],
    office: [],
    room: [],
  };

  if (singlePageData) {
    const { path, pageName } = singlePageData;
    const type = path.slice(1);
    breadcrumbsData.push({
      name: pageName,
      path,
      type,
    });
    nonSelectTypes.push(type);
  }

  const scrollToEnd = () => {
    if (ref?.current) {
      const el = ref.current as HTMLElement;
      el.scrollTo({ left: 1000 });
    }
  };

  useEffect(() => {
    scrollToEnd();
  });

  useEffect(() => {
    if (currentUserId) {
      setTimeout(() => {
        loadRegionsAndOffices();
        loadOffice(regionKey);
        loadRooms(officeKey);
        loadWorkspaces(workspacesKey);
      }, 300);
    }
  }, [regionKey, officeKey, workspacesKey, currentUserId]);

  serviceContainer.regionsStore.regions.forEach((region) => {
    if (region.active) {
      const regionData = {
        path: `/${region.id}/offices`,
        name: region.name,
        key: region.id,
        type: 'region',
      };
      breadcrumbsData.push(regionData);
      selectData.region.push(regionData);
    }
  });

  serviceContainer.officesStore.getOfficesNewByKey(regionKey)?.forEach((office) => {
    if (office.attributes.active || serviceContainer.editStore.isEdit) {
      const officeData = {
        path: `/${regionKey}/offices/${office.attributes.id}/rooms`,
        name: office.attributes.name,
        key: office.attributes.id,
        type: office.type,
      };
      breadcrumbsData.push(officeData);
      selectData.office.push(officeData);
    }
  });

  serviceContainer.roomsStore.rooms[officeKey]?.forEach((room) => {
    if (room.attributes.active || serviceContainer.editStore.isEdit) {
      const roomData = {
        path: `/${regionKey}/offices/${room.attributes.office.id}/rooms/${room.attributes.id}/workspaces`,
        name: room.attributes.name,
        key: room.attributes.id,
        type: room.type,
      };
      breadcrumbsData.push(roomData);
      selectData.room.push(roomData);
    }
  });

  if (serviceContainer.workspacesStore.getWorkspacesByKey(workspacesKey)) {
    serviceContainer.workspacesStore.getWorkspacesByKey(workspacesKey).forEach((workspace) => {
      const title = workspace.type === 'locker' ? 'Локер' : 'Рабочее место';
      const workspaceData = {
        path: `/${regionKey}/offices/${officeKey}/rooms/${workspace.room.id}/workspaces/${workspace.id}`,
        name: workspace.type === 'meetingroom' ? 'Переговорная' : `${title} № ${workspace.workspace_number}`,
        key: workspace.id,
        type: workspace.type,
      };
      breadcrumbsData.push(workspaceData);
    });
  }

  const links = breadcrumbsData.map((link) => {
    if (nonSelectTypes.includes(link.type)) {
      return (
        location.pathname === link.path
          ? (
            <Link
              to={link.path}
              key={link.path}
            >
              {link.name}
            </Link>
          )
          : null
      );
    }

    if (link.type === 'room'
      && location.pathname.slice(0, location.pathname.lastIndexOf('/')) === link.path
      || location.pathname === link.path) {
      return (
        <BreadcrumbsSelect
          options={selectData[link.type]}
          initial={link.path}
          key={link.path}
        />
      );
    }

    if (link.type !== 'room' && location.pathname.includes(link.path)) {
      return (
        <BreadcrumbsSelect
          options={selectData[link.type]}
          initial={link.path}
          key={link.path}
        />
      );
    }
  });

  return (
    <div
      role="presentation"
      className="breadcrumbs"
    >
      <GoBackIcon />
      <BasicBreadcrumbs
        ref={ref}
        separator="/"
        aria-label="breadcrumb"
        className={classes.selectStyles}
      >
        <Link
          to="/"
          style={{ display: 'block', height: '16px' }}
        >
          Карта
        </Link>
        {links}
      </BasicBreadcrumbs>
      <div
        className="editLabel"
        style={{ display:serviceContainer.editStore.isEdit ? 'flex' : 'none' }}
      >
        <EditOutlined/>
        <div>
          Режим редактора
        </div>
      </div>
    </div>
  );
});

export default Breadcrumbs;
