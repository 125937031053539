import { random } from 'lodash';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { Marker, TMarkerProps } from '../Marker/Marker';
import geoMap from '../../../assets/geoData/map.json';
import './Map.scss';
import { MapPosition } from 'types';
import { useState } from 'react';

type GeographiesType = {
  geometry: {
    coordinates: Array<Array<number[]>>;
    type: string;
  };
  properties: {
    HASC_1: string;
    ISO_2: string;
    NAME_1: string;
  };
  rsmKey: string;
  svgPath: string;
  type: string;
};

export type MapPropsType = {
  markers: TMarkerProps[];
  projectionConfig?: object;
  position: MapPosition;
  onMoveEnd: (pos: MapPosition) => void;
};

const isMobile = window.innerWidth < window.innerHeight;

const Map = ({
  markers,
  projectionConfig,
  position,
  onMoveEnd,
}: MapPropsType) => {
  const [drag, setDrag] = useState(false);

  return (
    <ComposableMap
      className="map"
      projection="geoAlbers"
      projectionConfig={projectionConfig}
      style={{
        cursor: drag ? 'grabbing' : 'grab',
      }}
    >
      <ZoomableGroup
        zoom={position.zoom}
        center={position.coordinates}
        onMoveStart={()=>setDrag(true)}
        onMoveEnd={(pos) => {
          setDrag(false);
          onMoveEnd(pos);
        }}
        minZoom={1}
        maxZoom={4}
        translateExtent={[[isMobile ? -10 : 0, isMobile ? 0 : -200], [1200, isMobile ? 800 : 600]]}
      >
        <Geographies
          className="country"
          geography={geoMap}
        >
          {({ geographies }) => {
            return (geographies as GeographiesType[]).map((geo) => {
              const marker = markers.find((m) => {
                return m.iso === geo.properties.ISO_2;
              });
              const hoverCls = marker?.isActive ? 'active' : '';
              return (
                <Geography
                  className={`region ${hoverCls}`}
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => {
                    if (marker?.isActive) marker.clickHandler(marker.iso);
                  }}
                  style={{
                    default: {
                      animationDelay: `${random(0, 5)}s`,
                    },
                  }}
                />
              );
            });
          }}
        </Geographies>
        {markers.map((marker) => (
          <Marker
            key={marker.iso}
            iso={marker.iso}
            coords={marker.coords}
            title={marker.title}
            color={marker.color}
            isActive={marker.isActive}
            clickHandler={marker.clickHandler}
          />
        ))}
      </ZoomableGroup>
    </ComposableMap>
  );
};
export { Map };
