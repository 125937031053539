import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactQuill from 'react-quill';
import { TagButton } from '../TagButton/TagButton';
import { TagMenu } from '../TagMenu/TagMenu';

import 'react-quill/dist/quill.snow.css';
import './TextEditor.scss';

type OwnProps = {
  tagBtnClicked: boolean;
  setTagBtnClicked: Dispatch<SetStateAction<boolean>>;
  richText: string;
  handleChangeText: (v: string) => void;
  isDistribution?: boolean;
};

export const TextEditor = (props: OwnProps) => {
  const {
    tagBtnClicked,
    setTagBtnClicked,
    richText,
    handleChangeText,
    isDistribution,
  } = props;
  const [menuPosition, setMenuPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [showTagMenu, setShowTagMenu] = useState<boolean>(false);

  const quillRef = useRef<ReactQuill | null>(null);
  const menuAnchorText = useRef(null);
  const menuAnchorBtn = useRef(null);
  const editor = quillRef.current?.getEditor();
  const toolbarContainer = editor?.getModule('toolbar').container;
  const editingArea = quillRef.current?.editingArea as Element;
  const cursorPosition = editor?.getSelection()?.index || 0;

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === '#') {
      const bounds = editor?.getBounds(cursorPosition);
      setMenuPosition({ x: bounds?.left || 0, y: bounds?.top || 0 });
      setShowTagMenu(true);
    }
  };

  const handleAddTag = (tagValue: string) => {
    if (editor && typeof cursorPosition === 'number') {
      editor.insertText(cursorPosition, tagValue);
      editor.setSelection({ index: cursorPosition + tagValue.length, length: 0 });
    }
    setShowTagMenu(false);
  };

  const handleClickTagBtn = () => {
    setTagBtnClicked(true);
    setShowTagMenu(true);
  };

  return (
    <>
      <ReactQuill
        ref={quillRef}
        className='rich-text-editor'
        theme="snow"
        value={richText}
        readOnly={isDistribution}
        onChange={handleChangeText}
        onKeyPress={handleKeyPress}
        modules={{
          toolbar:[
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['image', 'video'],

            ['clean'],                                         // remove formatting button
          ],
        }}
      />
      {toolbarContainer && createPortal(
        <TagButton
          name="#"
          onClick={handleClickTagBtn}
          ref={menuAnchorBtn}
        />, toolbarContainer)}
      {editingArea && createPortal(
        <div
          className="tagMenuAnchor"
          style={{
            top: menuPosition.y,
            left: menuPosition.x,
          }}
          ref={menuAnchorText}
        />, editingArea)}
      <TagMenu
        open={showTagMenu}
        onBackdropClick={() => setShowTagMenu(false)}
        anchorEl={tagBtnClicked
          ? menuAnchorBtn.current
          : menuAnchorText.current}
        handleAddTag={handleAddTag}
      />
    </>
  );
};
