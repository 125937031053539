import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { Position, Status } from 'types/commonTypes';
import Chair from 'assets/svg/decor/chairs/chair_free.svg';
import ChairOccupied from 'assets/svg/decor/chairs/chair_occupied.svg';
import ChairReserved from 'assets/svg/decor/chairs/chair_reserved.svg';
import ChairUnavailable from 'assets/svg/decor/chairs/chair_unavailable.svg';
import { ReactComponent as OcupiedTableTexture } from 'assets/svg/decor/tables/occupied_table_texture.svg';
import { ReactComponent as ReservedTableTexture } from 'assets/svg/decor/tables/reserved_table_texture.svg';
import {  OUTLINE_THICKNESS } from 'utils';
import { serviceContainer } from 'services';
import { Draggable } from 'UI/components';
import { FoLogoGreen } from 'assets';
import { RoomTooltip } from '../../RoomTooltip/RoomTooltip';

import './Workspace.scss';

interface WorkspaceProps {
  number:number;
  position:Position;
  rotation:number;
  isFlexible:boolean;
  isDesktopNa: boolean,
  isSelected: boolean;
  status: Status;
  width: number;
  reservator?: string;
  reservatorIcon?: string | null;
  note?: string | null;
  divisionAcl?: string[];
  userAcl?: string[];
}

export const Workspace = observer(({
  number,
  position,
  rotation,
  isFlexible,
  isDesktopNa,
  isSelected,
  status,
  width,
  reservator,
  reservatorIcon,
  note,
  divisionAcl,
  userAcl,
}: WorkspaceProps) => {

  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const history = useHistory();
  const { roomId } = useParams<{ roomId: string, officeId: string }>();

  const truncatedRotation = Math.trunc(((isSelected ? serviceContainer.roomStore.currentRotation : rotation) - 1) / 90 + 1) * 90;
  const numberPadding = `${2 / serviceContainer.roomStore.scale}px`;

  const currentWidth = 60 + ((isSelected ? serviceContainer.roomStore.currentWidth : width) - 1) * 6;

  const hasUserIcon = Object.keys(serviceContainer.usersStore.userIcon).includes(reservatorIcon ?? '');

  const isMagnetTrigger = serviceContainer.roomStore.checkIsMagnetTrigger(number);

  const onClick = () => {
    if (serviceContainer.roomStore.isEdit || isDesktopNa) return;
    history.push(`${history.location.pathname}/${roomId}.${number}`);
  };

  useEffect(() => {
    if (reservatorIcon && !hasUserIcon && isTooltipOpen) {
      serviceContainer.usersService.getBlobImageByUrl(reservatorIcon).then((data) => {
        serviceContainer.usersStore.setUserIcon(data, reservatorIcon);
      });
    }
  }, [reservatorIcon, hasUserIcon, isTooltipOpen]);

  const styleStickerFo = {
    top: [50, 100].includes(truncatedRotation) ? numberPadding : undefined,
    right: [0, 90].includes(truncatedRotation) ? numberPadding : undefined,
    bottom: [0, 270].includes(truncatedRotation) ? numberPadding : undefined,
    left: [180, 270].includes(truncatedRotation) ? numberPadding : undefined,
    transform:`
      rotate(${isSelected ? -serviceContainer.roomStore.currentRotation : -rotation}deg)
      scale(${1 / serviceContainer.roomStore.scale})
    `,
    margin: 2 - 2.5 * serviceContainer.roomStore.scale,
  };

  const logoStickerFoComponent = (
    <>
      {isFlexible && !serviceContainer.roomStore.isEdit && reservator && (
        <div
          className='icon-wrap'
          style={styleStickerFo}
        >
          <FoLogoGreen className='icon'/>
        </div>
      )}
    </>
  ); 

  return (
    <Draggable
      number={number}
      position={position}
      rotation={rotation}
      isSelected={isSelected}
      note={note}
      divisionAcl={divisionAcl}
      width={width}
      userAcl={userAcl}
      snap
      onKeyUp={(key:string) => {
        if (key.toLowerCase() === 'f') serviceContainer.roomStore.toogleCurrentType();
        if (
          serviceContainer.roomStore.isShift &&
          key === 'ArrowLeft' &&
          serviceContainer.roomStore.currentWidth > 1
        ) {
          serviceContainer.roomStore.setCurrentWidth(serviceContainer.roomStore.currentWidth - 1);
        }
        if (
          serviceContainer.roomStore.isShift &&
          key === 'ArrowRight' &&
          serviceContainer.roomStore.currentWidth < 4
        ) {
          serviceContainer.roomStore.setCurrentWidth(serviceContainer.roomStore.currentWidth + 1);
        }
      }}
    >
      <RoomTooltip
        label='Рабочее место'
        id={number}
        status={status}
        isFlexible={isFlexible}
        reservator={reservator}
        reservatorIcon={reservatorIcon}
        note={note}
        disabled={serviceContainer.roomStore.isEdit || isDesktopNa}
        onOpen={()=>setTooltipOpen(true)}
        onClose={()=>setTooltipOpen(false)}
        divisionAcl={divisionAcl}
      >
        <div
          className='workspace'
          style={{
            width: `${30}px`,
            height: `${currentWidth}px`,
            cursor: serviceContainer.roomStore.isEdit ? undefined : 'pointer',
          }}
          onClick={onClick}
        >
          <img
            className='chair'
            src={{
              free: (isSelected ? serviceContainer.roomStore.isDesktopNa : isDesktopNa) ? ChairUnavailable : Chair,
              occupied: (isSelected ? serviceContainer.roomStore.isDesktopNa : isDesktopNa) ? ChairUnavailable : ChairOccupied,
              reserved: (isSelected ? serviceContainer.roomStore.isDesktopNa : isDesktopNa) ? ChairUnavailable : ChairReserved,
            }[serviceContainer.roomStore.isEdit ? 'free' : status]}
            draggable='false'
          />
          <div
            className='table-texture'
            style={{
              outline: isSelected
                ? `${OUTLINE_THICKNESS / serviceContainer.roomStore.scale}px solid #19A0D8`
                : isMagnetTrigger ? `${OUTLINE_THICKNESS / serviceContainer.roomStore.scale}px solid #f83021` : '',
              outlineOffset: `${-OUTLINE_THICKNESS / serviceContainer.roomStore.scale}px`,
            }}
          >
            {{
              free: null,
              occupied: (isSelected ? serviceContainer.roomStore.isDesktopNa : isDesktopNa) ? null : <OcupiedTableTexture />,
              reserved: (isSelected ? serviceContainer.roomStore.isDesktopNa : isDesktopNa) ? null : <ReservedTableTexture />,
            }[serviceContainer.roomStore.isEdit ? 'free' : status]}
          </div>
          {(isSelected ? serviceContainer.roomStore.isCurrentFlexible : isFlexible) &&
            (!reservator || serviceContainer.roomStore.isEdit) &&
              <FoLogoGreen
                className="workspace-logo"
                style={{ transform: `translate(-50%,-50%) rotate(${isSelected ? -serviceContainer.roomStore.currentRotation : -rotation}deg)` }}
              />}
          <div
            className='number'
            style={{
              top:[0, 270].includes(truncatedRotation) ? numberPadding : undefined,
              right:[180, 270].includes(truncatedRotation) ? numberPadding : undefined,
              bottom:[90, 180].includes(truncatedRotation) ? numberPadding : undefined,
              left:[0, 90].includes(truncatedRotation) ? numberPadding : undefined,
              transform:`
                rotate(${isSelected ? -serviceContainer.roomStore.currentRotation : -rotation}deg)
                scale(${1 / serviceContainer.roomStore.scale})
              `,
              margin: 2 - 2.5 * serviceContainer.roomStore.scale,
            }}
          >
            {number}
          </div>
          {logoStickerFoComponent}
        </div>
      </RoomTooltip>
    </Draggable>
  );
});
