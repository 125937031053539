import { 
  ChangeEvent,
  MouseEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { IRowData } from '../../../types/otherTypes';
import { serviceContainer } from 'services';

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof IRowData;
  label: string;
}

interface IBookingTableProps {
  rows: IRowData[],
  tabsValue: string,
  order:Order,
  orderBy:keyof IRowData,
  handleRequestSort:(event: MouseEvent<unknown>, property: keyof IRowData) => void,
}

interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IRowData) => void;
  onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tabsValue: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends number | string>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: readonly HeadCell[] = [
  {
    id: 'userName',
    disablePadding: true,
    label: 'ФИО',
  },
  {
    id: 'dateStart',
    disablePadding: false,
    label: 'Начало',
  },
  {
    id: 'dateEnd',
    disablePadding: false,
    label: 'Конец',
  },
  {
    id: 'regionName',
    disablePadding: false,
    label: 'Город',
  },
  {
    id: 'officeName',
    disablePadding: false,
    label: 'Офис',
  },
  {
    id: 'roomName',
    disablePadding: false,
    label: 'Кабинет',
  },
  {
    id: 'desktop',
    disablePadding: false,
    label: 'Рабочее место',
  },
  {
    id: 'locker',
    disablePadding: false,
    label: 'Локер',
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { 
    order, 
    orderBy, 
    onRequestSort, 
    tabsValue,
  } = props;

  const createSortHandler = (property: keyof IRowData) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const filterableByTabs = ['desktop', 'locker'];

  return (
    <TableHead>
      <TableRow className={`booking_row header ${tabsValue === 'desktop' ? 'seven-rows' : ''}`}>
        {headCells.map((headCell) => {
          if (
            (tabsValue === 'meetingroom' && filterableByTabs.includes(headCell.id))
            || (filterableByTabs.includes(headCell.id) && tabsValue !== headCell.id)
          ) return;
          const tableCellStyles = {
            width: 'auto',
          };
          switch (headCell.id) {
            case 'userName':
              tableCellStyles.width = '24%';
              break;
            case 'dateStart':
              tableCellStyles.width = '12%';
              break;
            case 'dateEnd':
              tableCellStyles.width = '12%';
              break;
            case 'regionName':
              tableCellStyles.width = '12%';
              break;
            case 'officeName':
              tableCellStyles.width = '19%';
              break;
            case 'roomName':
              tableCellStyles.width = '4%';
              break;
          }
          return (
            <TableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={tableCellStyles}
              className={headCell.id}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className='table-sort-label'
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box
                    component='span'
                    sx={visuallyHidden}
                  >
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default function BookingTable(props: IBookingTableProps) {
  // const [page, setPage] = useState(0); // pagination
  // const [rowsPerPage, setRowsPerPage] = useState(10); // pagination
  const history = useHistory();
  const { 
    rows, 
    tabsValue,
    order,
    orderBy,
    handleRequestSort,
  } = props;

  // const handleChangePage = (event: unknown, newPage: number) => { // pagination
  //   setPage(newPage);
  // };
  //
  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => { // pagination
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0; // pagination

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        className={`booking_table_wrapper 
      ${['user', 'viewer'].includes(serviceContainer.usersStore.currentUser?.userRole
          ? serviceContainer.usersStore.currentUser?.userRole : '') ? 'tall' : ''}`}
      >
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby='tableTitle'
          className='booking_table'
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            tabsValue={tabsValue}
          />
          <TableBody className='booking_table_body'>
            {stableSort(rows, getComparator(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //  pagination
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const handleClick = (rowItem: IRowData) => {
                  const { officeId, regionId, reservationId, roomId, workspaceId } = rowItem;
                  history.push(`/${regionId}/offices/${officeId}/rooms/${roomId}/workspaces/${workspaceId}?id=${reservationId}`);
                };
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(row)}
                    role='checkbox'
                    tabIndex={-1}
                    key={row.reservationId}
                    className={`booking_row ${tabsValue === 'desktop' ? 'seven-rows' : ''}`}
                  >
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='none'
                      className='userName'
                    >
                      {row.userName}
                    </TableCell>
                    <TableCell
                      align='left'
                      className='dateStart'
                    >{row.dateStartFormatted}</TableCell>
                    <TableCell
                      align='left'
                      className='dateEnd'
                    >{row.dateEndFormatted}</TableCell>
                    <TableCell
                      align='left'
                      className='regionName'
                    >{row.regionName}</TableCell>
                    <TableCell
                      align='left'
                      className='officeName'
                    >{row.officeName}</TableCell>
                    <TableCell
                      align='left'
                      className='roomName'
                    >{row.roomName}</TableCell>
                    {tabsValue !== 'meetingroom' ? 
                      <TableCell
                        align='left'
                        className='workspaceName'
                      >{row.workspaceName}</TableCell> : null}
                  </TableRow>
                );
              })}
            {/*{emptyRows > 0 && (*/}
            {/*  <TableRow*/}
            {/*    style={{}}*/}
            {/*  >*/}
            {/*    <TableCell colSpan={6} />*/}
            {/*  </TableRow>*/}
            {/*)}*/}
          </TableBody>
        </Table>
      </TableContainer>
      {/*<TablePagination*/}
      {/*  rowsPerPageOptions={[5, 10, 25]}*/}
      {/*  component='div'*/}
      {/*  count={rows.length}*/}
      {/*  rowsPerPage={rowsPerPage}*/}
      {/*  page={page}*/}
      {/*  onPageChange={handleChangePage}*/}
      {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
      {/*/>*/}
    </Box>
  );
}
