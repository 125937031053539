import { IDomain } from '../../types/otherTypes';
import { TDomainTableInternal } from '../../pages/AdministrationPage/DomainsTable/DomainsTable.type';

export const toInternalData = (data: IDomain[]): TDomainTableInternal[] => {
  return data.map(item => {
    return {
      domainName: item.domainName,
    };
  });
};
