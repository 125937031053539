import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Status } from 'types/commonTypes';
import { ControlPanel, PageLoader } from 'UI/components';
import { serviceContainer } from 'services';
import { Door } from './Door/Door';

import './Locker.scss';

export const Locker = observer(() => {

  const { roomId } = useParams<{ roomId: string, officeId: string }>();
  const workspaces = serviceContainer.workspacesStore.getWorkspacesByKey(roomId);

  const dependenciesEditChanges = [
    serviceContainer.roomStore.columns,
    serviceContainer.roomStore.rows,
    serviceContainer.roomStore.initRows,
    serviceContainer.roomStore.initColumns,
  ];

  useEffect(() => {
    window.onbeforeunload = () => {
      if (serviceContainer.roomStore.isEditChangesDraggble) {
        return '';
      }
    };
  }, [serviceContainer.roomStore.isEditChangesDraggble]);

  useEffect(() => {
    if (serviceContainer.editStore.isEdit) {
      if (serviceContainer.roomStore.initRows === serviceContainer.roomStore.rows &&
        serviceContainer.roomStore.initColumns === serviceContainer.roomStore.columns) {
        serviceContainer.roomStore.setIsEditChangesDraggble(false);
      } else {
        serviceContainer.roomStore.setIsEditChangesDraggble(true);
      }
    } else {
      serviceContainer.roomStore.setIsEditChangesDraggble(false);
    }
  }, dependenciesEditChanges);

  useEffect(() => {
    serviceContainer.roomStore.reset();
    if (!workspaces) return;
    let [columns, rows] = [0, 0];
    const draggables = workspaces
      .map(({ attributes: { x, y, rotate, activeReservation }, workspace_number, status, division_acl, user_acl }) => {

        columns = Math.max(+x + 1, columns);
        rows = Math.max(+y + 1, rows);

        return {
          number: workspace_number,
          position: { x: +x, y: +y },
          rotation: +rotate,
          isChanged: false,
          isNew: false,
          status: status as Status,
          reservator: activeReservation?.data?.attributes?.fname?.userName,
          reservatorIcon: activeReservation?.data?.attributes?.fname?.img,
          division_acl,
          user_acl,
        };
      }).sort((a, b) => a.number - b.number);
    serviceContainer.roomStore.setDraggables(draggables);
    serviceContainer.roomStore.setInitDraggables(draggables);
    serviceContainer.roomStore.setInitColumns(columns);
    serviceContainer.roomStore.setInitRows(rows);
    serviceContainer.roomStore.setColumns(columns);
    serviceContainer.roomStore.setRows(rows);
  }, [roomId, workspaces]);

  return (
    <div className='locker-container'>
      <div className='locker'>
        {new Array(serviceContainer.roomStore.rows)
          .fill(null)
          .map((_row, i) =>
            <div
              key={i}
              className='row'
            >
              {
                new Array(serviceContainer.roomStore.columns)
                  .fill(null)
                  .map((_, j) => workspaces === undefined ? <PageLoader /> :
                  <Door
                    key={i * serviceContainer.roomStore.columns + j + 1}
                    number={i * serviceContainer.roomStore.columns + j + 1}
                  />,
                  )
                }
            </div>,
          )}
      </div>
      <ControlPanel roomVariant='locker'/>
    </div>
  );
});
