import { serviceContainer } from './';
import Cookies from 'js-cookie';

export class LoginService {

  async logOut() {
    serviceContainer.keycloakService.keycloak.logout();
    Cookies.remove(serviceContainer.apiService.tokenName);
    serviceContainer.loginStore.token = undefined;
  }
}
