import { useState } from 'react';
import { serviceContainer } from 'services';
import { TextField, Typography } from '@mui/material';
import FilePreview from './FilePreview';
import AttachButton from './AttachButton';
import { LoadingButton } from '@mui/lab';

export const FeedbackForm = () => {
  const [attachedFile, setAttachedFile] = useState<File[] | undefined>();
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [loadingSend, setLoadingSend] = useState<boolean>(false);

  const sendHandler = () => {
    setLoadingSend(true);
    const formData = new FormData();

    if (attachedFile) {
      for (const file of attachedFile) {
        formData.append('files', file);
      }
    }
    formData.append('userId', serviceContainer.usersStore.currentUser!.userId);
    formData.append('text', textFieldValue);
    
    serviceContainer.apiService.sendMessageToSupport(formData)
      .then(res => {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: res.data as string,
          severity: 'success',
        });
      })
      .catch(() => {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: 'Что-то пошло не так...',
          severity: 'error',
        });
      })
      .finally(() => setLoadingSend(false));
    setAttachedFile(undefined);
    setTextFieldValue('');
  };
  
  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 255) {
      setTextFieldValue(e.target.value);
    }
  };

  return (
    <div className='help-wrapper-textus box'>
      <Typography className='title'>
        Нужна помощь? Напиши нам!
      </Typography>
      <TextField
        value={textFieldValue}
        className='help-wrapper-textfield'
        id='filled-multiline-static'
        label='Максимальное количество символов в сообщении 255'
        multiline
        rows={5}
        variant='filled'
        onChange={handleChangeTextField}
      />
      <div className='help-wrapper-group'>
        {attachedFile
          ? <FilePreview
              attachedFile={attachedFile}
              setAttachedFile={setAttachedFile}
            />
          : <AttachButton
              setAttachedFile={setAttachedFile}
            />}
      </div>
      <LoadingButton
        className='button-send'
        variant='contained'
        onClick={sendHandler}
        loading={loadingSend}
        disabled={!attachedFile && !textFieldValue && !loadingSend}
      >
        {!loadingSend && 'Отправить'}
      </LoadingButton>
    </div>
  );
};