import { serviceContainer } from '../ServiceContainer';
import { toInternalData, toInternalOffice, toInternalRegionsOption } from './helpers';
import { TOfficesTableInternal } from '../../pages/AdministrationPage/OfficesTable/OfficesTable.type';

export class OfficesService {

  async loadOfficeByRegionId(regionId: string, force?: boolean) {
    if (!force && Object.keys(serviceContainer.officesStore.newOffices).includes(regionId)) return;
    const r = await serviceContainer.apiService.getOfficeByRegionId(regionId);
    serviceContainer.officesStore.setNewOffices(regionId, r.data.data);
    serviceContainer.officesStore.isRequestError = false;
  }

  async loadAllOffices() {
    try {
      serviceContainer.officesStore.loadingOffices = true;
      const r = await serviceContainer.apiService.getAllOffices();
      const regionsOption = toInternalRegionsOption(r.data.data);
      serviceContainer.officesStore.setRegionsOption(regionsOption);
      serviceContainer.officesStore.setOfficesTable(toInternalData(r.data.data));
      serviceContainer.officesStore.isRequestError = false;
    } finally {
      serviceContainer.officesStore.loadingOffices = false;
    }
  }

  async addOffice(params: TOfficesTableInternal) {
    const office = toInternalOffice(params);
    await serviceContainer.apiService.createOffice(office).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлен ${params.officeName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.officesService.loadAllOffices();
  }

  async updateOffice(params: TOfficesTableInternal) {
    const office = toInternalOffice(params);
    await serviceContainer.apiService.updateOffice(params.officeId, office).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлен ${params.officeName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.officesService.loadAllOffices();
  }

  async deleteOffice(officeId: string) {
    await serviceContainer.apiService.deleteOffice(officeId).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно удален ${serviceContainer.officesStore.officeInfoForEdit?.officeName}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.officesService.loadAllOffices();
  }
}
