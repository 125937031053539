import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import { serviceContainer } from 'services';
import { ModalCustom } from '..';
import { ReservationAccessUsers } from './ReservationAccessUsers';
import { User } from 'types';

import './ReservationAccessModal.scss';

interface ReservatinAccessModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  setDivisionAcl: (divisionAcl:string[]) => void;
  getDivisionAcl: () => string[] | undefined;
  setUserAcl: (userAcl: string[]) => void;
  getUserAcl: () => string[] | undefined;
}

export const ReservationAccessModal = observer(({
  open,
  onClose,
  title,
  setDivisionAcl,
  getDivisionAcl,
  setUserAcl,
  getUserAcl,
}: ReservatinAccessModalProps) => {
  const { users } = serviceContainer.usersStore;

  const [divisionsWithAccess, setDivisionsWithAccess] = useState<string[]>([]);
  const [isPopperOpen, setPopperOpen] = useState(false);
  const [chooseUsers, setChooseUsers] = useState<User[] | []>([]);
  const [usersUserId, setUsersUserId] = useState<string[]>([]);

  useEffect(() => {
    serviceContainer.adminService.getUsers();
  }, []);

  const usersAclArray = (getUserAcl() || []).reduce((acc: User[], item: string) => {
    const val = users?.find(el => el.userId === item);
    return val ? [...acc, val] : acc;
  }, []);

  useEffect(() => {
    if (open) {
      setDivisionsWithAccess(getDivisionAcl() || []);
      setChooseUsers(usersAclArray);
    }
  }, [open]);

  useEffect(() => {
    setUsersUserId(chooseUsers.map((item) => item?.userId || '').filter(Boolean));
  }, [chooseUsers]);
  
  return (
    <ModalCustom
      title={title}
      open={open}
      onClose={onClose}
      onSave={() => { 
        setDivisionAcl(divisionsWithAccess);
        setUserAcl(usersUserId);
        onClose();
      }}
      saveLabel='Применить'
    >
      <Autocomplete
        multiple
        disableCloseOnSelect
        style={{ paddingTop: 8 }}
        limitTags={3}
        open={isPopperOpen}
        onOpen={()=>setPopperOpen(true)}
        onClose={()=>setPopperOpen(false)}
        options={serviceContainer.divisionsStore.divisions
          .sort((a, b) => {
            const aSelected = (getDivisionAcl() || []).includes(a.divisionId);
            const bSelected = (getDivisionAcl() || []).includes(b.divisionId);
            return aSelected === bSelected ? a.divisionId.localeCompare(b.divisionId) : aSelected ? -1 : 1;
          })
          .map(({ divisionId }) => divisionId)}
        renderOption={(props, divisionId) => (
          <li
            {...props}
            style={{ paddingInline: 0 }}
          >
            <Checkbox
              size='small'
              checked={divisionsWithAccess.indexOf(divisionId) > -1}
            />
            <div key={divisionId}>
              <div
                className='divisionId'
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 14,
                }}
              >
                {divisionId}
              </div>
              <div
                className='divisionName'
                style={{
                  color: 'grey',
                  fontSize: 12,
                }}
              >
                {serviceContainer.divisionsStore.divisions.find((division) => division.divisionId === divisionId)?.divisionName}
              </div>
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Практики с доступом"
            placeholder="Поиск..."
          />
        )}
        componentsProps={{
          popper: {
            placement: 'bottom',
          },
        }}
        value={divisionsWithAccess}
        onChange={(_, value) => setDivisionsWithAccess(typeof value === 'string' ? [value] : value)}
        renderTags={(value, getTagProps) =>
          isPopperOpen ?
            <div
              className="d-flex"
              style={{ overflow: 'auto', maxHeight: 60 }}
            >
              {value.map((option, index) => (
                <Chip
                  label={
                  option.length > 10
                    ? option.substring(0, 7) +
                    '...'
                    : option
                }
                  size="small"
                  {...getTagProps({ index })}
                />
              ))}
            </div> :
            value.map((option, index) => (
              <Chip
                label={
                  option.length > 10
                    ? option.substring(0, 7) +
                    '...'
                    : option
                }
                size="small"
                {...getTagProps({ index })}
              />
            ))}
      />
      <ReservationAccessUsers
        setChooseUsers={setChooseUsers}
        chooseUsers={chooseUsers}
        getUserAcl={getUserAcl}
        users={users}
      />
    </ModalCustom>);
});