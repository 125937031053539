export interface Data {
  newsletter_id: number;
  status: {
    label: string,
    color: string,
  };
  subject: string;
  schedule: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

export const STATUS = {
  SEND_PENDING: {
    label: 'Ожидает отправки',
    color: '#FF9300',
  },
  CANCELED: {
    label: 'Отменено',
    color: '#168FF7',
  },
  SENT: {
    label: 'Отправлено',
    color: '#0AAF52',
  },
  SEND_FAILED: {
    label: 'Ошибка',
    color: '#F83021',
  },
  DRAFT: {
    label: 'Черновик',
    color: '#a3a4a6',
  },
};

export const headCells: readonly HeadCell[] = [
  { 
    id: 'newsletter_id',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: false,
    label: 'Тема',
  },
  {
    id: 'schedule',
    numeric: false,
    disablePadding: false,
    label: 'Дата и время отправки',
  },
];
