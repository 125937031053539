import { IDepartment } from '../../types/otherTypes';
import { TDepartmentTableInternal } from '../../pages/AdministrationPage/DepartmentsTable/DepartmentsTable.type';

export const toInternalData = (data: IDepartment[]): TDepartmentTableInternal[] => {
  return data.map(item => {
    return {
      departmentId: item.departmentId,
      departmentName: item.departmentName,
    };
  });
};
