import { makeAutoObservable, reaction } from 'mobx';
import { ChangeEvent } from 'react';
import { serviceContainer } from 'services';
import { DraggableType, Position, RoomVariant } from 'types/commonTypes';
import { LOCKER_MAX, LOCKER_MIN, MAX_SCALE, MIN_SCALE } from 'utils';

export class RoomStore {
  private _initDraggables: DraggableType[] = [];
  private _draggables: DraggableType[] = [];
  private _initScale = 1;
  private _scale = 1;
  private _currentPosition:Position = { x:0, y:0 };
  private _currentRotation = 0;
  private _currentWidth = 1;
  private _isCurrentFlexible = false;
  private _isCurrentChanged = false;
  private _isCtrl = false;
  private _isShift = false;
  private _selected:number | null = null;
  private _isEdit = false;
  private _officeSvg: File | null = null;
  private _officeSvgUrl: string = '';
  private _columns = 3;
  private _rows = 3;
  private _initColumns = 3;
  private _initRows = 3;
  private _scaleCoef = 1;
  private _backgroundSize = { width: 0, height: 0 };
  private _initBackgroundSize = { width: 0, height: 0 };
  private _isBackgroundLoading = false;
  private _isSnap = true;
  private _confirmDelete = false;
  private _magnetTriggers: { x: number[], y: number[] } = { x: [], y: [] };
  private _isEditChangesDraggble = false;
  private _isDesktopNa = false;
  private _isDesktop = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this._selected,
      () => this._confirmDelete = false,
    );
  }

  reset() {
    this._initDraggables = [];
    this._draggables = [];
    this._initScale = 1;
    this._scale = 1;
    this._currentPosition = { x:0, y:0 };
    this._currentRotation = 0;
    this._currentWidth = 1;
    this._isCurrentFlexible = false;
    this._isCurrentChanged = false;
    this._isCtrl = false;
    this._isShift = false;
    this._selected = null;
    this._isEdit = false;
    this._officeSvg = null;
    this._officeSvgUrl = '';
    this._columns = 3;
    this._rows = 3;
    this._initColumns = 3;
    this._initRows = 3;
    this._scaleCoef = 1;
    this._backgroundSize = { width:0, height:0 };
    this._initBackgroundSize = { width: 0, height: 0 };
    this._isBackgroundLoading = false;
    this._isSnap = true;
    this._isEditChangesDraggble = false;
    this._isDesktopNa = false;
    this._isDesktop = false;
  }

  exitEditMode(onSuccess?:()=>void, locker?: boolean) {
    if (this._isEditChangesDraggble) {
      serviceContainer.confirmationDialogStore.open({
        title:'Подтвердите выход',
        description:'Выйти из режима редактирования? Все несохраненные изменения будут потеряны.',
        resolveTitle:'Выйти',
        rejectTitle:'Отменить',
        onFulfilled:()=>{
          this._isEdit = false;
          this._isEditChangesDraggble = false;
          if (locker) {
            this._columns = this._initColumns;
            this._rows = this._initRows;
          } else {
            this._draggables = this._initDraggables;
            this._scale = this._initScale;
          }
          this._selected = null;
          if (onSuccess) onSuccess();
        },
      });
    } else {
      this._isEditChangesDraggble = false;
      this._isEdit = false;
      if (locker) {
        this._columns = this._initColumns;
        this._rows = this._initRows;
      } else {
        this._draggables = this._initDraggables;
        this._scale = this._initScale;
      }
      this._selected = null;
      if (onSuccess) onSuccess();
    }
  }

  get initDraggables() {
    return this._initDraggables;
  }

  get draggables() {
    return this._draggables;
  }
  setDraggables(value:DraggableType[]) {
    this._draggables = value;
  }
  setInitDraggables(value:DraggableType[]) {
    this._initDraggables = value;
  }
  resetDraggables() {
    this._draggables = this._draggables.map((draggable)=>({ ...draggable, isChanged: false }));
    this._initDraggables = this._draggables;
  }
  addDraggable() {
    const lastSequenced = this._draggables[0]?.number !== 1 ?
      undefined :
      this._draggables
        .find(({ number }, i) => {
          const next = this._draggables[i + 1];
          return next && next.number !== number + 1;
        });

    const draggable:DraggableType = {
      number: this._draggables[0]?.number !== 1 ?
        1 :
        this._draggables.length ?
          lastSequenced ?
            lastSequenced.number + 1 :
            [...this._draggables].reverse()[0].number + 1 :
          1,
      position: {
        x: 0,
        y: 0,
      },
      rotation: 0,
      isFlexible: false,
      isDesktop: false,
      isDesktopNa: false,
      width: 1,
      isChanged: true,
      status: 'free',
      reservator: undefined,
      reservatorIcon: undefined,
    };
    this.updateDraggables();
    this._draggables = [...this._draggables, draggable].sort((a, b) => a.number - b.number);
    this.selectDraggable(draggable);
  }
  selectDraggable(draggable:DraggableType) {
    this._selected = draggable.number;
    this._currentPosition = draggable.position;
    this._currentRotation = draggable.rotation;
    this._isCurrentFlexible = !!draggable.isFlexible;
    this._isDesktopNa = !!draggable.isDesktopNa;
    this._isDesktop = !!draggable.isDesktop;
    this._currentWidth = draggable.width || 1;
    this._isCurrentChanged = draggable.isChanged;
  }
  updateDraggables() {
    this._draggables = this._draggables.map((draggable)=>this._selected === draggable.number ? {
      ...draggable,
      position:this._currentPosition,
      rotation:this._currentRotation,
      isFlexible: this._isCurrentFlexible,
      isDesktopNa: this._isDesktopNa,
      isDesktop: this._isDesktop,
      isChanged: this._isCurrentChanged,
      width: this._currentWidth,
    } : draggable);
  }
  deleteDraggable() {
    if (this._selected) {
      this._draggables = this._draggables.filter(({ number }) => number !== this._selected);
      this.selectDraggable([...this._draggables].reverse()[0]);
    }
  }
  deleteAllDraggables() {
    serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите удаление',
      description:'Удалить все рабочие места?',
      resolveTitle:'Удалить',
      rejectTitle:'Отменить',
      onFulfilled:()=>{
        this._draggables = [];
        this._selected = null;
      },
    });
  }

  getNote() {
    return this._draggables.find(({ number })=>this._selected === number)?.note;
  }
  getInitDivisionAcl() {
    return this._initDraggables.find(({ number })=>this._selected === number)?.division_acl;
  }
  getInitUserAcl() {
    return this._initDraggables.find(({ number })=>this._selected === number)?.user_acl;
  }
  getInitNote() {
    return this._initDraggables.find(({ number })=>this._selected === number)?.note;
  }

  setNote(note: string) {
    this._draggables = this._draggables.map((draggable)=>this._selected === draggable.number ? {
      ...draggable,
      note,
    } : draggable);
  }

  setDivisionAcl(divisionAcl: string[]) {
    this._draggables = this._draggables.map((draggable)=>this._selected === draggable.number ? {
      ...draggable,
      division_acl: divisionAcl.length ? divisionAcl : undefined,
    } : draggable);
  }

  getDivisionAcl() {
    return this._draggables.find(({ number })=>this._selected === number)?.division_acl;
  }

  setUserAcl(userAcl: string[]) {
    this._draggables = this._draggables.map((draggable)=>this._selected === draggable.number ? {
      ...draggable,
      user_acl: userAcl.length ? userAcl : undefined,
    } : draggable);
  }

  getUserAcl() {
    return this._draggables.find(({ number })=>this._selected === number)?.user_acl;
  }

  get initScale() {
    return this._initScale;
  }
  setInitScale(value: number) {
    this._initScale = value;
  }

  get scale() {
    return this._scale;
  }
  setScale(value: number) {
    this._scale = value < MIN_SCALE ? MIN_SCALE : value > MAX_SCALE ? MAX_SCALE : value;
  }

  get currentPosition() {
    return this._currentPosition;
  }
  setCurrentPosition(position:Position) {
    this._isCurrentChanged = true;
    this._currentPosition = this.limitPosition(position);
  }
  move(x:number, y:number) {
    this.setCurrentPosition({
      x: this.currentPosition.x + x,
      y: this.currentPosition.y + y,
    });
  }
  setX(value: number) {
    this.setCurrentPosition({
      x: +value,
      y: this.currentPosition.y,
    });
  }
  setY(value: number) {
    this.setCurrentPosition({
      x: this.currentPosition.x,
      y: +value,
    });
  }

  get currentRotation() {
    return this._currentRotation;
  }
  setCurrentRotation(rotation:number) {
    this._isCurrentChanged = true;
    this._currentRotation = rotation;
  }

  get currentWidth() {
    return this._currentWidth;
  }
  setCurrentWidth(width:number) {
    this._isCurrentChanged = true;
    this._currentWidth = width;
  }

  get isCurrentFlexible() {
    return this._isCurrentFlexible;
  }
  setCurrentFlexible(isFlexible:boolean) {
    this._isCurrentChanged = true;
    this._isCurrentFlexible = isFlexible;
  }
  toogleCurrentType() {
    if (this._isDesktop) {
      this._isCurrentFlexible = true;
      this._isDesktop = false;
    } else if (this._isCurrentFlexible) {
      this._isDesktopNa = true;
      this._isCurrentFlexible = false;
    } else {
      this._isDesktop = true;
      this._isDesktopNa = false;
    }
  }

  get isDesktopNa() {
    return this._isDesktopNa;
  }
  setDesktopNa(isDesktopNa: boolean) {
    this._isCurrentChanged = true;
    this._isDesktopNa = isDesktopNa;
  }

  get isDesktop() {
    return this._isDesktop;
  }
  setDesktop(isDesktop: boolean) {
    this._isCurrentChanged = true;
    this._isDesktop = isDesktop;
  }

  get isCtrl() {
    return this._isCtrl;
  }
  setCtrl(isCtrl:boolean) {
    this._isCtrl = isCtrl;
  }

  get isShift() {
    return this._isShift;
  }
  setShift(isShift:boolean) {
    this._isShift = isShift;
  }

  get selected() {
    return this._selected;
  }
  setSelected(selected:number | null) {
    if (this._isEdit) {
      this._selected = selected;
    }
    return null;
  }

  set magnetTriggers(triggers) {
    this._magnetTriggers = triggers;
  }

  get magnetTriggers() {
    return this._magnetTriggers;
  }

  checkIsMagnetTrigger(workspaceNumber: number) {
    return [
      ...this._magnetTriggers.x,
      ...this._magnetTriggers.y,
    ].includes(workspaceNumber);
  }

  clearMagnetTriggers() {
    this._magnetTriggers = { x: [], y: [] };
  }

  get isEdit() {
    return this._isEdit;
  }
  setEdit(isEdit:boolean) {
    this._isEdit = isEdit;
  }

  get isEditChangesDraggble() {
    return this._isEditChangesDraggble;
  }
  setIsEditChangesDraggble(isEditChangesDraggble: boolean) {
    this._isEditChangesDraggble = isEditChangesDraggble;
  }

  get officeSvg() {
    return this._officeSvg;
  }
  get officeSvgUrl() {
    return this._officeSvgUrl;
  }
  setOfficeSvgUrl(value: string) {
    this._officeSvgUrl = value;
  }
  uploadBackground({ target:{ files } }:ChangeEvent<HTMLInputElement>) {
    if (!files || !files.length || !files[0]) return;
    const extension = files[0].name.split('.').pop();
    if (extension !== 'svg') {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message: 'Недопустимое расширение файла.',
        severity: 'error',
      });
      return;
    }
    this._officeSvg = files[0];
    this._officeSvgUrl = URL.createObjectURL(files[0]);
    this._isEdit = true;

    const img = new Image();
    img.onload = () => {
      this._initBackgroundSize = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };
    };
    img.src = this._officeSvgUrl;
  }

  get columns() {
    return this._columns;
  }
  setColumns(value:number) {
    this._columns = value < LOCKER_MIN ? LOCKER_MIN : value > LOCKER_MAX ? LOCKER_MAX : value;
  }

  get rows() {
    return this._rows;
  }
  setRows(value: number) {
    this._rows = value < LOCKER_MIN ? LOCKER_MIN : value > LOCKER_MAX ? LOCKER_MAX : value;
  }

  get initColumns() {
    return this._initColumns;
  }
  setInitColumns(value:number) {
    this._initColumns = value < LOCKER_MIN ? LOCKER_MIN : value > LOCKER_MAX ? LOCKER_MAX : value;
  }

  get initRows() {
    return this._initRows;
  }
  setInitRows(value:number) {
    this._initRows = value < LOCKER_MIN ? LOCKER_MIN : value > LOCKER_MAX ? LOCKER_MAX : value;
  }

  get scaleCoef() {
    return this._scaleCoef;
  }
  setScaleCoef(value: number) {
    this._scaleCoef = value;
  }

  get backgroundSize() {
    return this._backgroundSize;
  }
  setBackgroundSize(value: { width:number, height: number }) {
    this._backgroundSize = value;
  }

  get initBackgroundSize() {
    return this._initBackgroundSize;
  }
  setInitBackgroundSize(value: { width:number, height: number }) {
    this._initBackgroundSize = value;
  }

  get isBackgroundLoading() {
    return this._isBackgroundLoading;
  }
  setBackgroundLoading(value: boolean) {
    this._isBackgroundLoading = value;
  }

  limitPosition({ x, y }: Position) {
    const halfOfBoundaryWidth =
      (this._backgroundSize.width / 2) /
      (this._scaleCoef);
    const halfOfBoundaryHeight =
      (this._backgroundSize.height / 2) /
      (this._scaleCoef);

    return {
      x: x < -halfOfBoundaryWidth ?
        -halfOfBoundaryWidth :
        x > halfOfBoundaryWidth ?
          halfOfBoundaryWidth :
          x,
      y:y < -halfOfBoundaryHeight ?
        -halfOfBoundaryHeight :
        y > halfOfBoundaryHeight ?
          halfOfBoundaryHeight :
          y,
    };
  }

  get isSnap() {
    return this._isSnap;
  }

  toogleSnap() {
    this._isSnap = !this._isSnap;
  }

  get confirmDelete() {
    return this._confirmDelete;
  }

  setConfirmDelete(value: boolean) {
    this._confirmDelete = value;
  }

  saveLockerChanges(roomId: string) {
    serviceContainer.confirmationDialogStore.open({
      title: 'Подтвердите сохраниние',
      description: 'Сохранить внесенные изменения?',
      resolveTitle: 'Сохранить',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        this.updateDraggables();
        this.setSelected(null);
        this.setEdit(false);

        const lockerCountArray = new Array(this.rows * this.columns).fill(true);

        const { updateLockers, newLockers } = lockerCountArray.reduce<{
          updateLockers: DraggableType[],
          newLockers: number[],
        }>((acc, _, i) => {
          const currentNumber = i + 1;
          const draggable = this.draggables.find(({ number }) => currentNumber === number);
          return draggable ?
            { ...acc, updateLockers: [...acc.updateLockers, draggable] } :
            { ...acc, newLockers: [...acc.newLockers, currentNumber] };
        }, {
          updateLockers: [],
          newLockers: [],
        });

        const deleteLockers = this.draggables
          .map(({ number }) => number)
          .filter((curr) => !lockerCountArray.find((_, i) => i + 1 === curr),
          );

        const requestBody = (number: number, division_acl?: string[] | null, user_acl?: string[] | null) => ({
          workspaceId: `${roomId}.${number}`,
          roomName: roomId,
          workspaceNumber: `${number}`,
          workspaceType: 'locker',
          scale: 1,
          rotate: 0,
          x: (number - 1) % this.columns,
          y: Math.floor((number - 1) / this.columns),
          wsize: '1',
          division_acl: division_acl ?? null,
          user_acl: user_acl ?? null,
        });

        const requests = [];

        if (updateLockers.length) {
          requests.push(serviceContainer.apiService
            .updateWorkspacesAllViaEditor(updateLockers.map((locker) => requestBody(locker.number, locker.division_acl, locker.user_acl))));
        }

        if (newLockers.length) {
          requests.push(serviceContainer.apiService
            .createWorkspacesAllViaEditor(newLockers.map((number) => requestBody(number))));
        }

        requests.push(...deleteLockers
          .map((number) => {
            const workspaceId = `${roomId}.${number}`;
            return serviceContainer.apiService.deleteWorkspace(workspaceId);
          }));

        await Promise.all(requests);
        this.resetDraggables();
        await serviceContainer.workspacesService.loadWorkspaces(roomId);
      },
    });
  }

  saveChanges(roomId: string, roomVariant: RoomVariant) {
    serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите сохраниние',
      description: 'Сохранить внесенные изменения?',
      resolveTitle:'Сохранить',
      rejectTitle:'Отменить',
      onFulfilled: async () => {
        this.updateDraggables();
        this.setSelected(null);
        this.setEdit(false);
        if (this.officeSvg) {
          serviceContainer.apiService.uploadImage(this.officeSvg, roomId);
        }

        const { deleteWorkspaces, updateWorkspaces } = this.initDraggables.reduce<{
          deleteWorkspaces: number[],
          updateWorkspaces: DraggableType[],
        }>((acc, curr) => {
          const draggable = this.draggables.find(({ number }) => curr.number === number);
          return draggable ?
            { ...acc, updateWorkspaces: [...acc.updateWorkspaces, draggable] } :
            { ...acc, deleteWorkspaces: [...acc.deleteWorkspaces, curr.number] };
        }, {
          deleteWorkspaces: [],
          updateWorkspaces: [],
        });

        const newWorkspaces = this.draggables.filter((curr) =>
          !this.initDraggables.find(({ number })=>number === curr.number),
        );

        const requestBody = ({
          number,
          isFlexible,
          position,
          rotation,
          width,
          division_acl,
          user_acl,
          note,
          isDesktop,
          isDesktopNa,
        }: DraggableType) => {
          const getWorkspaceType = () => {
            if (isFlexible) {
              return 'desktop_fo';
            } else if (isDesktop) {
              return 'desktop';
            } else if (isDesktopNa) {
              return 'desktop_na';
            } else {
              return 'desktop';
            }
          };

          return {
            workspaceId: `${roomId}.${number}`,
            roomName: roomId,
            workspaceNumber: `${number}`,
            workspaceType: roomVariant === 'meeting' ? 'meetingroom' : getWorkspaceType(),
            scale: +this.scale,
            rotate: +rotation,
            x: +position.x,
            y: +position.y,
            wsize: `${width}`,
            division_acl:division_acl ?? null,
            user_acl: user_acl ?? null,
            note: note ? note : null,
          };
        };

        const requests = [];

        if (updateWorkspaces.length) {
          requests.push(serviceContainer.apiService.updateWorkspacesAllViaEditor(updateWorkspaces.map(requestBody)));
        }

        if (newWorkspaces.length) {
          requests.push(serviceContainer.apiService.createWorkspacesAllViaEditor(newWorkspaces.map(requestBody)));
        }

        requests.push(...deleteWorkspaces
          .map((number) => {
            const workspaceId = `${roomId}.${number}`;
            return serviceContainer.apiService.deleteWorkspace(workspaceId);
          }));

        await Promise.all(requests);
        this.resetDraggables();
        await serviceContainer.workspacesService.loadWorkspaces(roomId);
      },
    });
  }
}
