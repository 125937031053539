import { action, makeAutoObservable, toJS } from 'mobx';
import { UpdateUserData, User, PaginationParams } from '../types/otherTypes';

export class UsersStore {
  private _currentUser: User | null = null;
  private _isCurrentUserAdmin: boolean = false;
  private _users: User[] | null = null;
  private _updateUser: UpdateUserData | undefined = undefined;
  private _paginationParams: PaginationParams | undefined = undefined;
  private _userIcon: { [key: string]: Blob } = {};

  constructor() {
    makeAutoObservable(this);
  }

  get users() {
    return toJS(this._users);
  }

  @action
  set users(users) {
    this._users = users;
  }

  get currentUser() {
    return toJS(this._currentUser);
  }

  @action
  set currentUser(user) {
    this._currentUser = user;
  }

  get updateUser() {
    return toJS(this._updateUser);
  }

  @action
  set updateUser(data) {
    this._updateUser = data;
  }

  get isCurrentUserAdmin() {
    return toJS(this._isCurrentUserAdmin);
  }

  @action
  set isCurrentUserAdmin(isAdmin) {
    this._isCurrentUserAdmin = isAdmin;
  }

  get paginationParams() {
    return toJS(this._paginationParams); 
  }

  set paginationParams(params) {
    this._paginationParams = params; 
  }

  get userIcon() {
    return toJS(this._userIcon); 
  }

  setUserIcon(data: any, userIcon: string) {
    const obj = {
      ...this._userIcon,
      [userIcon]: data,
    };
    this._userIcon = obj;
  }
}
