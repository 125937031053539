import { makeAutoObservable, toJS } from 'mobx';
import { TDivisionTableInternal } from '../pages/AdministrationPage/DivisionsTable/DivisionsTable.type';

export class DivisionsStore {
  private _divisions: TDivisionTableInternal[] = [];
  private _isLoaded: boolean = true;
  private _filter: string = '';
  private _isAddingDivision = false;
  private _dropdownDivision: TDivisionTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _divisionInfoForEdit: TDivisionTableInternal | null = null;
  private _option: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get divisions() {
    return toJS(this._divisions);
  }

  setDivisions(divisions: TDivisionTableInternal[]) {
    this._divisions = divisions;
  }

  get isLoaded() {
    return this._isLoaded;
  }

  set isLoaded(isLoaded) {
    this._isLoaded = isLoaded;
  }

  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingDivision() {
    return this._isAddingDivision;
  }

  setIsAddingDivision(value: boolean) {
    this._isAddingDivision = value;
  }

  get dropdownDivision() {
    return this._dropdownDivision;
  }

  setDropdownDivision(value: TDivisionTableInternal) {
    this._dropdownDivision = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get divisionInfoForEdit() {
    return this._divisionInfoForEdit;
  }

  setDivisionInfoForEdit(value: TDivisionTableInternal | null) {
    this._divisionInfoForEdit = value;
  }

  get option() {
    return this._option;
  }

  setOption(value: string[]) {
    this._option = value;
  }
}
