import { imageStore } from 'stores';
import { serviceContainer } from './ServiceContainer';

export class RoomService {
  async loadBackgroundImage(officeId: string, roomId: string) {
    if (imageStore.images.has(`BG_${roomId}`)) {
      const url = imageStore.images.get(`BG_${roomId}`);
      if (url) {
        const img = new Image();
        img.onload = () => {
          serviceContainer.roomStore.setInitBackgroundSize({
            width: img.naturalWidth,
            height: img.naturalHeight,
          });
          serviceContainer.roomStore.setOfficeSvgUrl(url);
        };
        img.src = url;
      }
    } else {
      const onError = () => {
        serviceContainer.workspacesStore.setWorkspacesByRoomId(roomId, []);
        serviceContainer.roomStore.setBackgroundLoading(false);
      };
      const backgroundImageName = serviceContainer.roomsStore.rooms[officeId]
        .find((room) => room.attributes.id === roomId)?.attributes.img;
      if (backgroundImageName) {
        serviceContainer.roomStore.setBackgroundLoading(true);
        await serviceContainer.apiService.getRoomBackground(backgroundImageName)
          .then((res) => {
            const blob = new Blob([res.data], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            const img = new Image();
            img.onload = () => {
              serviceContainer.roomStore.setInitBackgroundSize({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
              serviceContainer.roomStore.setOfficeSvgUrl(url);
              imageStore.images.set(`BG_${roomId}`, url);
              serviceContainer.roomStore.setBackgroundLoading(false);
            };
            img.src = url;
          }, onError)
          .catch(onError);
      }
    }
  }
}
