import React from 'react';
import { Typography as TypographyMui } from '@mui/material';

import './Typography.scss';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

// todo подумать как лучше вытащить весь кастомный шрифт и затем переиспользовать
type TypographyType = {
  component?: any;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  children?: React.ReactChildren | React.ReactNode;
  className?: string;
};

const Typography = ({
  component,
  variant,
  children,
  className,
} : TypographyType) => {
  return (
    <TypographyMui
      variant={variant}
      component={component}
      className={`typography-${className}`}
    >
      {children}
    </TypographyMui>
  );
};

export default Typography;
