import { useHistory } from 'react-router-dom';
import { KeyboardBackspaceRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import './GoBackIcon.scss';

export const GoBackIcon = () => {
  const history = useHistory();
  const pathName = history.location.pathname;
  const isCalendar = pathName.includes('workspaces/');

  const goBack = () => {
    const newPath = pathName
      .split('/')
      .slice(0, isCalendar ? -1 : -2)
      .join('/');
    history.push(newPath);
  };

  return (
    <div>
      <IconButton
        className="back-btn"
        onClick={goBack}
        disableRipple
      >
        <KeyboardBackspaceRounded />
      </IconButton>
    </div>
  );
};
