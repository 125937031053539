import { useMemo } from 'react';
import { getExtension } from './helpers';
import { IconButton, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { ArrowDropDown } from '@mui/icons-material';

type OwnProps = {
  attachedFile: File[],
  setAttachedFile?: (file: File[] | undefined) => void,
};

const FilePreview = ({ attachedFile, setAttachedFile }: OwnProps) => {
  const attachedFileSize = useMemo(() => {
    let size = 0;
    Object.values(attachedFile).forEach((item: any) => {
      size += item.size;
    });
    return size;
  }, [attachedFile]);

  return (
    <div className="file-preview">
      <div className="file-preview-wrap">
        <Accordion
          disableGutters
          onChange={()=>{}}
          className='accordion'
        >
          <AccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="accordion-content"
            className='accordion-summary'
            id="accordion-fiels"
          >
            {`Добавлено файлов ${attachedFile && attachedFile.length} размером ${`${String(attachedFileSize / 1e6).slice(0, 4)} MB`}`}
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            {attachedFile && attachedFile.map((file) => {
              const fileSize = file && `${String(file.size / 1e6).slice(0, 4)} MB`;
              const fileName = file && file.name.length > 35
                ? `${file.name.slice(0, 30)}....${getExtension(file.name)}`
                : file?.name;
              return (
                <div
                  className='file-preview-list'
                  key={file.name}
                >
                  <div className="file-preview-text">
                    <Typography className='file-preview-name'>
                      {fileName}
                    </Typography>
                    <Typography className='file-preview-size'>
                      {fileSize}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </AccordionDetails>
        </Accordion>
        
      </div>
      {setAttachedFile && (
        <IconButton
          className="file-preview-delete"
          onClick={() => setAttachedFile(undefined)}
        >
          <Delete/>
        </IconButton>
      )}
    </div>
  );
};

export default FilePreview;