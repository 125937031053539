import { makeAutoObservable } from 'mobx';

interface ConfirmationDialogProps {
  title?: string;
  description?: string;
  resolveTitle?: string;
  rejectTitle?: string;
  linkPath?: string;
  linkText?: string;
  content?: JSX.Element;
  onFulfilled?: () => void;
  onRejected?: () => void;
  onClose?: () => void;
}

export class ConfirmationDialogStore {
  private _isOpen = false;
  private _loading = false;
  private _title?: string;
  private _description?: string;
  private _resolveTitle?: string;
  private _rejectTitle?: string;
  private _linkPath?: string;
  private _linkText?: string;
  private _content?: JSX.Element;
  private _onFulfilled = () => {};
  private _onRejected = () => {};
  private _onClose = () => {};

  constructor() {
    makeAutoObservable(this);
  }

  get isOpen() {
    return this._isOpen;
  }

  get title() {
    return this._title;
  }
  get description() {
    return this._description;
  }
  get resolveTitle() {
    return this._resolveTitle;
  }
  get rejectTitle() {
    return this._rejectTitle;
  }
  get linkPath() {
    return this._linkPath;
  }
  get linkText() {
    return this._linkText;
  }
  get content() {
    return this._content;
  }
  get onFulfilled() {
    return this._onFulfilled;
  }
  get onRejected() {
    this._loading = false;
    return this._onRejected;
  }
  get onClose() {
    return this._onClose;
  }

  get loading() {
    return this._loading;
  }
  set loading(value) {
    this._loading = value;
  }

  private _close = () => {
    this._isOpen = false;
  };

  open({
    title,
    description,
    resolveTitle,
    rejectTitle,
    linkPath,
    linkText,
    content,
    onFulfilled = async () => {},
    onRejected = () => {},
    onClose = () => {},
  }: ConfirmationDialogProps) {
    return new Promise<void>((resolve) => {
      this._title = title;
      this._description = description;
      this._resolveTitle = resolveTitle;
      this._rejectTitle = rejectTitle;
      this._linkPath = linkPath;
      this._linkText = linkText;
      this._content = content;
      this._onFulfilled = async () => {
        this.loading = true;
        try {
          await onFulfilled();
          resolve();
        } finally {
          this.loading = false;
          this._close();
        }
      };
      this._onRejected = () => {
        onRejected();
        this._close();
      };
      this._onClose = () => {
        onClose();
        this._close();
      };
      this._isOpen = true;
    });
  }
}
