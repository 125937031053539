import { useState, createContext, forwardRef, useContext, useRef, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  TextField,
  Autocomplete,
  Checkbox,
  autocompleteClasses,
  Popper,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { User } from 'types';

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    paddingInline: 0,
  };

  return (
    <li
      {...dataSet[0]}
      style={inlineStyle}
    >
      <Checkbox 
        size='small'
        checked={dataSet[1]?.props.children[0].props.checked}
      />
      <div key={dataSet[1]?.userId}>
        <div
          className='userName'
          style={{
            margin: 0,
            padding: 0,
            fontSize: 14,
          }}
        >
          {dataSet[1]?.props.children[1]?.userName}
        </div>
        <div
          className='divisionName'
          style={{
            color: 'grey',
            fontSize: 12,
          }}
        >
          {dataSet[1]?.props.children[1]?.division}
        </div>
      </div>
    </li>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div
    ref={ref}
    {...props}
    {...outerProps}
         />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef<
HTMLDivElement,
React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child: React.ReactChild) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  
  return (
    // @ts-ignore
    <div ref={ref}>
      {/* @ts-ignore */}
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          //@ts-ignore
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
          useIsScrolling
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

interface ReservationAccessUsersProps {
  setChooseUsers: Dispatch<SetStateAction<User[] | []>>;
  chooseUsers: User[] | [];
  getUserAcl: () => string[] | undefined;
  users: User[] | null;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: '8px 0',
      margin: 0,
    },
  },
});

export const ReservationAccessUsers = observer(({
  setChooseUsers,
  chooseUsers,
  getUserAcl,
  users,
}: ReservationAccessUsersProps) => {
  const [isUserPopperOpen, setUserPopperOpen] = useState(false);

  return (
    <Autocomplete
      multiple
      id="virtualize-users"
      disableListWrap
      style={{ paddingTop: 20 }}
      limitTags={3}
      disableCloseOnSelect
      PopperComponent={StyledPopper}
      open={isUserPopperOpen}
      onOpen={() => setUserPopperOpen(true)}
      onClose={() => setUserPopperOpen(false)}
      ListboxComponent={ListboxComponent}
      options={users?.sort((a, b) => {
        const aSelected = (getUserAcl() || []).includes(a.userId || '');
        const bSelected = (getUserAcl() || []).includes(b.userId || '');
        return aSelected === bSelected ? (a.userId || '').localeCompare(b.userId || '') : aSelected ? -1 : 1;
      }) || []}
      getOptionLabel={(option) => option?.userName ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Пользователи с доступом"
          placeholder="Поиск..."
        />
      )}    
      componentsProps={{
        popper: {
          placement: 'bottom',
        },
      }}  
      renderOption={(props, option, { selected }) => [
        props, 
        <>
          <Checkbox 
            size='small'
            checked={selected}
          />
          {option}
        </>,
      ]}
      renderTags={(value, getTagProps) =>
        isUserPopperOpen ?
          <div
            className="d-flex"
            style={{ overflow: 'auto', maxHeight: 60 }}
          >
            {value.map((option, index) => (
              <Chip
                label={
                option.userName.length > 15
                  ? option.userName.substring(0, 10) +
                  '...'
                  : option.userName
              }
                size="small"
                {...getTagProps({ index })}
              />
            ))}
          </div> :
          value.map((option, index) => (
            <Chip
              label={
                option.userName.length > 15
                  ? option.userName.substring(0, 10) +
                  '...'
                  : option.userName
              }
              size="small"
              {...getTagProps({ index })}
            />
          ))}
      value={chooseUsers}
      onChange={(_, value) => setChooseUsers(value)}
      renderGroup={(params) => params}
    />
  );
});
