const API_HOST = process.env.REACT_APP_API_HOST || '';

export const API_URL = `${API_HOST}`;

export const usersURL = `${API_URL}/secured/users`;

export const currentUserURL = `${API_URL}/secured/users/me`;

export const currentUserImageURL = `${API_URL}/secured/images/user`;

export const getAllUsersURL = `${API_URL}/secured/users/all`;

export const getSchedulerURL = `${API_URL}/secured/scheduler/status`;

export const getAllSchedulersURL = `${API_URL}/secured/scheduler/status/all`;

export const disableSchedulersURL = `${API_URL}/secured/scheduler/disable`;

export const enableSchedulersURL = `${API_URL}/secured/scheduler/enable`;

export const restorePasswordURL = `${API_URL}/secured/users/restore`;

export const updateUserDataURL = `${API_URL}/secured/users/refresh`;

export const updateUserEmailURL = `${API_URL}/secured/users/email`;

export const updatePasswordURL = `${API_URL}/secured/users/password`;

export const getRegionsURL = `${API_URL}/secured/regions`;

export const officesURL = `${API_URL}/secured/offices`;

export const favoritesURL = `${API_URL}/secured/favorites`;

export const getRoomsURL = `${API_URL}/secured/rooms`;

export const getWorkspacesURL = `${API_URL}/secured/workspaces`;

export const workspacesAllURL = `${API_URL}/secured/workspaces/all`;

export const getReservationsURL = `${API_URL}/secured/reservations/all`;

export const changeReservationURL = `${API_URL}/secured/reservations`;

export const getDivisionsURL = `${API_URL}/secured/divisions`;

export const getRolesURL = `${API_URL}/secured/roles`;

export const restorePasswordPublicURL = `${API_URL}/public/restore`;

export const sendMessageToSupportURL = `${API_URL}/secured/support`;

export const departmentsURL = `${API_URL}/secured/departments`;

export const domainsURL = `${API_URL}/secured/domains`;

export const POSTroomBackgroundURL = `${API_URL}/secured/rooms/image`;

export const POSTroomPreviewURL = `${API_URL}/secured/rooms/preview-room`;

export const POSTofficePreviewURL = `${API_URL}/secured/offices/preview-office`;

export const roomBackgroundURL = `${API_URL}/secured/images/room`;

export const roomPreviewURL = `${API_URL}/secured/images/room/preview`;

export const officePreviewURL = `${API_URL}/secured/images/office/preview`;

export const notificationsURL = `${API_URL}/secured/notifications`;

export const notificationVersionsURL = `${API_URL}/secured/versions`;

export const newslettersURL = `${API_URL}/secured/newsletters`;
