import { action, makeAutoObservable, toJS } from 'mobx';
import { IRoom } from '../types/commonTypes';

export type RoomsTableInternal = {
  workspaceId: {
    value: string;
    cell: string;
    cellEdit: string;
  };
  roomId: {
    value: string;
    cell: string;
    cellEdit: string;
  };
  workspaceNumber: {
    value: string;
    cell: string;
    cellEdit: string;
  };
  wtypeId: {
    value: string;
    cell: string;
    cellEdit: string;
  };
  roomType: {
    value: string;
    cell: string;
    cellEdit: string;
  };
  disabled: boolean;
  id: string;
  edit: boolean;
  isNew: boolean;
};

export class RoomsStore {
  private _rooms: { [key: string]: IRoom[] } = {};
  private _roomsTable: RoomsTableInternal[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get rooms(): { [key: string]: IRoom[] } {
    return toJS(this._rooms);
  }

  get roomsTable() {
    return toJS(this._roomsTable);
  }

  set roomsTable(roomsTable) {
    this._roomsTable = roomsTable;
  }

  getRoomsByKey(key: string): IRoom[] {
    return toJS(this._rooms[key]);
  }
  
  setRoomsById(officeId: string, rooms: IRoom[]) {
    this._rooms[officeId] = rooms;
  }

  addRoom(officeId: string, room: IRoom) {
    if (!this._rooms[officeId]) this._rooms[officeId] = [];
    this._rooms[officeId].push(room);
  }

  deleteRoom(officeId: string, roomId: string) {
    this._rooms[officeId] = this._rooms[officeId].filter(({ attributes: { id } }) => roomId !== id);
  }
}
