import { action, makeAutoObservable, toJS } from 'mobx';
import { IOffice } from '../types/commonTypes';
import { TOfficesTableInternal, TSnackbarProps } from '../pages/AdministrationPage/OfficesTable/OfficesTable.type';

export class OfficesStore {
  private _officesTable: TOfficesTableInternal[] = [];
  private _newOffices: { [key: string]: IOffice[] } = {};
  private _officesLoaded = false;
  private _loadingOffices = false;
  private _isRequestError = false;
  private _filter: string = '';
  private _isAddingOffice = false;
  private _dropdownOffice: TOfficesTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _officeInfoForEdit: TOfficesTableInternal | null = null;
  private _regionsOption: { id: string; description: string; }[] = [];
  private _snackbar: TSnackbarProps = {
    open: false,
    message: '',
    severity: 'error',
  };

  constructor() {
    makeAutoObservable(this);
  }

  get loadingOffices(): boolean {
    return toJS(this._loadingOffices);
  }

  set loadingOffices(officesTable) {
    this._loadingOffices = officesTable;
  }

  get officesLoaded(): boolean {
    return toJS(this._officesLoaded);
  }
  
  get newOffices() {
    return toJS(this._newOffices);
  }

  get officesTable() {
    return toJS(this._officesTable);
  }

  setOfficesTable(officesTable: TOfficesTableInternal[]) {
    this._officesTable = officesTable;
  }
  
  @action
  setNewOffices(regionId: string, data: IOffice[] ) {
    this._newOffices[regionId] = data;
  }
  
  @action
  set isRequestError(value: boolean) {
    this._isRequestError = value;
  }

  getOfficesNewByKey(key: string): IOffice[] | undefined {
    return toJS(this._newOffices[key]);
  }

  addOffice(regionId: string, office: IOffice) {
    if (!this._newOffices[regionId]) this._newOffices[regionId] = [];
    this._newOffices[regionId].push(office);
  }

  deleteOffice(regionId: string, officeId: string) {
    this._newOffices[regionId] = this._newOffices[regionId].filter(({ attributes: { id } }) => officeId !== id);
  }

  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingOffice() {
    return this._isAddingOffice;
  }

  setIsAddingOffice(value: boolean) {
    this._isAddingOffice = value;
  }

  get dropdownOffice() {
    return this._dropdownOffice;
  }

  setDropdownOffice(value: TOfficesTableInternal) {
    this._dropdownOffice = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get snackbar() {
    return this._snackbar;
  }

  setSnackbar(value: TSnackbarProps) {
    this._snackbar = value;
  }

  get officeInfoForEdit() {
    return this._officeInfoForEdit;
  }

  setOfficeInfoForEdit(value: TOfficesTableInternal | null) {
    this._officeInfoForEdit = value;
  }

  get regionsOption() {
    return this._regionsOption;
  }

  setRegionsOption(value: { id: string; description: string; }[]) {
    this._regionsOption = value;
  }
}
