import { makeAutoObservable } from 'mobx';
import { serviceContainer } from 'services';

export class EditStore {
  private _isEdit = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEdit() {
    return this._isEdit &&
      serviceContainer.usersStore.currentUser?.userRole &&
      ['admin', 'designer'].includes(serviceContainer.usersStore.currentUser.userRole);
  }

  toggleEdit() {
    if (!this._isEdit) {
      this._isEdit = true;
    } else { 
      serviceContainer.roomStore.exitEditMode(() => {
        this._isEdit = false;
      });
    }
  }
}