import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 14,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {

      transform: 'translateX(16px)',
      color: '#fff',
      '& .MuiSwitch-thumb': {
        color: '#fff',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#168FF7',
        borderColor: '#168FF7',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: '50%',
    color: '#D1D2D3',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },

  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(0,0,0,.25)' : 'rgba(255,255,255,.35)',
    boxSizing: 'border-box',
    border: '1px solid #D1D2D3',
  },
}));

export default AntSwitch;