import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { serviceContainer } from 'services';
import {
  Box,
  Avatar,
  Button as ButtonMui,
  Typography as TypographyMui,
  Link,
  FormControl, InputLabel, Input, InputAdornment, TextField, Grid,
} from '@mui/material';
import { User } from '../../../types/otherTypes';
import Button from './components/Button';
import Typography from './components/Typography';
import { Telegram } from '@mui/icons-material';
import { ModalCustom, PasswordChanges } from '../index';
import PageLoader from '../PageLoader/PageLoader';

import './Profile.scss';

export type InitFieldProps = {
  key: string;
  label: string;
  value?: string | boolean | null;
  edit?: boolean;
  newValue?: string;
  isButton?: boolean;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Укажите email')
    .email('Некорректный email'),
});

const initFields: InitFieldProps[] = [
  { 
    key: 'userId',
    label: 'Логин',
  },
  { 
    key: 'userName',
    label: 'ФИО',
  },
  { 
    key: 'email',
    label: 'Email',
  },
  { 
    key: 'division',
    label: 'Подразделение',
  },
  { 
    key: 'userRole',
    label: 'Роль', 
  },
];

const Profile = observer(({
  currentUser,
  setOpenSnackbar = () => {},
  setIsSuccess = () => {},
  setAlertMessage = () => {},
}: {
  currentUser: User | null,
  setOpenSnackbar: (v: boolean) => void;
  setIsSuccess: (v: boolean) => void;
  setAlertMessage: (v: string) => void; }) => {
  const [fields, setFields] = useState<InitFieldProps[] | undefined>(undefined);
  const [isEditField, setIsEditField] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [formFields, setFormFields] = useState</*FormikProps<FormValues>*/any>();
  const [loadData, setLoadData] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const isSmallAvatar = currentUser && currentUser.userName?.length <= 22;

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values) => {
      const updateUser = currentUser;
      if (updateUser) {
        updateUser.email = values.email;
        serviceContainer.usersService.updateUserDetails(updateUser).catch((err) => {
          const status = err.response.status;
          const message = err.response.data.message;
          setOpenSnackbar(true);
          if (status) { setAlertMessage(`${status}: ${message}`); }
          setIsSuccess(false);
          setEmailError(true);
        }).then((r) => {
          if (r?.status === 200) {
            setOpenSnackbar(true);
            setAlertMessage('Почта успешно обновлена!');
            setIsSuccess(true);
            setIsEditField(undefined);
            setEmailError(false);
            formik.resetForm();
          }
        });
      }
    },
  });

  useEffect(() => {
    setLoadData(true);
    if (currentUser) {
      const updateFields: InitFieldProps[] = initFields.map(it => ({
        ...it,
        value: !currentUser[it.key as keyof User]
          ? null
          : currentUser[it.key as keyof User],
        edit: ['email'].includes(it.key),
        isButton: ['password'].includes(it.key),
      }));
      if (['viewer'].includes(currentUser?.userRole)) {
        updateFields.pop();
      }
      if (emailError) {
        currentUser.email = '';
        setIsEditField(currentUser.email);
      }
      setEmailError(false);
      setFields(updateFields);
      setLoadData(false);
    }
  }, [currentUser, currentUser?.email, emailError]);

  const onClickDone = () => {
    if (formik.isValid) { formik.handleSubmit(); }
  };

  const onSavePassword = () => formFields?.handleSubmit();

  return (
    <>
      <Box className='profile'>
        <div className='profile-header'>
          {serviceContainer.usersStore.userIcon[currentUser?.img ?? ''] ? (
            <Avatar
              src={URL.createObjectURL(serviceContainer.usersStore.userIcon[currentUser?.img ?? ''] as Blob | MediaSource)}
              className={`profile-header-avatar${isSmallAvatar ? '-small' : '' }`}
            />
          ) : null}
          {currentUser?.userName ? (
            <h2 className='profile-header-title'>{currentUser?.userName}</h2>
          ) : null}
        </div>
        <div className='profile-container'>
          {loadData && !fields ? (
            <div className='profile-container-loader'><PageLoader/></div>
          ) : (
            <>
              {fields?.map((field, index) => {
                const btnInnerStyle = !field.value && !!field.isButton ? 'btn-inner' : null;
                const isEdit = ['email'].includes(field.key) && !!field.edit && !['viewer'].includes(currentUser?.userRole ?? '') ? 'btn' : null;
                const inputValue = field.value ? field.value : field.isButton ? '' : 'нет данных';
                const getNameClass = !!isEdit
                  ? isEdit
                  : !!btnInnerStyle ? btnInnerStyle : null;
                return (
                  <React.Fragment key={field.key}>
                    {isEditField && field.edit ? (
                      <FormControl
                        variant="standard"
                        fullWidth={true}
                        className="profile-container-formControl-editing"
                      >
                        <Grid
                          container
                          className="profile-container-formControl-editing-grid"
                        >
                          <Grid
                            item
                            xs
                            className="profile-container-formControl-editing-grid-label"
                          >
                            <InputLabel htmlFor="input-with-icon-adornment">
                              <Typography className="600">
                                {field.label}
                              </Typography>
                            </InputLabel>
                          </Grid>
                          <Grid
                            item
                            xs
                            className="profile-container-formControl-editing-grid-field"
                          >
                            <TextField
                              id={field.key}
                              name={field.key}
                              type="text"
                              multiline={true}
                              fullWidth={true}
                              className="profile-container-formControl-editing-field"
                              autoFocus={!!isEditField && field.edit}
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('email', true);
                              }}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            className="profile-container-formControl-editing-grid-btns"
                          >
                            <Button
                              style={{ marginRight: '8px' }}
                              type="done-rounded"
                              onClick={onClickDone}
                              disabled={!!currentUser && formik.values.email === currentUser.email}
                              // titleHint={!!currentUser && formik.values.email === currentUser.email ? 'Необходимо изменить email' : ''}
                            />
                            <Button
                              type="do-not-disturb-alt"
                              onClick={() => {
                                formik.resetForm();
                                setIsEditField(undefined);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    ) : (
                      <FormControl
                        variant="standard"
                        fullWidth={true}
                        className="profile-container-formControl"
                      >
                        <Input
                          className={`profile-container-formControl-field${['нет данных'].includes(inputValue as string) ? '-empty' : ''}`}
                          value={inputValue}
                          readOnly={true}
                          id="1"
                          multiline
                          startAdornment={
                            <InputAdornment position="start">
                              <Typography className="600">
                                {field.label}
                              </Typography>
                            </InputAdornment>
                          }
                        />
                        {/* TODO: возможность изменения email */}
                        {/* {getNameClass && (
                          <Box className={`profile-container-formControl-${getNameClass}`}>
                            {isEdit ? (
                              <Button
                                onClick={() => {
                                  setIsEditField(field.key);
                                  formik.setFieldValue('email', currentUser?.email);
                                }}
                              />
                            ) : (
                              <Link
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '22.4px',
                                }}
                                component="button"
                                variant="body2"
                                onClick={() => setOpen(true)}
                              >
                                Изменить
                              </Link>
                            )}
                          </Box>
                        )} */}
                      </FormControl>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
        {/*<ButtonMui*/}
        {/*  startIcon={<Telegram fontSize="large"/>}*/}
        {/*  variant="outlined"*/}
        {/*  className="profile-footer"*/}
        {/*  target="_blank"*/}
        {/*  size="large"*/}
        {/*  href={process.env.NODE_ENV === 'production' ? 'https://t.me/flexofficebot' : 'https://t.me/testovichus_bot'}*/}
        {/*>*/}
        {/*  <TypographyMui*/}
        {/*    variant="subtitle1"*/}
        {/*    component="div"*/}
        {/*    className="profile-footer-subtitle"*/}
        {/*  >*/}
        {/*    Открыть Telegram бот*/}
        {/*  </TypographyMui>*/}
        {/*</ButtonMui>*/}
      </Box>
      {/* TODO: модалка для изменения пароля */}
      {/* <ModalCustom
        title="Изменение пароля"
        open={open}
        onClose={() => setOpen(false)}
        onSave={onSavePassword}
        loadingSave={loadingSave}
        disabledSave={!formFields?.isValid}
      >
        <PasswordChanges
          setOpen={setOpen}
          setFormFields={setFormFields}
          setLoadingSave={setLoadingSave}
          setIsSuccess={setIsSuccess}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
        />
      </ModalCustom> */}
    </>
  );
});

export default Profile;
