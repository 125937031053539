import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { compact } from 'lodash';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Collapse,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import {
  CheckCircle,
  LocationOn,
  ArrowDropUpRounded,
  PersonRounded,
  VerifiedUserTwoTone,
  ExitToAppTwoTone,
  AccountCircleTwoTone,
  Grade,
  Help,
  EditTwoTone,
  MailTwoTone,
} from '@mui/icons-material';
import { routes } from 'routes';
import { serviceContainer } from 'services';
import { FoLogoWhite } from 'assets';
import FAQicon from '../Help/FAQicon/FAQicon';

import './AppBar.scss';

const ButtonAppBar = observer(() => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [isAppbarIn, setIsAppbarIn] = useState(()=>false);
  const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement>(()=>null);
  const [error, setError] = useState<boolean>(false);
  const [imageData, setImageData] = useState<Blob | null>(null);
  const privilegedRoles = ['admin', 'superuser'];
  const currentUser = serviceContainer.usersStore.currentUser;
  const mediaMax900 = useMediaQuery('(max-width: 900px)');

  useEffect(() => {
    const isNotAvailable = history.location.pathname === '/notavailable';
    if (!isNotAvailable) {
      serviceContainer.usersService.loadCurrentUser().then(() => {
        serviceContainer.adminService.currentUser();
      });
    }
  }, []);

  useEffect(() => {
    const loadFavorites = async () => {
      if (!serviceContainer.favoritesStore.favoritesLoaded && currentUser?.userId) {
        await serviceContainer.favoritesService.loadFavorites(currentUser?.userId);
      }
    };
    if (currentUser?.userId) {
      loadFavorites();
    }
  }, [currentUser?.userId]);

  useEffect(() => {
    if (currentUser?.img) {
      serviceContainer.usersService.getBlobImageByUrl(currentUser.img).then((data) => {
        setImageData(data);
        serviceContainer.usersStore.setUserIcon(data, currentUser.img ?? '');
      });
    }
  }, [currentUser]);

  const isEditModeAllowed = useMemo(() =>
    serviceContainer.usersStore.currentUser?.userRole &&
    ['admin', 'designer'].includes(serviceContainer.usersStore.currentUser.userRole),
  [serviceContainer.usersStore.currentUser?.userRole],
  );

  const buttonData = [
    {
      ...routes.INDEX,
      icon: <LocationOn className='icon' />,
    },
    {
      ...routes.BOOKING_PAGE,
      icon: <CheckCircle className='icon' />,
    },
    {
      ...routes.FAVORITES_PAGE,
      icon: <Grade className='icon' />,
    },
    mediaMax900 ?
      {
        ...routes.HELP_PAGE,
        icon: <Help className='icon' />,
      } :
      {
        pageName: '',
        path: '',
        icon: '',
      },
  ];

  const dropdownButtonData = currentUser ? compact([
    {
      icon:<AccountCircleTwoTone className='icon'/>,
      ...routes.PROFILE_PAGE,
    },
    privilegedRoles.includes(currentUser.userRole)
      ? {
        icon:<VerifiedUserTwoTone className='icon'/>,
        ...routes.ADMINISTRATION_PAGE,
      } : null,
    privilegedRoles.includes(currentUser.userRole)
      ? {
        icon:<MailTwoTone className='icon'/>,
        ...routes.DISTRIBUTION,
      } : null,
  ]) : [];

  const handleClickMenu = (path: string): void => {
    history.push(path);
  };

  const navButtons = buttonData.map((btn) => (
    <Button
      className='button nav-button'
      color='inherit'
      key={btn.pageName}
      onClick={() => {
        handleClickMenu(btn.path);
        setIsAppbarIn(false);
      }}
    >
      {btn.icon}
      {btn.pageName}
    </Button>
  ));

  const navCollapseButtons = buttonData.map((btn) => (
    <Button
      className='button'
      color='inherit'
      key={btn.pageName}
      onClick={() => {
        handleClickMenu(btn.path);
        setIsAppbarIn(false);
      }}
    >
      {btn.icon}
      {btn.pageName}
    </Button>
  ));

  return (
    <Box className='appbar'>
      {serviceContainer.loginStore.token ? (
        <AppBar
          className='header'
          position='static'
          sx={{
            zIndex: 1000,
          }}
        >
          <Toolbar className={`appbar-toolbar ${serviceContainer.editStore.isEdit ? 'grey' : 'indigo'}`} >
            <div className='appbar-container'>
              <Typography
                className='appbar-header'
                variant='h6'
                component='div'
                onClick={() => handleClickMenu(routes.INDEX.path)}
              >
                <FoLogoWhite className='appbar-logo' />
                FlexOffice
              </Typography>
              <div className='appbar-button-wrapper appbar-left-button-wrapper'>
                <Button
                  className='button appbar-collapse-button'
                  color='inherit'
                  onClick={()=>{
                    setIsAppbarIn(!isAppbarIn);
                  }}
                >
                  <div
                    className={`hamburger--squeeze ${isAppbarIn ? 'is-active' : ''}`}
                  >
                    <div className='hamburger-box'>
                      <div className='hamburger-inner' />
                    </div>
                  </div>
                </Button>
                {navButtons}
              </div>
            </div>
            <Typography
              className='appbar-header-mobile'
              variant='h6'
              component='div'
              onClick={() => handleClickMenu(routes.INDEX.path)}
            >
              <FoLogoWhite className='appbar-logo' />
              FlexOffice
            </Typography>
            <div className='appbar-button-wrapper appbar-right-button-wrapper'>
              {mediaMax900 ? null : <FAQicon/>}
              <Button
                className='button'
                color='inherit'
                onClick={(e)=>{
                  setDropdownAnchor(e.currentTarget);
                  setIsAppbarIn(false);
                }}
              >
                {error || !imageData ? (
                  <PersonRounded
                    className='appbar-dropdown-icon'
                    fontSize='large'
                  />
                ) : currentUser && imageData ? (
                  <img
                    className='appbar-dropdown-icon'
                    src={URL.createObjectURL(imageData as Blob | MediaSource)}
                    alt={`${currentUser.img}`}
                    onError={() => setError(true)}
                  />
                ) : null}
                {currentUser?.userName ?
                  <>
                    <div className='appbar-dropdown-username'>
                      {currentUser.userName.split(' ')[0] + ' ' + currentUser?.userName?.split(' ').slice(1).map(word=>word[0] + '.').join('')}
                    </div>
                    <ArrowDropUpRounded className={`dropdown-arrow ${!!dropdownAnchor ? 'up' : 'down'}`}/>
                  </>
                  : null}
              </Button>
              <Menu
                className='appbar-dropdown'
                MenuListProps={{ className:'appbar-dropdown-list' }}
                open={!!dropdownAnchor}
                onClose={()=>setDropdownAnchor(null)}
                anchorEl={dropdownAnchor}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
              >
                {dropdownButtonData.map(({ pageName, path, icon }) => (
                  <MenuItem
                    className='appbar-dropdown-button'
                    key={pageName}
                    onClick={() =>{
                      handleClickMenu(path);
                      setDropdownAnchor(null);
                    }}
                  >
                    {icon}
                    {pageName}
                  </MenuItem>
                ))}
                { isEditModeAllowed &&
                  <MenuItem
                    className='appbar-dropdown-button'
                    key='Редактирование'
                    onClick={() => {
                      serviceContainer.editStore.toggleEdit();
                      setDropdownAnchor(null);
                    }}
                  >
                    <EditTwoTone className={`icon ${serviceContainer.editStore.isEdit ? 'red' : ''}`} />
                    Режим редактора
                  </MenuItem>}
                <MenuItem
                  className='appbar-dropdown-button'
                  key='Выйти'
                  onClick={() => {
                    serviceContainer.loginService.logOut();
                    setDropdownAnchor(null);
                  }}
                >
                  <ExitToAppTwoTone className='icon'/>
                  <div className="blue-background"/>
                  Выйти
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>

          <Collapse
            in={isAppbarIn}
          >
            <div
              className='appbar-button-wrapper appbar-collapse'
            >
              {navCollapseButtons}
            </div>
          </Collapse>
        </AppBar>
      ) : null}
    </Box>
  );
});

export default ButtonAppBar;
