import { observer } from 'mobx-react';
import { IconButton } from '@mui/material';
import {
  DoneRounded,
  DoNotDisturbAltTwoTone,
  EditTwoTone,
  ArrowForward,
} from '@mui/icons-material';

import './Button.scss';
import React from 'react';
import { LightTooltip } from 'UI/components';

type ButtonProps = {
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: 'edit' | 'arrow-forward' | 'done-rounded' | 'do-not-disturb-alt';
  style?: { marginRight?: string };
  disabled?: boolean;
  titleHint?: string;
};

// todo собрать все кастомные кнопки в один компонент
const Button = observer(({
  id,
  onClick,
  type,
  style,
  disabled,
  titleHint,
}: ButtonProps) => {
  const sx: { right?: string, backgroundColor?: string, color?: string, marginRight?: string } = { ...style  };

  const getIcon = () => {
    switch (type) {
      case 'edit':
        return <EditTwoTone id={id}/>;
      case 'arrow-forward':
        return <ArrowForward id={id}/>;
      case 'done-rounded':
        sx.backgroundColor = '#e2f5ea';
        sx.color = '#0aaf52';
        return (
          <DoneRounded
            fontSize='small'
            id={id}
          />
        );
      case 'do-not-disturb-alt':
        sx.backgroundColor = '#fee6e4';
        sx.color = '#f83021';
        return (
          <DoNotDisturbAltTwoTone
            fontSize='small'
            id={id}
          />
        );
      default:
        return <EditTwoTone id={id}/>;
    }
  };

  return (
    <LightTooltip
      title={!!titleHint ? titleHint : ''}
      arrow
    >
      <IconButton
        id={id}
        aria-label={`${id}-btn`}
        sx={sx}
        onClick={onClick}
        className="custom-btn"
        disabled={disabled}
      >
        {getIcon()}
      </IconButton>
    </LightTooltip>
  );
});

export default Button;
