import Cookies from 'js-cookie';
import { action, makeAutoObservable } from 'mobx';

export class LoginStore {
  private _token: string | undefined;
  private _currentUser: string | undefined = Cookies.get('current_user');

  constructor() {
    makeAutoObservable(this);
  }

  get token(): string | undefined {
    return this._token;
  }

  @action
  set token(newValue) {
    this._token = newValue;
  }

  get currentUser() {
    return this._currentUser;
  }

  @action
  set currentUser(newValue) {
    this._currentUser = newValue;
  }
}
