import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';
import { AddRounded, UploadFileRounded } from '@mui/icons-material';

import { ControlPanel } from 'UI/components';
import { serviceContainer } from 'services';
import { Status } from 'types';
import { MeetingTable } from './MeetingTable/MeetingTable';
import { initMeetingTable } from './MeetingTable.consts';

import './Meeting.scss';

export const Meeting = observer(() => {

  const { officeId, roomId } = useParams<{ roomId: string, officeId: string }>();
  const backgoundRef = useRef<HTMLImageElement>(null);

  const updateSize = () => {
    if (!backgoundRef.current) return;

    const isWiderThanLonger = backgoundRef.current.naturalWidth > backgoundRef.current.naturalHeight;

    const ratio = isWiderThanLonger ?
      backgoundRef.current?.naturalWidth / backgoundRef.current.naturalHeight :
      backgoundRef.current?.naturalHeight / backgoundRef.current.naturalWidth;

    const side = {
      one: isWiderThanLonger ? backgoundRef.current.clientWidth : backgoundRef.current.clientHeight,
      two: isWiderThanLonger ? backgoundRef.current.clientHeight : backgoundRef.current.clientWidth,
    };

    const currentRatio = side.one / side.two;

    serviceContainer.roomStore.setScaleCoef((currentRatio > ratio ?
      side.two * ratio :
      side.one) / (isWiderThanLonger ? 1000 : 500));

    serviceContainer.roomStore.setBackgroundSize({
      width: side.one,
      height: side.two,
    });
  };

  const addInitTable = () => {
    serviceContainer.roomStore.setDraggables([initMeetingTable]);
  };

  const meetingTable = useMemo(
    () => serviceContainer.roomStore.draggables[0],
    [serviceContainer.roomStore.draggables],
  );

  useEffect(() => {
    serviceContainer.roomStore.reset();
    serviceContainer.roomService.loadBackgroundImage(officeId, roomId);
  }, [roomId]);

  useEffect(() => {
    const workspaces = serviceContainer.workspacesStore.getWorkspacesByKey(roomId);
    if (!workspaces) return;

    const workspace = workspaces[0];

    if (workspaces.length) {
      const draggables = [{
        number: workspace.workspace_number,
        position: { x:+workspace.attributes.x, y:+workspace.attributes.y },
        rotation: +workspace.attributes.rotate,
        isChanged: false,
        isNew: false,
        status: workspace.status as Status,
        reservator: workspace?.attributes?.activeReservation?.data?.attributes?.fname?.userName,
        reservatorIcon: workspace?.attributes?.activeReservation?.data?.attributes?.fname?.img,
        division_acl:workspace.division_acl,
        user_acl:workspace.user_acl,
      }];
      serviceContainer.roomStore.setDraggables(draggables);
      serviceContainer.roomStore.setInitDraggables(draggables);
      serviceContainer.roomStore.setScale(+workspaces[0].attributes?.scale || 1);
      serviceContainer.roomStore.setInitScale(+workspaces[0].attributes?.scale || 1);
    }
  }, [roomId, serviceContainer.workspacesStore.workspaces]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className="meeting">
      {serviceContainer.roomStore.isBackgroundLoading ?
        <CircularProgress sx={{ margin: 'auto' }} /> :
        serviceContainer.roomStore.officeSvgUrl ?
          <>
            <div className="chairs">
              <img
                src={serviceContainer.roomStore.officeSvgUrl}
                alt='background'
                draggable='false'
                className='background'
                ref={backgoundRef}
                onLoad={updateSize}
                onClick={() => {
                  serviceContainer.roomStore.updateDraggables();
                  serviceContainer.roomStore.setSelected(null);
                }}
              />
              {!!meetingTable &&
                <MeetingTable
                  key={meetingTable.number}
                  number={meetingTable.number}
                  note={meetingTable.note}
                  position={meetingTable.position}
                  rotation={meetingTable.rotation}
                  reservatorIcon={meetingTable.reservatorIcon}
                  isSelected={serviceContainer.roomStore.selected === meetingTable.number}
                  status={meetingTable.status}
                  reservator={meetingTable.reservator}
                  divisionAcl={meetingTable.division_acl}
                />}
              {serviceContainer.roomStore.isEdit && !meetingTable &&
                <Button
                  className='add-meeting-table-button'
                  variant='contained'
                  onClick={addInitTable}
                  startIcon={<AddRounded />}
                >
                  Добавить стол
                </Button>}
            </div>
            <ControlPanel roomVariant='meeting' />
          </> :
          serviceContainer.editStore.isEdit ?
            <div className='upload-background'>
              <input
                type='file'
                onChange={(e)=>serviceContainer.roomStore.uploadBackground(e)}
                accept="image/svg+xml"
                value=""
              />
              <UploadFileRounded className='icon'/>
              <span>Перетащите или выберете <strong>svg</strong> офиса</span>
            </div> :
            <div className='no-data'>
              Нет данных
            </div>}
    </div>
  );
});
