import { observer } from 'mobx-react';
import { Button, Tab, Tabs } from '@mui/material';
import { serviceContainer } from 'services';
import SearchForm from '../../SearchForm/SearchForm';

import './AdminBar.scss';


const tabs = [
  {
    label: 'Пользователи',
    disabled: false,
  },
  {
    label: 'Конфигурация',
    disabled: true,
  },
];

export const AdminBar = observer(() => {

  const submitSearchHandler = (value: string) => {
    serviceContainer.adminStore.setFilter(value);
  };

  return (
    <div
      className="bar"
    >
      <div
        className="inputs"
      >
        <SearchForm
          submitHandler={submitSearchHandler}
          clearHandler={() => serviceContainer.adminStore.setFilter('')}
          placeholder="Поиск по сотруднику"
        />
        <Button
          className="button active"
          variant="outlined"
          onClick={() => {
            serviceContainer.adminStore.setIsAddingUser(!serviceContainer.adminStore.isAddingUser);
          }}
        >
          {serviceContainer.adminStore.isAddingUser ? 'отменить добавление' : 'добавить пользователя'}
        </Button>
      </div>
      <Tabs
        className="tabs"
        value={serviceContainer.adminStore.tab}
        onChange={(_e, value) => serviceContainer.adminStore.setTab(value)}
        TabIndicatorProps={{
          className: 'indicator',
          children: <span className="line"/>,
        }}
        selectionFollowsFocus
      >
        {tabs.map(({
          label,
          disabled,
        }) =>
          <Tab
            className="tab"
            label={label}
            disableRipple
            disabled={disabled}
            key={label}
          />,
        )}
      </Tabs>
    </div>
  );
});
