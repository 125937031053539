import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import { serviceContainer } from 'services';
import { isEqual } from 'lodash';
import { RoomVariant } from 'types/commonTypes';
import { ReservationAccessModal } from 'UI/components/ReservationAccessModal/ReservationAccessModal';
import { LockerControlPanel, MainControlPanel, WorkspaceControlPanel } from './components';

import s from './ControlPanel.module.scss';

export const ControlPanel = observer(({ roomVariant }:{ roomVariant:RoomVariant }) => {

  const [accessModalOpen, setAccessModalOpen] = useState(false);

  const { roomId } = useParams<{ roomId: string, officeId: string }>();
  const workspaces = serviceContainer.workspacesStore.getWorkspacesByKey(roomId);
  const selectedWorkspace = workspaces && workspaces.find(({ workspace_number }) => serviceContainer.roomStore.selected === workspace_number);

  const dependenciesEditChanges = [
    selectedWorkspace?.user_acl,
    selectedWorkspace?.division_acl,
    serviceContainer.roomStore.getUserAcl(),
    serviceContainer.roomStore.getDivisionAcl(),
  ];

  useEffect(() => {
    window.onbeforeunload = () => {
      if (serviceContainer.roomStore.isEditChangesDraggble) {
        return '';
      }
    };
  }, [serviceContainer.roomStore.isEditChangesDraggble]);

  useEffect(() => {
    if (serviceContainer.editStore.isEdit) {
      if (roomVariant === 'locker') {
        if (isEqual(selectedWorkspace?.user_acl, serviceContainer.roomStore.getUserAcl()) &&
        isEqual(selectedWorkspace?.division_acl, serviceContainer.roomStore.getDivisionAcl())) {
          serviceContainer.roomStore.setIsEditChangesDraggble(false);
        } else {
          serviceContainer.roomStore.setIsEditChangesDraggble(true);
        }
      }
    } else {
      serviceContainer.roomStore.setIsEditChangesDraggble(false);
    }
  }, dependenciesEditChanges); 

  return <div style={{ zIndex: 3 }}>
    {serviceContainer.editStore.isEdit ?
      serviceContainer.roomStore.isEdit ?
        roomVariant === 'locker' ?
          <LockerControlPanel setAccessModalOpen={setAccessModalOpen} /> :
          <MainControlPanel roomVariant={roomVariant} /> :
          <div className={s.edit_button}>
            <Button
              variant='contained'
              onClick={()=>serviceContainer.roomStore.setEdit(true)}
            >
              Редактировать
            </Button>
          </div> :
      null}
    {roomVariant !== 'locker' && serviceContainer.roomStore.isEdit &&
      <WorkspaceControlPanel
        roomVariant={roomVariant}
        setAccessModalOpen={setAccessModalOpen}
      />}
    <ReservationAccessModal
      open={accessModalOpen}
      onClose={() => setAccessModalOpen(false)}
      title={`Доступ на бронирование рабочего места ${roomId}.${serviceContainer.roomStore.selected}`}
      setDivisionAcl={serviceContainer.roomStore.setDivisionAcl.bind(serviceContainer.roomStore)}
      getDivisionAcl={serviceContainer.roomStore.getDivisionAcl.bind(serviceContainer.roomStore)}
      setUserAcl={serviceContainer.roomStore.setUserAcl.bind(serviceContainer.roomStore)}
      getUserAcl={serviceContainer.roomStore.getUserAcl.bind(serviceContainer.roomStore)}
    />
  </div>;
});
