import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Close, SearchRounded } from '@mui/icons-material';

import './SearchForm.scss';


type SearchFormProps = {
  submitHandler: (value: string) => void,
  clearHandler: () => void,
  placeholder: string,
};

const SearchForm = ({ submitHandler, clearHandler, placeholder }: SearchFormProps) => {
  const [value, setValue] = useState('');
  
  return (
    <form
      className="search-form"
      onSubmit={(e) => {
        e.preventDefault();
        submitHandler(value);
      }}
    >
      <TextField
        className="search"
        placeholder={placeholder}
        size="small"
        autoFocus
        fullWidth
        InputProps={{
          className: 'input',
          endAdornment: (
            <InputAdornment position="end">
              {value &&
                <IconButton
                  className="button"
                  onClick={() => {
                    clearHandler();
                    setValue('');
                  }}
                >
                  <Close/>
                </IconButton>}
              <IconButton
                className="button"
                onClick={() => submitHandler(value)}
                disabled={!value}
              >
                <SearchRounded style={{ color: value ? '#168ff7' : '#a3a4a6' }}/>
              </IconButton>  
            </InputAdornment>
          ),
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </form>
  );
};

export default SearchForm;
