import { HeadCell } from '../../../types/otherTypes';

export const favoritesColumns: HeadCell[] = [
  {
    numeric: true,
    disablePadding: false,
    id: 'placeType',
    label: 'Тип',
  },
  {
    numeric: true,
    disablePadding: false,
    id: 'regionName',
    label: 'Город',
  },
  {
    numeric: true,
    disablePadding: false,
    id: 'officeName',
    label: 'Офис',
  },
  {
    numeric: true,
    disablePadding: false,
    id: 'roomNumber',
    label: 'Комната',
  },
  {
    numeric: true,
    disablePadding: false,
    id: 'workspaceNumber',
    label: 'Место',
  },
  {
    numeric: true,
    disablePadding: false,
    id: 'action',
    label: '',
  },
];