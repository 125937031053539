import { observer } from 'mobx-react';
import { useState, MouseEvent, useCallback } from 'react';
import {
  TableContainer,
  Table,
  IconButton,
  TableBody,
  Stack,
  TableRow,
  TableCell,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { stableSort, getComparator, sortOptions } from './helpers';
import { useHistory } from 'react-router-dom';
import { DeleteOutline, Sort } from '@mui/icons-material';
import { IFavorites } from '../../../types/commonTypes';
import { FavoritesField } from '../../../types/otherTypes';
import { SortTableHead } from '../SortTableHead/SortTableHead';
import { favoritesColumns } from './mocks';

import './FavoritesTable.scss';

type FavoritesTableProps = {
  rows: IFavorites[];
  orderBy: keyof FavoritesField;
  setOrderBy: (value: keyof FavoritesField) => void;
  handleDeleteRow: (v: string) => void;
};

export const FavoritesTable = observer(({
  rows,
  orderBy,
  setOrderBy,
  handleDeleteRow,
}: FavoritesTableProps) => {
  const history = useHistory();
  
  const mediaMax850 = useMediaQuery('(max-width: 850px)');
  
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	
  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof FavoritesField) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const createSortHandler = (property: keyof FavoritesField) => (event: MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };
  
  const clickCheck = (target: HTMLLIElement) => {
    if (target.tagName === 'svg' || target.tagName === 'path' || target.tagName === 'BUTTON') return false;
    return true;
  };
  
  const handleOpenWorkspace = useCallback((event: MouseEvent, url: string) => {
    const target = event.target as HTMLLIElement;
    if (clickCheck(target)) return history.push(`/${url.split('/').slice(3).join('/')}`);
  }, [history]);
  
  return (
    <Stack className='favorites-table'>
      <Box className='mobile'>
        <Button
          className='sort-button'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={<Sort/>}
        >
          {sortOptions[orderBy as keyof typeof sortOptions]}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{ className:'favorites-sort-menu' }}
        >
          {Object.entries(sortOptions).map(([key, value]) =>
            <MenuItem
              className={`menu-item ${orderBy === key ? 'selected' : ''}`}
              value={key}
              key={key}
              onClick={(event)=>{
                handleRequestSort(event, key as keyof FavoritesField);
                setAnchorEl(null);
              }}
            >
              {value}
            </MenuItem>,
          )}
        </Menu>
      </Box>
      {rows?.length > 0 ? (
        <TableContainer className='favorites-table-container'>
          <Table
            stickyHeader
            aria-labelledby='tableTitle'
          >
            <SortTableHead
              order={order}
              orderBy={orderBy}
              createSortHandler={createSortHandler}
              headColumns={favoritesColumns}
              mediaMax850={mediaMax850}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    tabIndex={-1}
                    onClick={(e) => handleOpenWorkspace(e, row?.attributes.url)}
                    className='favorites-row'
                  >
                    {Object.entries(row.attributes).map(([key, prop]) => {
                      return (
                        <TableCell
                          className='favorites-cell-mobile'
                          align='left'
                          key={key}
                        >
                          {key === 'url' ? null : prop === 0 ? '' : prop}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className='favorites-cell-button'
                      align='right'
                    >
                      <IconButton
                        aria-label='delete-button'
                        onClick={() => handleDeleteRow(row.id)}
                        className='delete-button'
                      >
                        <DeleteOutline/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className='not-found-body'>
          <Typography className='not-found-top'>
            В этом разделе пока нет избранных помещений. 
          </Typography>
          <Typography>
            Добавь их для быстрого бронирования, нажав на звездочку 
          </Typography>
        </Box>
      )}
    </Stack>
  );
});
