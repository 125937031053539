import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { CloseRounded } from '@mui/icons-material';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent, 
  DialogContentText,
  DialogTitle, 
  IconButton, 
  Link as MuiLink,
} from '@mui/material';
import { serviceContainer } from 'services';

import './ConfirmationDialog.scss';
import { LoadingButton } from '@mui/lab';

export const ConfirmationDialog = observer(() => {
  return (
    <Dialog
      open={serviceContainer.confirmationDialogStore.isOpen}
      onClose={serviceContainer.confirmationDialogStore.onClose}
      PaperProps={{ className: 'paper' }}
      className="modal"
    >
      <IconButton
        className="close-button"
        onClick={serviceContainer.confirmationDialogStore.onClose}
      >
        <CloseRounded />
      </IconButton>
      {serviceContainer.confirmationDialogStore.title && (
        <DialogTitle className="title">{serviceContainer.confirmationDialogStore.title}</DialogTitle>
      )}
      <DialogContent className="content">
        {serviceContainer.confirmationDialogStore.description && (
          <DialogContentText className="text">
            {serviceContainer.confirmationDialogStore.description}
            {serviceContainer.confirmationDialogStore.linkPath && serviceContainer.confirmationDialogStore.linkText && (
              <Link
                to={serviceContainer.confirmationDialogStore.linkPath}
                onClick={serviceContainer.confirmationDialogStore.onClose}
              >
                <MuiLink>{serviceContainer.confirmationDialogStore.linkText}</MuiLink>
              </Link>
            )}
          </DialogContentText>
        )}
        {serviceContainer.confirmationDialogStore.content}
      </DialogContent>
      <DialogActions className="actions">
        {serviceContainer.confirmationDialogStore.rejectTitle && (
          <Button
            className="cancel-button"
            variant="outlined"
            onClick={() => serviceContainer.confirmationDialogStore.onRejected()}
          >
            {serviceContainer.confirmationDialogStore.rejectTitle}
          </Button>
        )}
        {serviceContainer.confirmationDialogStore.resolveTitle && (
          <LoadingButton 
            className="save-button"
            variant="contained"
            loading={serviceContainer.confirmationDialogStore.loading}
            onClick={() => serviceContainer.confirmationDialogStore.onFulfilled()}
          >
            {serviceContainer.confirmationDialogStore.resolveTitle}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
});
